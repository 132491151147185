import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, ValueGetterParams, ICellRendererParams } from 'ag-grid-community'
import { PageTitle } from 'components/PageTitle'
import ViewPhotoSubmissionsDialog from './components/ViewPhotoSubmissionsDialog'
import { 
  PhotoUpload as PhotoUploadModel, 
  PhotoUploadVehicle, 
  UserData, 
  VehicleWithUser 
} from 'core/types/photoUpload'
import { Add, History } from '@mui/icons-material'
import AssignUserDialog from '../InventoryManagement/components/Dialogs/AssignUserDialog'
import { removeUserFromVehicle } from 'core/api/inventory/removeUserFromVehicle'
import { useSnackbar } from 'contexts/snackBarContext'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { PhotoAlbumOutlined } from '@mui/icons-material'
import { theme } from 'theme'
import { assignUserToVehicle } from 'core/api/inventory/assignUserToVehicle'
import PhotoUploadDialog from '../InventoryManagement/components/Dialogs/PhotoUploadDialog'
import InitiatePhotoUploadDialog from './components/InitiatePhotoUploadDialog'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { PhotoUploadHistory } from './components/PhotoUploadHistory'

const PhotoUpload = () => {
  const { showSnackbar } = useSnackbar()
  const [selectedUpload, setSelectedUpload] = useState<PhotoUploadModel | null>(null)
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false)
  const [selectedVehicleForAssignment, setSelectedVehicleForAssignment] = useState<string | null>(null)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [isPhotoUploadDialogOpen, setIsPhotoUploadDialogOpen] = useState(false)
  const [selectedVehicleForUpload, setSelectedVehicleForUpload] = useState<Vehicle | null>(null)
  const [isInitiateDialogOpen, setIsInitiateDialogOpen] = useState(false)
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false)

  const [photoUploads, setPhotoUploads] = useState<PhotoUploadModel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    console.log('Setting up Firestore listeners...')
    setIsLoading(true)

    const users: { [key: string]: UserData } = {}
    const vehicles: { [key: string]: VehicleWithUser } = {}
    const userVehicleStatuses: { [key: string]: { [key: string]: any } } = {}

    const unsubscribeUsers = onSnapshot(
      collection(db, 'users'),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'removed') {
            delete users[change.doc.id]
          } else {
            const userData = change.doc.data();
            if (userData.user_type !== 'transporter') {
              users[change.doc.id] = {
                id: change.doc.id,
                email: userData.email,
                phone: userData.phone,
                user_type: userData.user_type,
                ...userData
              }
            }
          }
        })
        updatePhotoUploads()
      },
      (error) => {
        console.error('Users listener error:', error)
        setError(error)
        setIsLoading(false)
      }
    )

    const unsubscribeVehicles = onSnapshot(
      collection(db, 'master_inventory'),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data()
          if (change.type === 'removed') {
            delete vehicles[change.doc.id]
          } else if (data.assigned_user) {
            //@ts-ignore
            vehicles[change.doc.id] = {
              id: change.doc.id,
              ...data
            }
            setupVehicleStatusListener(data.assigned_user.id, change.doc.id)
          }
        })
        updatePhotoUploads()
      },
      (error) => {
        console.error('Vehicles listener error:', error)
        setError(error)
        setIsLoading(false)
      }
    )

    const vehicleStatusListeners = new Map<string, () => void>()

    const setupVehicleStatusListener = (userId: string, vehicleId: string) => {
      const listenerKey = `${userId}_${vehicleId}`
      
      if (vehicleStatusListeners.has(listenerKey)) {
        vehicleStatusListeners.get(listenerKey)?.()
        vehicleStatusListeners.delete(listenerKey)
      }

      const unsubscribe = onSnapshot(
        doc(db, 'users', userId, 'vehicles', vehicleId),
        (doc) => {
          if (!userVehicleStatuses[userId]) {
            userVehicleStatuses[userId] = {}
          }
          userVehicleStatuses[userId][vehicleId] = doc.data()
          updatePhotoUploads()
        },
        (error) => {
          console.error(`Vehicle status listener error for ${userId}/${vehicleId}:`, error)
        }
      )

      vehicleStatusListeners.set(listenerKey, unsubscribe)
    }

    const updatePhotoUploads = () => {
      const uploads = new Map<string, PhotoUploadModel>()

      Object.values(vehicles).forEach(vehicle => {
        const userId = vehicle.assigned_user?.id
        if (!userId || !users[userId]) return

        if (!uploads.has(userId)) {
          const userVehicles = Object.values(vehicles).filter(
            vehicle => vehicle.assigned_user?.id === userId
          )
          const submittedCount = userVehicles.reduce(
            (count, vehicle) => count + (userVehicleStatuses[userId]?.[vehicle.id]?.uploadId ? 1 : 0),
            0
          )
          
          let status = 'Not submitted'
          if (submittedCount > 0) {
            if (submittedCount === userVehicles.length) {
              status = 'Fully submitted'
            } else {
              status = `${submittedCount}/${userVehicles.length} submitted`
            }
          }

          uploads.set(userId, {
            id: userId,
            email: users[userId].email || 'No email',
            phone: users[userId].phone || '',
            user_type: users[userId].user_type,
            status: status as 'Submitted' | 'Not submitted' | "Pending" | "Complete" | "In Progress",
            submissionCount: submittedCount,
            dateCreated: new Date().toISOString(),
            vehicles: []
          })
        }

        const upload = uploads.get(userId)!
        const vehicleStatus = userVehicleStatuses[userId]?.[vehicle.id]
        
        const vehicleWithStatus = {
          ...vehicle,
          status: vehicleStatus?.uploadId ? 'Submitted' : 'Not submitted' as 'Submitted' | 'Not submitted'
        }

        upload.vehicles.push(vehicleWithStatus)
      })

      setPhotoUploads(Array.from(uploads.values()))
      setIsLoading(false)
    }

    return () => {
      unsubscribeUsers()
      unsubscribeVehicles()
      vehicleStatusListeners.forEach(unsubscribe => unsubscribe())
    }
  }, [])

  useEffect(() => {
    console.log('PhotoUploads data changed:', {
      isLoading,
      dataLength: photoUploads?.length,
      firstItem: photoUploads?.[0]
    })
  }, [isLoading, photoUploads])

  const ActionCellRenderer = (props: any) => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setSelectedUpload(props.data)
          setIsViewDialogOpen(true)
        }}
        disabled={!props.data.vehicles?.length}
      >
        View All Photos
      </Button>
      <IconButton
        aria-label="Generate Photo Upload Link"
        onClick={() => {
          setSelectedVehicleForUpload({
            ...props.data.vehicles[0],
            assigned_user: {
              id: props.data.id,
              email: props.data.email,
              user_type: props.data.user_type
            },
            allVehicles: props.data.vehicles
          })
          setIsPhotoUploadDialogOpen(true)
        }}
        disabled={!props.data.email || !props.data.vehicles?.length}
        sx={{ 
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          }
        }}
      >
        <PhotoAlbumOutlined />
      </IconButton>
    </Box>
  )

  const columnDefs = useMemo<ColDef[]>(() => [
    { 
      field: 'email',
      headerName: 'Email',
      width: 200,
      filter: 'agTextColumnFilter',
      cellRenderer: 'agGroupCellRenderer'
    },
    { 
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      filter: 'agTextColumnFilter'
    },
    { 
      field: 'status',
      headerName: 'Status',
      width: 130,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => {
        const chipStyles = {
          borderRadius: '12px',
          padding: '2px 8px',
          display: 'inline-flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontWeight: 500,
          lineHeight: '1.5',
          whiteSpace: 'nowrap'
        }

        if (params.value === 'Fully submitted') {
          return (
            <Box
              sx={{
                ...chipStyles,
                backgroundColor: 'success.main',
                color: 'white',
              }}
            >
              {params.value}
            </Box>
          )
        }
        
        if (params.value === 'Not submitted') {
          return (
            <Box
              sx={{
                ...chipStyles,
                backgroundColor: 'error.main',
                color: 'white',
              }}
            >
              {params.value}
            </Box>
          )
        }

        if (params.value.includes('/')) {
          return (
            <Box
              sx={{
                ...chipStyles,
                backgroundColor: 'warning.main',
                color: 'warning.contrastText',
              }}
            >
              {params.value}
            </Box>
          )
        }

        return params.value
      }
    },
    {
      field: 'vehicles',
      headerName: 'Vehicle Count',
      width: 130,
      valueGetter: (params: ValueGetterParams) => params.data?.vehicles?.length || 0,
      filter: 'agNumberColumnFilter'
    },
    { 
      field: 'submissionCount',
      headerName: 'Photo Submissions',
      width: 130,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Actions',
      width: 150,
      cellRenderer: ActionCellRenderer,
      sortable: false,
      filter: false
    }
  ], [])

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    flex: 1,
  }), [])

  const chipStyles = {
    borderRadius: '12px',
    padding: '2px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.5',
    whiteSpace: 'nowrap'
  }

  const StatusChip = ({ status }: { status: string }) => (
    <Box
      sx={{
        ...chipStyles,
        backgroundColor: status === 'Submitted' ? 'success.main' : 'error.main',
        color: 'white',
      }}
    >
      {status}
    </Box>
  );

  const detailCellRenderer = useMemo(() => {
    return (params: ICellRendererParams) => {
      const detailGridOptions = {
        columnDefs: [
          { field: 'make', headerName: 'Make' },
          { field: 'model', headerName: 'Model' },
          { field: 'year', headerName: 'Year' },
          { field: 'vin', headerName: 'VIN' },
          { 
            field: 'status', 
            headerName: 'Status',
            cellRenderer: (params: any) => <StatusChip status={params.value} />,
            width: 130,
          }
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
        },
        rowData: params.data.vehicles,
        domLayout: 'autoHeight' as const,
        suppressHorizontalScroll: true,
      }

      return (
        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact {...detailGridOptions} />
        </div>
      )
    }
  }, [])

  const isRowMaster = useCallback((dataItem: any) => {
    return dataItem ? dataItem.vehicles?.length > 0 : false
  }, [])

  const handleAssignUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await assignUserToVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User assigned successfully', 'success')
        setAssignUserDialogOpen(false)
        setSelectedVehicleForAssignment(null)
      } catch (error) {
        showSnackbar('Error assigning user', 'error')
      }
    }
  }

  const handleRemoveUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await removeUserFromVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User removed successfully', 'success')
      } catch (error) {
        showSnackbar('Error removing user', 'error')
      }
    }
  }

  return (
    <>
      <PageTitle
        title="Photo Uploader"
        subtitle="Manage photo upload links and submissions"
        bulletPoints={[
          'View all users with vehicles requiring photos',
          'Track photo submission status for each vehicle',
          'View submitted photos and vehicle details'
        ]}
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              startIcon={<History />}
              onClick={() => setIsHistoryDialogOpen(true)}
            >
              HISTORY
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsInitiateDialogOpen(true)}
            >
              NEW PHOTO UPLOAD
            </Button>
          </Box>
        }
      />

      {error ? (
        <Box sx={{ p: 4, textAlign: 'center', color: 'error.main' }}>
          Error loading data: {(error as Error).message}
        </Box>
      ) : isLoading ? (
        <Box sx={{ p: 4, textAlign: 'center' }}>Loading...</Box>
      ) : (
        <Box className="ag-theme-alpine" sx={{ height: 600, width: '100%' }}>
          <AgGridReact
            rowData={photoUploads}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            suppressCellFocus={true}
            masterDetail={true}
            detailCellRenderer={detailCellRenderer}
            isRowMaster={isRowMaster}
            detailRowHeight={250}
            overlayNoRowsTemplate="<span style='padding: 10px'>No photo uploads found</span>"
            overlayLoadingTemplate="<span style='padding: 10px'>Loading...</span>"
            loading={isLoading}
          />
        </Box>
      )}

      {selectedUpload && (
        <ViewPhotoSubmissionsDialog
          open={isViewDialogOpen}
          onClose={() => {
            setIsViewDialogOpen(false)
            setSelectedUpload(null)
          }}
          vehicles={selectedUpload.vehicles}
          userId={selectedUpload.id}
        />
      )}

      <AssignUserDialog
        open={assignUserDialogOpen}
        onClose={() => {
          setAssignUserDialogOpen(false)
          setSelectedVehicleForAssignment(null)
        }}
        onAssign={handleAssignUser}
        onRemove={handleRemoveUser}
        currentUserId={selectedVehicleForAssignment ? photoUploads?.find(
          upload => upload.vehicles?.some((v) => v.id === selectedVehicleForAssignment)
        )?.vehicles?.find((v: PhotoUploadVehicle) => v.id === selectedVehicleForAssignment)?.assigned_user?.id : undefined}
        vehicleId={selectedVehicleForAssignment || ''}
        collectionName="master_inventory"
      />

      {selectedVehicleForUpload && (
        <PhotoUploadDialog
          open={isPhotoUploadDialogOpen}
          onClose={() => {
            setIsPhotoUploadDialogOpen(false)
            setSelectedVehicleForUpload(null)
          }}
          //@ts-ignore
          vehicle={selectedVehicleForUpload}
        />
      )}

      <InitiatePhotoUploadDialog
        open={isInitiateDialogOpen}
        onClose={() => setIsInitiateDialogOpen(false)}
      />

      <PhotoUploadHistory
        open={isHistoryDialogOpen}
        onClose={() => setIsHistoryDialogOpen(false)}
      />
    </>
  )
}

export default PhotoUpload