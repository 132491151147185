import { AttachMoney, ExpandLess, ExpandMore, FilterList, LocalShipping, Phone, Speed, Search, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Drawer,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  Collapse,
  Chip,
  Stack,
  CircularProgress,
} from '@mui/material'
import { formatDistance, formatPrice } from 'core/utils/inventoryUtils'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useState, useEffect } from 'react'
import { getVehicleSizeOptions, vehicleFuelOptions, vehicleTypeOptions } from 'core/constants/inventory'
import { useInventory, VehicleFilters } from 'hooks/useInventory'

interface VehicleSidebarFilterProps {
  filters: VehicleFilters
  onFilterChange: (filters: VehicleFilters) => void
  onFilterClear: () => void
  isAdmin?: boolean
}

interface VehicleFilterListProps {
  filters: VehicleFilters
  onFilterChange: (filters: VehicleFilters) => void
  onFilterClear: () => void
  isAdmin?: boolean
  onClose?: () => void
}

const initialFilters: VehicleFilters = {
  vin: '',
  stockNumber: '',
  type: '',
  size: '',
  fuel: '',
  miles: [0, 500000],
  listPrice: [0, 100000],
  askingPrice: [0, 100000],
  potentialProfit: [0, 50000],
  location: '',
  contact: '',
  addedDate: null,
  isAvailable: false,
}

const getActiveFilterCount = (filters: VehicleFilters): number => {
  return Object.entries(filters).filter(([key, value]) => {
    const initialValue = initialFilters[key as keyof VehicleFilters]
    
    if (Array.isArray(value) && Array.isArray(initialValue)) {
      return value[0] !== initialValue[0] || value[1] !== initialValue[1]
    }

    if (value === null || value === undefined) return false
    
    if (key === 'addedDate') {
      if (!value || !initialValue) return false
      return (value as Date).getTime() !== (initialValue as Date).getTime()
    }

    return value !== initialValue
  }).length
}

const VehicleSidebarFilter: React.FC<VehicleSidebarFilterProps> = ({ filters, onFilterChange, onFilterClear, isAdmin }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const isMobile = useIsMobile()
  const activeFilterCount = getActiveFilterCount(filters)

  return (
    <>
      {!isMobile && (
        <Box height='fit-content' top={24} boxShadow={1} minWidth={300} position='sticky'>
          <VehicleFilterList 
            filters={filters} 
            onFilterChange={onFilterChange} 
            onFilterClear={onFilterClear} 
            isAdmin={isAdmin} 
          />
        </Box>
      )}
      {isMobile && (
        <Box>
          <Fab
            variant='extended'
            size='medium'
            color='primary'
            aria-label='filter'
            onClick={() => setIsFilterOpen(true)}
            sx={{
              display: { xs: 'block', sm: 'none' },
              position: 'fixed',
              bottom: 16,
              left: 16,
              zIndex: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterList sx={{ marginRight: 1 }} />
              <Typography variant='button'>
                Filter {activeFilterCount > 0 && `(${activeFilterCount})`}
              </Typography>
            </Box>
          </Fab>
          <Drawer
            open={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
            anchor='left'
            sx={{ width: { xs: '100%', sm: 'auto' }, display: { sm: 'none' } }}
          >
            <VehicleFilterList 
              filters={filters} 
              onFilterChange={onFilterChange} 
              onFilterClear={onFilterClear} 
              isAdmin={isAdmin}
              onClose={() => setIsFilterOpen(false)}
            />
          </Drawer>
        </Box>
      )}
    </>
  )
}

const formatFilterValue = (key: keyof VehicleFilters, value: any): string => {
  switch (key) {
    case 'miles':
    case 'listPrice':
    case 'askingPrice':
    case 'potentialProfit':
      return `${value[0]} - ${value[1]}`
    case 'isAvailable':
      return value ? 'Available' : ''
    default:
      return String(value)
  }
}

const VehicleFilterList: React.FC<VehicleFilterListProps> = ({ filters, onFilterChange, onFilterClear, isAdmin, onClose }) => {
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({
    general: true,
    performance: false,
    pricing: false,
    contact: false,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFormValues(filters)
  }, [filters])

  const [formValues, setFormValues] = useState<VehicleFilters>(filters)

  const toggleSection = (section: string) => {
    setExpandedSections(prevSections => ({ ...prevSections, [section]: !prevSections[section] }))
  }

  const handleFilterChange = (field: keyof VehicleFilters, value: any) => {
    setFormValues(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleRemoveFilter = (key: keyof VehicleFilters) => {
    handleFilterChange(key, initialFilters[key])
    onFilterChange({
      ...formValues,
      [key]: initialFilters[key]
    })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    onClose?.()
    console.log('Submitting filters:', formValues)
    onFilterChange(formValues)
  }

  const handleClearFilters = () => {
    setLoading(true)
    onClose?.()
    setFormValues(initialFilters)
    onFilterClear()
  }

  const activeFilters = Object.entries(formValues).filter(([key, value]) => {
    const initialValue = initialFilters[key as keyof VehicleFilters]
    
    if (Array.isArray(value) && Array.isArray(initialValue)) {
      // Handle array values (ranges)
      return value[0] !== initialValue[0] || value[1] !== initialValue[1]
    }

    // Handle non-array values
    if (value === null || value === undefined) return false
    
    // Special handling for Date objects
    if (key === 'addedDate') {
      if (!value || !initialValue) return false
      return (value as Date).getTime() !== (initialValue as Date).getTime()
    }

    // Handle all other values
    return value !== initialValue
  })


  return (
    <form onSubmit={handleSubmit}>
      <List>
        {/* General Information */}
        <ListItemButton onClick={() => toggleSection('general')}>
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary='General Information' />
          {expandedSections.general ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandedSections.general} timeout='auto' unmountOnExit>
          <ListItem>
            <TextField
              fullWidth
              label='VIN'
              value={formValues.vin}
              onChange={e => handleFilterChange('vin', e.target.value)}
            />
          </ListItem>
          <ListItem>
            <Select
              fullWidth
              value={formValues.type}
              onChange={e => {
                handleFilterChange('type', e.target.value)
                handleFilterChange('size', '')
              }}
              displayEmpty
            >
              <MenuItem value=''>Select Type</MenuItem>
              {vehicleTypeOptions.map(vehicleType => (
                <MenuItem key={vehicleType} value={vehicleType}>
                  {vehicleType}
                </MenuItem>
              ))}
            </Select>
          </ListItem>
          {formValues.type && (
            <>
              <ListItem>
                <TextField
                  fullWidth
                  label='Stock number'
                  value={formValues.stockNumber}
                  onChange={e => handleFilterChange('stockNumber', e.target.value)}
                />
              </ListItem>
              <ListItem>
                <Select
                  fullWidth
                  value={formValues.size}
                  onChange={e => handleFilterChange('size', e.target.value)}
                  displayEmpty
                >
                  <MenuItem value=''>Select Size</MenuItem>
                  {getVehicleSizeOptions(formValues.type).map(vehicleSize => (
                    <MenuItem key={vehicleSize} value={vehicleSize}>
                      {vehicleSize}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            </>
          )}
        </Collapse>

        {/* Performance */}
        <ListItemButton onClick={() => toggleSection('performance')}>
          <ListItemIcon>
            <Speed />
          </ListItemIcon>
          <ListItemText primary='Performance' />
          {expandedSections.performance ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandedSections.performance} timeout='auto' unmountOnExit>
          <ListItem>
            <Select
              fullWidth
              value={formValues.fuel}
              onChange={e => handleFilterChange('fuel', e.target.value)}
              displayEmpty
            >
              <MenuItem value=''>Select Fuel Type</MenuItem>
              {vehicleFuelOptions.map(fuelType => (
                <MenuItem key={fuelType} value={fuelType}>
                  {fuelType}
                </MenuItem>
              ))}
            </Select>
          </ListItem>
          <ListItem>
            <Box sx={{ width: '80%', mx: 'auto', py: 2 }}>
              <Typography gutterBottom>
                <strong>Miles</strong>: {formatDistance(String(formValues.miles[0]))} -{' '}
                {formatDistance(String(formValues.miles[1]))}
              </Typography>
              <Slider
                value={formValues.miles}
                onChange={(_, newValue) => handleFilterChange('miles', newValue)}
                valueLabelDisplay='auto'
                min={0}
                max={500000}
                marks={[
                  { value: 0, label: '0' },
                  { value: 500000, label: '500k' },
                ]}
              />
            </Box>
          </ListItem>
        </Collapse>

        {/* Pricing */}
        <ListItemButton onClick={() => toggleSection('pricing')}>
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary='Pricing' />
          {expandedSections.pricing ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandedSections.pricing} timeout='auto' unmountOnExit>
          {[
            { key: 'askingPrice', label: 'Sell Price', max: 100000 },
            ...(isAdmin
              ? [
                  { key: 'listPrice', label: 'Buy Price', max: 100000 },
                  { key: 'potentialProfit', label: 'Potential Profit', max: 50000 },
                ]
              : []),
          ].map(({ key, label, max }) => (
            <ListItem key={key}>
              <Box sx={{ width: '80%', mx: 'auto', py: 2 }}>
                <Typography gutterBottom>
                  <strong>{label}</strong>:{' '}
                  {formatPrice(String(formValues[key as keyof VehicleFilters] as [number, number])[0])} -{' '}
                  {formatPrice(String((formValues[key as keyof VehicleFilters] as [number, number])[1]))}
                </Typography>
                <Slider
                  value={formValues[key as keyof VehicleFilters] as [number, number]}
                  onChange={(_, newValue) => handleFilterChange(key as keyof VehicleFilters, newValue)}
                  valueLabelDisplay='auto'
                  min={0}
                  max={max}
                  marks={[
                    { value: 0, label: '0' },
                    { value: max, label: `${max / 1000}k` },
                  ]}
                />
              </Box>
            </ListItem>
          ))}
        </Collapse>

        {/* Contact */}
        <ListItemButton onClick={() => toggleSection('contact')}>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText primary='Contact' />
          {expandedSections.contact ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandedSections.contact} timeout='auto' unmountOnExit>
          <ListItem>
            <TextField
              fullWidth
              label='Contact'
              value={formValues.contact}
              onChange={e => handleFilterChange('contact', e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
              fullWidth
              label='Location'
              value={formValues.location}
              onChange={e => handleFilterChange('location', e.target.value)}
            />
          </ListItem>
        </Collapse>

        {/* Status */}
        <ListItem>
          <ListItemText primary='Available' />
          <Switch checked={formValues.isAvailable} onChange={e => handleFilterChange('isAvailable', e.target.checked)} />
        </ListItem>

        {/* Active Filters */}
        {activeFilters.length > 0 && (
          <ListItem>
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1, mt: 1, mb: 1 }}>
              {activeFilters.map(([key, value]) => (
                <Chip
                  key={key}
                  label={`${key.replace(/([A-Z])/g, ' $1').toLowerCase()}: ${formatFilterValue(key as keyof VehicleFilters, value)}`}
                  onDelete={() => handleRemoveFilter(key as keyof VehicleFilters)}
                  deleteIcon={<Close />}
                  size="small"
                  sx={{
                    bgcolor: theme => theme.palette.primary.main,
                    color: 'white',
                    '& .MuiChip-deleteIcon': {
                      color: 'white',
                    },
                  }}
                />
              ))}
            </Stack>
          </ListItem>
        )}

        {/* Action Buttons */}
        <ListItem sx={{ gap: 1, mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            startIcon={<Search />}
            sx={{
              bgcolor: theme => theme.palette.primary.main,
              color: 'white',
              '&:hover': {
                bgcolor: theme => theme.palette.primary.dark,
              },
            }}
          >
            Apply Filters {activeFilters.length > 0 && `(${activeFilters.length})`}
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </ListItem>
      </List>
    </form>
  )
}
export default VehicleSidebarFilter
