import { deleteDoc, doc, updateDoc } from '@firebase/firestore'
import { CarCrash, PhotoAlbumOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import ListIcon from '@mui/icons-material/List'
import TruckIcon from '@mui/icons-material/LocalShipping'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import { Box, Button, Grid, Switch, ToggleButton, ToggleButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { GridActionsCellItem, GridColDef, GridRowId, GridRenderCellParams } from '@mui/x-data-grid'
import { PageTitle } from 'components/PageTitle'
import DataGridFirestoreCRUD from 'components/table/DataGridFirestoreCRUD'
import { SelectionButtonT } from 'components/table/DataGridToolBar'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { downloadShareableInventoryCsv } from 'core/api/inventory/downloadShareableInventoryCSV'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import { handleInventoryCsvUpload } from 'core/api/inventory/handleInventoryCsvUpload'
import removeFromWebsiteInventory from 'core/api/inventory/removeFromWebsiteInventory'
import { addToWebsiteInventory } from 'core/api/inventory/showHideOnWebsiteInventory'
import { db } from 'core/config/firebase'
import {
  calculateProfit,
  formatDateTime,
  formatDistance,
  formatPrice,
  sanitizeNumber,
} from 'core/utils/inventoryUtils'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import { useIsMobile } from 'hooks/screen/useIsMobile'

import React, { FC, useEffect, useState } from 'react'
import VehicleCard from './components/Cards/VehicleCard'
import ManagePhotosDialog from './components/Dialogs/ManagePhotosDialog'
import PhotoUploadDialog from './components/Dialogs/PhotoUploadDialog'
import SelectMainPhotoDialog from './components/Dialogs/SelectMainWebsitePhotoDialog'
import VehicleForm from './components/Forms/VehicleForm'
import VehicleSidebarFilter, { FilterState } from './components/VehicleSidebarFilter'
import { assignUserToVehicle } from 'core/api/inventory/assignUserToVehicle'
import { removeUserFromVehicle } from 'core/api/inventory/removeUserFromVehicle'
import { vehicleStatusOptions } from 'core/constants/inventory'

interface InventoryManagementProps {
  inventoryCollectionName: string
}

const InventoryManagement: FC<InventoryManagementProps> = ({ inventoryCollectionName }) => {
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false)
  const [openManagePhotosDialog, setOpenManagePhotosDialog] = useState(false)
  const [openSelectMainPhotoDialog, setOpenSelectMainPhotoDialog] = useState(false)
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [view, setView] = React.useState<'card' | 'list'>('list')
  const { showSnackbar } = useSnackbar()

  const { userInfo } = useAuth()
  const uType = userInfo?.user_type

  const isMobile = useIsMobile()
  const isOpticInventory = inventoryCollectionName === 'master_inventory'
  
  const userGuidelines = [
    'Upload CSV to add multiple trucks at once.',
    'You can edit the inventory details by double clicking on the row. Select multiple rows to delete them.',
    "Use the 'Send Photo Upload' button to send a photo upload to a representative.",
  ]

  const handleManagePhotosClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle as Vehicle)
    setOpenManagePhotosDialog(true)
  }

  const handleShowHideOnWebsiteClick = async (vehicle: Vehicle) => {
    if (vehicle.show_on_website !== true) {
      setOpenSelectMainPhotoDialog(true)
    }

    const inventoryDocRef = doc(db, inventoryCollectionName, vehicle.id)

    if (vehicle.show_on_website) {
      await removeFromWebsiteInventory(vehicle.id)
    } else {
      await addToWebsiteInventory(vehicle.id)
    }
    await updateDoc(inventoryDocRef, { show_on_website: !vehicle.show_on_website })
  }

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [selectedVehicleForVisibility, setSelectedVehicleForVisibility] = React.useState<Vehicle | null>(null)

  const handleShowHideClick = (vehicle: Vehicle) => {
    setSelectedVehicleForVisibility(vehicle)
    setShowConfirmDialog(true)
  }

  const handleConfirmShowHide = () => {
    if (selectedVehicleForVisibility) {
      handleShowHideOnWebsiteClick(selectedVehicleForVisibility)
    }
    setShowConfirmDialog(false)
    setSelectedVehicleForVisibility(null)
  }

  const columns = React.useMemo(() => {
    return getInventoryColumns({
      isAdmin: uType === 'admin',
      onManagePhotosClick: handleManagePhotosClick,
      onShowHideOnWebsiteClick: isOpticInventory
        ? handleShowHideOnWebsiteClick
        : () => showSnackbar('Only available for Optic Inventory', 'error'),
      handleShowHideClick,
    })
  }, [uType, isOpticInventory, handleManagePhotosClick, handleShowHideOnWebsiteClick])

  const handleShareInventoryListClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())
    downloadShareableInventoryPdf(inventoryCollectionName, masterInventoryIds)
  }

  const handleDownloadInventoryCSVClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())

    if (uType) {
      downloadShareableInventoryCsv(inventoryCollectionName, masterInventoryIds, uType)
    }
  }

  const customToolbarSelectionButtons: SelectionButtonT[] = [
    {
      icon: <CarCrash />,
      label: 'Download PDF',
      onClick: handleShareInventoryListClick,
    },
    {
      icon: <CarCrash />,
      label: 'Download CSV',
      onClick: handleDownloadInventoryCSVClick,
    },
  ]

  const {
    data: vehicles,
    isLoading,
    isSuccess: loadedVehicles,
    refetch: refetchVehicles,
  } = useQueryFirestoreDocuments({
    inventoryCollectionName,
    useQueryOptions: {
      subscribe: true,
    },
  })

  useEffect(() => {
    refetchVehicles
  }, [loadedVehicles, inventoryCollectionName])

  useEffect(() => {
    if (vehicles) {
      // can be deleted when no duplicate vins loaded via csv
      const uniqueVehicles = (vehicles as Vehicle[]).filter(
        (vehicle, index, self) => index === self.findIndex(v => v.vin === vehicle.vin),
      )
      setFilteredVehicles(uniqueVehicles)
    }
  }, [loadedVehicles])

  const { mutate: addFirebaseDocument } = useAddFirestoreDocument({ collectionName: inventoryCollectionName })

  // TODO: Replace with react-query
  const handleDeleteVehicle = async (vehicleId: string) => {
    const inventoryRef = doc(db, inventoryCollectionName, vehicleId)
    await deleteDoc(inventoryRef)
  }

  const handleAddVehicle = async (data: { [key: string]: string | number | boolean }) => {
    const newValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }
    addFirebaseDocument(newValues)
    setOpenVehicleForm(false)
  }

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
    sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
    sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }

    try {
      const docRef = doc(db, inventoryCollectionName, selectedVehicle.id)
      await updateDoc(docRef, newValues)
      setOpenVehicleForm(false)
    } catch (error) {
      showSnackbar('Error updating vehicle', 'error')
      console.error('Error updating vehicle:', error)
    }
  }

  const handlePhotoUploadClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenPhotoUploadDialog(true)
  }

  const handleClickVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleForm(true)
  }

  const handleDownloadCSVTemplate = () => {
    window.open('/inventory_template.csv')
  }

  const handleFilterChange = (filters: FilterState) => {
    if (!vehicles) return

    const uniqueVehicles = (vehicles as Vehicle[]).filter(
      (vehicle, index, self) => index === self.findIndex(v => v.vin === vehicle.vin),
    )

    const filtered = uniqueVehicles.filter(vehicle => {
      return (
        (!filters.vin || (vehicle.vin || '').toLowerCase().includes(filters.vin.toLowerCase())) &&
        (!filters.stockNumber || (vehicle.stock_number || '').toLowerCase().includes(filters.stockNumber.toLowerCase())) &&
        (!filters.type || vehicle.type === filters.type) &&
        (!filters.size || vehicle.size === filters.size) &&
        (!filters.fuel || vehicle.fuel === filters.fuel) &&
        Number(vehicle.miles) >= filters.miles[0] &&
        Number(vehicle.miles) <= filters.miles[1] &&
        Number(vehicle.optic_list_price) >= filters.listPrice[0] &&
        Number(vehicle.optic_list_price) <= filters.listPrice[1] &&
        Number(vehicle.seller_asking_price) >= filters.askingPrice[0] &&
        Number(vehicle.seller_asking_price) <= filters.askingPrice[1] &&
        (!filters.location || (vehicle.location || '').toLowerCase().includes(filters.location.toLowerCase())) &&
        (!filters.isAvailable || vehicle.status === 'Available')
      )
    })
    setFilteredVehicles(filtered as Vehicle[])
  }

  const handleFilterClear = () => {
    setFilteredVehicles((vehicles as Vehicle[]) ?? [])
  }

  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false)
  const [selectedVehicleForAssignment, setSelectedVehicleForAssignment] = useState<string | null>(null)

  const handleAssignUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        console.log("assigning user to vehicle", selectedVehicleForAssignment, userId, inventoryCollectionName)
        await assignUserToVehicle(selectedVehicleForAssignment, userId, inventoryCollectionName)
        showSnackbar('User assigned successfully', 'success')
        setAssignUserDialogOpen(false)
        setSelectedVehicleForAssignment(null)
        // Refresh the vehicles data
        refetchVehicles()
      } catch (error) {
        console.error('Error assigning user:', error)
        showSnackbar('Error assigning user', 'error')
      }
    }
  }

  const handleRemoveUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await removeUserFromVehicle(selectedVehicleForAssignment, userId, inventoryCollectionName)
        showSnackbar('User removed successfully', 'success')
        refetchVehicles()
      } catch (error) {
        console.error('Error removing user:', error)
        showSnackbar('Error removing user', 'error')
      }
    }
  }

  return (
    <>
      <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} justifyContent='space-between'>
        <PageTitle
          title={`${isOpticInventory ? 'Optic' : 'Shield'} Inventory Management`}
          subtitle='Manage the inventory of trucks.'
          bulletPoints={userGuidelines}
        />
        <Box
          maxWidth={isMobile ? '100%' : '400px'}
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems='center'
          gap={isMobile ? 2 : 1}
          justifyContent='flex-start'
        >
          <Button
            aria-hidden={false}
            variant='contained'
            startIcon={<TruckIcon />}
            onClick={() => {
              setSelectedVehicle(null)
              setOpenVehicleForm(true)
            }}
            fullWidth={isMobile}
            sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
          >
            Add Truck
          </Button>
          {!isMobile && (
            <>
              <Button
                variant='text'
                component='label'
                fullWidth={isMobile}
                sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                onClick={() => handleDownloadCSVTemplate()}
              >
                Download CSV Template
              </Button>

              <Button
                variant='text'
                component='label'
                fullWidth={isMobile}
                sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              >
                Upload CSV
                <input
                  type='file'
                  hidden
                  onChange={event => handleInventoryCsvUpload(event, inventoryCollectionName)}
                />
              </Button>
            </>
          )}
        </Box>
        {!isMobile && (
          <Box alignSelf='center'>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={(_event, newView) => {
                if (newView) {
                  setView(newView)
                }
              }}
              aria-label='text alignment'
            >
              <ToggleButton value='list' aria-label='list view'>
                <ListIcon />
              </ToggleButton>
              <ToggleButton value='card' aria-label='card view'>
                <SpaceDashboardIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Box>

      {isLoading ? (
        <div>Loading Vehicles...</div>
      ) : isMobile || view === 'card' ? (
        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2} p={2}>
          <VehicleSidebarFilter
            onFilterChange={handleFilterChange}
            onFilterClear={handleFilterClear}
            isAdmin={uType === 'admin'}
          />
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: 'center',
              flexWrap: 'wrap',
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {filteredVehicles &&
              filteredVehicles.map(vehicle => (
                <Grid item key={vehicle.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <VehicleCard
                    collectionName={inventoryCollectionName}
                    vehicle={vehicle}
                    onClick={() => handleClickVehicle(vehicle)}
                    onDeleteClick={() => handleDeleteVehicle(vehicle.id)}
                    onManagePhotosClick={() => handleManagePhotosClick(vehicle)}
                    onPhotoUploadClick={() => handlePhotoUploadClick(vehicle)}
                    onShowHideOnWebsiteClick={() => handleShowHideOnWebsiteClick(vehicle)}
                    isAdmin={uType === 'admin'}
                    onAssignUserClick={() => {
                      setSelectedVehicleForAssignment(vehicle.id)
                      setAssignUserDialogOpen(true)
                    }}
                    assignedUserName={vehicle.assigned_user?.email}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <DataGridFirestoreCRUD
          columns={columns}
          hiddenColumns={['engine', 'GVW', 'boxSize', 'transmission']}
          collectionName={inventoryCollectionName}
          editable={false}
          deleteable={true}
          create={true}
          search={true}
          multiDeleteable={true}
          onDeleteClick={handleDeleteVehicle}
          onDoubleClick={id => handleClickVehicle(vehicles?.find(vehicle => vehicle.id === id) as Vehicle)}
          customToolbarSelectionButtons={customToolbarSelectionButtons}
          viewOnlyMode={true}
          canShowDocViewer={userInfo?.email.includes('freetech.co')}
        />
      )}
      <VehicleForm
        open={openVehicleForm}
        vehicle={selectedVehicle}
        onClose={() => setOpenVehicleForm(false)}
        onSubmit={selectedVehicle ? handleEditVehicle : handleAddVehicle}
        generateStockNumber={() =>
          generateOpticStockNumber(
            (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
        }
      />
      {selectedVehicle && (
        <>
          <PhotoUploadDialog
            open={openPhotoUploadDialog}
            onClose={() => setOpenPhotoUploadDialog(false)}
            // @ts-ignore
            vehicle={selectedVehicle}
          />
          <ManagePhotosDialog
            collectionName={inventoryCollectionName}
            open={openManagePhotosDialog}
            onClose={() => setOpenManagePhotosDialog(false)}
            vehicle={selectedVehicle}
          />
          <SelectMainPhotoDialog
            collectionName={inventoryCollectionName}
            open={openSelectMainPhotoDialog}
            onClose={() => setOpenSelectMainPhotoDialog(false)}
            vehicle={selectedVehicle}
          />
        </>
      )}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <DialogTitle>
          {selectedVehicleForVisibility && 
            `${selectedVehicleForVisibility.year} ${selectedVehicleForVisibility.make} ${selectedVehicleForVisibility.model}`
          }
        </DialogTitle>
        <DialogContent>
          Are you sure you want to {selectedVehicleForVisibility?.show_on_website ? 'hide' : 'show'} this vehicle
          {selectedVehicleForVisibility?.show_on_website ? ' from' : ' on'} the website?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmShowHide} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface InventoryColumnsProps {
  isAdmin: boolean
  onManagePhotosClick: (vehicle: Vehicle) => void
  onShowHideOnWebsiteClick: (vehicle: Vehicle) => void
  handleShowHideClick: (vehicle: Vehicle) => void
}

const getInventoryColumns = ({
  isAdmin,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
  handleShowHideClick,
}: InventoryColumnsProps): GridColDef[] => {
  const columns: GridColDef[] = [
    ...(isAdmin
      ? [
          { field: 'who', headerName: 'Who', editable: true },
          {
            field: 'url',
            headerName: 'URL',
            minWidth: 80,
            renderCell: (params: GridRenderCellParams) =>
              params.value &&
              params.value.length > 0 && (
                <Button
                  variant='text'
                  onClick={() => window.open(params.value, '_blank', 'noopener,noreferrer')}
                  sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                >
                  View
                </Button>
              ),
          },
        ]
      : []),
    { field: 'stock_number', headerName: 'Stock Number', editable: true },
    { field: 'year', headerName: 'Year', editable: true },
    { field: 'make', headerName: 'Make', editable: true },
    { field: 'model', headerName: 'Model', editable: true },
    { field: 'size', headerName: 'Size', editable: true },
    { field: 'miles', headerName: 'Mileage', editable: true, valueFormatter: formatDistance },
    { field: 'fuel', headerName: 'Fuel', editable: true },
    { field: 'location', headerName: 'Location', editable: true },
    {
      field: 'optic_list_price',
      headerName: 'Our List Price',
      valueFormatter: formatPrice,
    },
    { field: 'type', headerName: 'Type', editable: true },
    ...(isAdmin
      ? [
          {
            field: 'vin',
            headerName: 'VIN',
            editable: true,
          },
          {
            field: 'seller_asking_price',
            headerName: 'Their Asking Price',
            valueFormatter: formatPrice,
          },
          {
            field: 'profit',
            headerName: 'Profit',
            valueFormatter: formatPrice,
          },
        ]
      : []),
    { field: 'sold_date', headerName: 'Sold Date' },
    {
      field: 'notes',
      headerName: 'Truck Notes',
      editable: true,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{params.value}</div>
      ),
    },
    ...(isAdmin
      ? [
          {
            field: 'phone_number',
            headerName: 'Phone Number',
            editable: true,
            minWidth: 100,
          },
        ]
      : []),
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: vehicleStatusOptions,
    },
    {
      field: 'Photos',
      type: 'actions',
      headerName: 'Photos',
      editable: false,
      flex: 1,
      getActions: params => [
        <GridActionsCellItem
          key={params.id}
          icon={<PhotoAlbumOutlined />}
          label='Send Reminder Email'
          onClick={() => onManagePhotosClick(params.row)}
          color='inherit'
          disabled={params.row.email_verified}
        />,
      ],
    },
    ...(isAdmin
      ? [
          {
            field: 'show_hide',
            headerName: 'Show Hide',
            editable: false,
            flex: 1,
            renderCell: (params: { id: React.Key | null | undefined; row: Vehicle }) => (
              <Switch
                key={params.id}
                checked={params.row.show_on_website}
                icon={<VisibilityOff />}
                checkedIcon={<Visibility />}
                onChange={() => handleShowHideClick(params.row)}
                color={params.row.show_on_website ? 'success' : 'error'}
              />
            ),
          },
          {
            field: 'Photo Upload',
            headerName: 'Photo Upload',
            editable: false,
            flex: 1,
            renderCell: (params: { id: React.Key | null | undefined; row: Vehicle }) => (
              <Switch
                key={params.id}
                checked={params.row.show_on_website}
                icon={<VisibilityOff />}
                checkedIcon={<Visibility />}
                onChange={() => onShowHideOnWebsiteClick(params.row)}
                color={params.row.show_on_website ? 'success' : 'error'}
              />
            ),
          },
        ]
      : []),

    {
      field: 'date_added',
      headerName: 'Timestamp',
      valueFormatter: formatDateTime,
    },
  ]

  return columns
}

export default InventoryManagement
