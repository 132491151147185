import React, { useState, useEffect } from 'react'
import { Badge, Box } from '@mui/material'
import { LocalShipping } from '@mui/icons-material'
import { collection, getDocs } from 'firebase/firestore'
import { db } from 'core/config/firebase'

const TransporterBadge = () => {
  const [unacceptedCount, setUnacceptedCount] = useState(0)

  useEffect(() => {
    const fetchUnacceptedVehicles = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'))
        let count = 0

        for (const userDoc of usersSnapshot.docs) {
          const userData = userDoc.data()
          if (userData.user_type === 'transporter') {
            const vehiclesSnapshot = await getDocs(collection(db, 'users', userDoc.id, 'vehicles'))
            for (const vehicleDoc of vehiclesSnapshot.docs) {
              const vehicleData = vehicleDoc.data()
              if (!vehicleData.accepted) {
                count++
              }
            }
          }
        }

        setUnacceptedCount(count)
      } catch (error) {
        console.error('Error fetching unaccepted vehicles:', error)
      }
    }

    fetchUnacceptedVehicles()
  }, [])

  return (
    <Box position='relative' sx={{ display: 'flex' }}>
      <Badge
        badgeContent={unacceptedCount}
        color="primary"
        sx={{
          '& .MuiBadge-badge': {
            top: 0,
            right: -4,
            fontSize: '0.6rem',
            minWidth: '16px',
            height: '16px',
            padding: '0 4px',
          },
        }}
      >
        <LocalShipping />
      </Badge>
    </Box>
  )
}

export default TransporterBadge 