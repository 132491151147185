import { fetchVinData } from "core/api/inventory/fetchVinData"

export const addVinData = async (vehicle: Omit<Vehicle, 'id'>): Promise<Omit<Vehicle, 'id'>> => {
  const vinData = await fetchVinData(vehicle.vin)
  const relevantVariables = new Map<string, keyof Omit<Vehicle, 'id'>>([
    ['Model year', 'year'],
    ['Model', 'model'],
    ['Make', 'make'],
    ['displacement', 'displacement'],
    ['Series', 'series'],
    ['Fuel Type - Primary', 'fuel'],
    ['Drive Type', 'drive_type'],
    ['Gross Vehicle Weight Rating From', 'gross_vehicle_weight'],
    ['Brake System Type', 'brake_system_type'],
  ])

  const expandedVindata = Object.fromEntries(
    vinData
      .filter(vinDataItem => {
        const isRelevant = relevantVariables.has(vinDataItem.Variable)
        if (!isRelevant) return false

        const vehicleKey = relevantVariables.get(vinDataItem.Variable)
        if (!vehicleKey) return false

        const isNeeded = !vehicle[vehicleKey] || vehicle[vehicleKey] === ''
        return isNeeded
      })
      .map(vinDataItem => [relevantVariables.get(vinDataItem.Variable), vinDataItem.Value]),
  )

  return {
    ...vehicle,
    ...expandedVindata,
  }
}
