import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { PhotoUpload, UserData, VehicleWithUser } from 'core/types/photoUpload'

export const fetchPhotoUploads = (
  setPhotoUploads: React.Dispatch<React.SetStateAction<PhotoUpload[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Error | null>>
) => {
  const users: { [key: string]: UserData } = {}
  const vehicles: { [key: string]: VehicleWithUser } = {}
  const userVehicleStatuses: { [key: string]: { [key: string]: any } } = {}

  const unsubscribeUsers = onSnapshot(
    collection(db, 'users'),
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'removed') {
          delete users[change.doc.id]
        } else {
          users[change.doc.id] = {
            id: change.doc.id,
            email: change.doc.data().email,
            phone: change.doc.data().phone,
            user_type: change.doc.data().user_type,
            ...change.doc.data()
          }
        }
      })
      updatePhotoUploads()
    },
    (error) => {
      console.error('Users listener error:', error)
      setError(error)
      setIsLoading(false)
    }
  )

  const unsubscribeVehicles = onSnapshot(
    collection(db, 'master_inventory'),
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data()
        if (change.type === 'removed') {
          delete vehicles[change.doc.id]
        } else if (data.assigned_user) {
          vehicles[change.doc.id] = {
            id: change.doc.id,
            assigned_user: data.assigned_user,
            ...data
          }
          setupVehicleStatusListener(data.assigned_user.id, change.doc.id)
        }
      })
      updatePhotoUploads()
    },
    (error) => {
      console.error('Vehicles listener error:', error)
      setError(error)
      setIsLoading(false)
    }
  )

  const vehicleStatusListeners = new Map<string, () => void>()

  const setupVehicleStatusListener = (userId: string, vehicleId: string) => {
    const listenerKey = `${userId}_${vehicleId}`
    
    if (vehicleStatusListeners.has(listenerKey)) {
      vehicleStatusListeners.get(listenerKey)?.()
      vehicleStatusListeners.delete(listenerKey)
    }

    const unsubscribe = onSnapshot(
      doc(db, 'users', userId, 'vehicles', vehicleId),
      (doc) => {
        if (!userVehicleStatuses[userId]) {
          userVehicleStatuses[userId] = {}
        }
        userVehicleStatuses[userId][vehicleId] = doc.data()
        updatePhotoUploads()
      },
      (error) => {
        console.error(`Vehicle status listener error for ${userId}/${vehicleId}:`, error)
      }
    )

    vehicleStatusListeners.set(listenerKey, unsubscribe)
  }

  const updatePhotoUploads = () => {
    const uploads = new Map<string, PhotoUpload>()

    Object.values(vehicles).forEach(vehicle => {
      const userId = vehicle.assigned_user?.id
      if (!userId || !users[userId]) return

      if (!uploads.has(userId)) {
        const userVehicles = Object.values(vehicles).filter(
          vehicle => vehicle.assigned_user?.id === userId
        )

        let preDeliveryCount = 0
        let postDeliveryCount = 0

        userVehicles.forEach(vehicle => {
          const vehicleStatus = userVehicleStatuses[userId]?.[vehicle.id]
          if (vehicleStatus?.deliveryStatus === 'preDelivery') {
            preDeliveryCount++
          } else if (vehicleStatus?.deliveryStatus === 'postDelivery') {
            postDeliveryCount++
            preDeliveryCount++
          }
        })

        uploads.set(userId, {
          id: userId,
          email: users[userId].email || 'No email',
          phone: users[userId].phone || '',
          user_type: users[userId].user_type || 'transporter',
          status: 'Not submitted',
          submissionCount: preDeliveryCount + postDeliveryCount,
          dateCreated: new Date().toISOString(),
          vehicles: [],
          preDeliverySubmissions: preDeliveryCount,
          postDeliverySubmissions: postDeliveryCount
        })
      }

      const upload = uploads.get(userId)!
      const vehicleStatus = userVehicleStatuses[userId]?.[vehicle.id]
      
      const vehicleWithStatus = {
        ...vehicle,
        status: vehicleStatus?.uploadId ? 'Submitted' : 'Not submitted' as 'Submitted' | 'Not submitted'
      }

      upload.vehicles.push(vehicleWithStatus)
    })

    setPhotoUploads(Array.from(uploads.values()))
    setIsLoading(false)
  }

  return () => {
    unsubscribeUsers()
    unsubscribeVehicles()
    vehicleStatusListeners.forEach(unsubscribe => unsubscribe())
  }
} 