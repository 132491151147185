/* eslint-disable */

// TODO: Another messy typing on debt overhaul that needs to be revisited

import { Alert, Box, Button, Grid, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'

import NewDebtOverhaulDialog from './components/NewDebtOverhaulDialog'

import { getTruckDebtOverhaulSubmissions, getDebtOverhaulTrucks } from 'core/api/application/client'

import { IsLoading } from 'components'

function createData(
  submission_id: number,
  dateSubmitted: Date,
  numOfTrucks: number,
  monthlyTruckPayment: number,
  trucks: any,
) {
  return {
    submission_id,
    dateSubmitted,
    numOfTrucks,
    monthlyTruckPayment,
    trucks,
  }
}

function Row(props: {
  row: ReturnType<typeof createData>
  setEditing: (isEditing: boolean, submissionId: number) => void
  closeDebtOverhaulDialog: () => void
  openDebtOverhaulDialog: () => void
}) {
  const { row, setEditing, closeDebtOverhaulDialog, openDebtOverhaulDialog } = props
  const [open, setOpen] = React.useState(false)

  const submittedDate = new Date(row.dateSubmitted).toDateString()

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {submittedDate}
        </TableCell>
        <TableCell align='right'>{row.numOfTrucks}</TableCell>
        <TableCell align='right'>{row.monthlyTruckPayment}</TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setEditing(true, row.submission_id)
              openDebtOverhaulDialog()
            }}
            variant='contained'
          >
            Edit or Add Trucks
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Truck Details
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Fuel Type</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Miles</TableCell>
                    <TableCell align='right'>Current Payoff ($)</TableCell>
                    <TableCell align='right'>Monthly Payment ($)</TableCell>
                    <TableCell>Purchase Date</TableCell>
                    <TableCell>Bank</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.trucks.map((truckRow: any) => (
                    <TableRow key={truckRow.vin}>
                      <TableCell component='th' scope='row'>
                        {truckRow.year}
                      </TableCell>
                      <TableCell>{truckRow.fuelType}</TableCell>
                      <TableCell>{truckRow.vin}</TableCell>
                      <TableCell>{truckRow.model}</TableCell>
                      <TableCell>{truckRow.size}</TableCell>
                      <TableCell>{truckRow.miles.toLocaleString('en-US')}</TableCell>
                      <TableCell align='right'>{truckRow.currentPayoff}</TableCell>
                      <TableCell align='right'>{truckRow.monthlyPayment}</TableCell>
                      <TableCell>{truckRow.purchaseDate}</TableCell>
                      <TableCell>{truckRow.bank}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const DebtOverhaul = (): JSX.Element => {
  const [newDebtOverhaulVisible, setNewDebtOverhaulVisible] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState({
    isEditing: false,
    submissionId: -1,
  })

  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
  })

  const resetErrorState = () => {
    setError({ isError: false, errorMessage: '' })
  }

  const setEditingState = (isEditing: boolean, submissionId: number) => {
    setEditing({
      isEditing: isEditing,
      submissionId: submissionId,
    })
  }

  const fetchTruckDebtOverhaulSubmissionsAndVehicles = async () => {
    setLoading(true)
    const submissionData = await getTruckDebtOverhaulSubmissions()
    const truckData = await getDebtOverhaulTrucks()

    if (submissionData && truckData) {
      const submissions = submissionData.map((submission: TruckDebtOverhaulSubmission) => ({
        id: submission.submission_id,
        date_submitted: submission.time_created,
        number_of_trucks: submission.number_of_trucks,
        monthly_truck_payment: submission.monthly_truck_payment,
        current_credit_score: submission.current_credit_score,
        status: submission.status,
        vehicles: truckData.trucks
          .filter((truck: DebtOverhaulTruck) => truck.submission_id === submission.submission_id)
          .map((truck: DebtOverhaulTruck) => ({
            year: truck.year,
            fuelType: truck.fuel_type,
            vin: truck.VIN,
            model: truck.model,
            size: truck.size,
            miles: truck.miles,
            currentPayoff: truck.current_payoff,
            monthlyPayment: truck.monthly_payment,
            purchaseDate: truck.purchase_date,
            bank: truck.bank,
          })),
      }))

      // Create rows using the consolidated data
      const rows = submissions.map((submission: any) =>
        createData(
          submission.id,
          new Date(submission.date_submitted),
          submission.number_of_trucks,
          submission.monthly_truck_payment,
          submission.vehicles,
        ),
      )

      setRows(rows)
    } else {
      setError({ isError: true, errorMessage: `Error: Failed to fetch submissions.` })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchTruckDebtOverhaulSubmissionsAndVehicles()
  }, [newDebtOverhaulVisible])

  const closeNewDebtOverhaulDialog = () => {
    setNewDebtOverhaulVisible(false)
  }

  const openNewDebtOverhaulDialog = () => {
    setNewDebtOverhaulVisible(true)
  }

  return (
    <>
      {loading && !newDebtOverhaulVisible && <IsLoading />}
      <Box sx={{ width: '100%' }}>
        <NewDebtOverhaulDialog
          isOpen={newDebtOverhaulVisible}
          onClose={closeNewDebtOverhaulDialog}
          isEditing={editing.isEditing}
          submissionId={editing.submissionId}
          setEditing={setEditing}
        />

        {rows.length === 0 ? (
          <Grid container justifyContent='center' alignItems='center'>
            {/* <Grid>
            <Typography variant="h5" component="h2" gutterBottom>
              We have partnered with PandaDoc to provide you a secure and easy way to complete your financing process. 
            </Typography> 
          </Grid> */}

            <Grid container justifyContent='center' alignItems='center'>
              <Typography variant='h5' component='h2' gutterBottom>
                Click the button below to begin your first truck debt overhaul.
              </Typography>
            </Grid>

            <Grid container justifyContent='center' alignItems='center'>
              <Button onClick={openNewDebtOverhaulDialog} variant='contained' color='primary' sx={{ marginTop: 2 }}>
                Begin Truck Debt Overhaul
              </Button>
            </Grid>
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button onClick={openNewDebtOverhaulDialog} variant='contained' color='primary'>
                      File New Truck Debt Overhaul
                    </Button>
                  </TableCell>
                  <TableCell>Date Submitted</TableCell>
                  <TableCell align='right'>Number of Trucks</TableCell>
                  <TableCell align='right'>Monthly Truck Payment ($)</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    setEditing={setEditingState}
                    closeDebtOverhaulDialog={closeNewDebtOverhaulDialog}
                    openDebtOverhaulDialog={openNewDebtOverhaulDialog}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {error.isError && (
        <Collapse in={error.isError}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  resetErrorState()
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {error.errorMessage}
          </Alert>
        </Collapse>
      )}
    </>
  )
}

export default DebtOverhaul
