import { Box, Divider, MenuItem, Select, Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { PageTitle } from 'components/PageTitle'
import { SubmissionStatus } from 'core/types/form_submission'
import { useIsMobile } from 'hooks/screen/useIsMobile'

import React, { useState } from 'react'
import SubmissionBoard from './SubmissionBoard'
import ListIcon from '@mui/icons-material/List'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'

const statuses: SubmissionStatus[] = ['New', 'Completed', 'Canceled']

const SubmissionManagement = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [statusFilter, setStatusFilter] = useState<SubmissionStatus>('New')
  const isMobile = useIsMobile()
  const [view, setView] = React.useState<'card' | 'list'>('card')
  const userGuidelines = [
    'Use the tabs to filter types of submissions from the website.',
    "The 'Sell submissions' tab displays all submission from 'Sell a Truck'.",
    "The 'Buy submissions' tab displays all request for information when asking information for a truck or fleet.",
    "The 'Contact submissions' tab displays all submission from 'Contact Us'.",
    'When receiving a fleet request click on the picture to submit all vehicles to inventory',
  ]

  const submissionStatus = ['Sell submissions', 'Buy submissions', 'Contact submissions']
  const formCollections = ['form_sell', 'form_rfi', 'form_contact']

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleViewSwitch = (event: React.MouseEvent<HTMLElement>, newView: 'card' | 'list') => {
    setView(newView)
  }

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <PageTitle
          title='Submission Management'
          subtitle='Manage all submission for buying and selling Trucks'
          bulletPoints={userGuidelines}
        />
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <Box display='flex' alignItems='center' gap={2}>
        {!isMobile && (
            <ToggleButtonGroup value={view} exclusive onChange={handleViewSwitch} aria-label='text alignment'>
              <ToggleButton value='list' aria-label='list view' disabled={true}>
                <ListIcon />
              </ToggleButton>
              <ToggleButton value='card' aria-label='card view'>
                <SpaceDashboardIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <Select
            value={statusFilter || 'New'}
            onChange={e => setStatusFilter(e.target.value as SubmissionStatus)}
            fullWidth={isMobile}
            variant='outlined'
            size='small'
            style={{ backgroundColor: 'transparent', border: '1px solid #ccc' }}
          >
            {statuses.map(status => (
              <MenuItem key={status} value={status} style={{ fontSize: '0.875rem' }}>
                {status}
              </MenuItem>
            ))}
          </Select>
         
        </Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          allowScrollButtonsMobile
        >
          {submissionStatus.map(status => (
            <Tab key={`${status}-tab`} label={status} sx={{ textTransform: 'none' }} />
          ))}
        </Tabs>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <SubmissionBoard
        key={formCollections[tabIndex]}
        collectionName={formCollections[tabIndex]}
        onComplete={() => {}}
        statusFilter={statusFilter}
        view={view}
      />
    </>
  )
}

export default SubmissionManagement
