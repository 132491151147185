import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  Button,
  Stack,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { useAdminListBuilder } from 'hooks/useAdminListBuilder'
import { useInventory } from 'hooks/useInventory'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { useTheme } from '@mui/material/styles'
import { ValueFormatterParams, ICellRendererParams, RowClassParams } from 'ag-grid-community'
import { ShareDialog } from './ShareDialog'
import EmailIcon from '@mui/icons-material/Email'
import { useUsers } from 'hooks/useUsers'

const LoadingDialog = ({ open, message }: { open: boolean; message: string }) => (
  <Dialog 
    open={open} 
    PaperProps={{ 
      sx: { 
        backgroundColor: 'background.paper', 
        padding: 2 
      } 
    }}
  >
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <CircularProgress size={24} />
        <Typography>{message}</Typography>
      </Box>
    </DialogContent>
  </Dialog>
)

const DeleteListDialog = ({ 
  open, 
  list, 
  onClose, 
  onConfirm, 
  loading 
}: { 
  open: boolean
  list: InventoryList | null
  onClose: () => void
  onConfirm: () => Promise<void>
  loading: boolean
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ color: 'error.main' }}>Delete List</DialogTitle>
    <DialogContent>
      <Stack spacing={2}>
        <Typography variant="h6">
          Are you sure you want to delete "{list?.name}"?
        </Typography>
        <Typography color="text.secondary">
          This list contains {list?.inventoryIds.length} vehicles and is shared with {list?.associatedUserIds?.length || 0} users.
        </Typography>
        <Typography color="error" variant="body2">
          This action cannot be undone. The list will be permanently deleted for all users.
        </Typography>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
      <Button 
        onClick={onConfirm} 
        color="error" 
        variant="contained"
        disabled={loading}
      >
        {loading ? 'Deleting...' : 'Delete List'}
      </Button>
    </DialogActions>
  </Dialog>
)

const AdminListBuilder = () => {
  const { 
    lists, 
    loading, 
    deleteList,
    updateListAssociations 
  } = useAdminListBuilder()
  const { vehicles } = useInventory('master_inventory')
  const [expandedList, setExpandedList] = useState<string | null>(null)
  const theme = useTheme()
  const [selectedListForSharing, setSelectedListForSharing] = useState<InventoryList | null>(null)
  const { users } = useUsers()
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadMessage, setDownloadMessage] = useState('')
  const [listToDelete, setListToDelete] = useState<InventoryList | null>(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const handleAccordionChange = (listId: string) => {
    setExpandedList(expandedList === listId ? null : listId)
  }

  const columnDefs = useMemo<ColDef[]>(() => [
    { 
      field: 'stock_number', 
      headerName: 'Stock #', 
      width: 120,
      filter: false,
      cellStyle: {
        fontWeight: '500',
        color: theme.palette.primary.main,
      } as any,
    },
    { 
      field: 'year', 
      headerName: 'Year', 
      width: 90,
      filter: false,
    },
    { 
      field: 'make', 
      headerName: 'Make', 
      width: 120,
      filter: false,
    },
    { 
      field: 'model', 
      headerName: 'Model', 
      width: 120,
      filter: false,
    },
    { 
      field: 'type', 
      headerName: 'Type', 
      width: 120,
      filter: false,
    },
    { 
      field: 'miles', 
      headerName: 'Miles', 
      width: 120,
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => 
        params.value ? `${Number(params.value).toLocaleString()} mi` : '',
      cellStyle: { textAlign: 'right' } as any,
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 130,
      filter: false,
      cellRenderer: (params: ICellRendererParams) => (
        <Chip 
          label={params.value || 'N/A'} 
          size="small"
          color={
            params.value === 'Available' ? 'success' :
            params.value === 'Pending Sale' ? 'warning' :
            params.value === 'Sold' ? 'error' :
            'default'
          }
          sx={{ minWidth: 85, justifyContent: 'center' }}
        />
      )
    },
    { 
      field: 'optic_list_price', 
      headerName: 'List Price', 
      width: 120,
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => 
        params.value ? `$${Number(params.value).toLocaleString()}` : '',
      cellStyle: {
        textAlign: 'right',
        fontWeight: '500',
        color: theme.palette.success.main,
      } as any,
    },
  ], [theme])

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: false,
    suppressMovable: true,
    suppressMenu: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    }
  }), [])

  const getListVehicles = useCallback((list: InventoryList) => {
    if (!vehicles) return []
    return vehicles.filter(vehicle => list.inventoryIds.includes(vehicle.id))
  }, [vehicles])

  const getRowStyle = useCallback((params: RowClassParams) => {
    if (params.node.rowIndex === null) return {}
    
    return {
      backgroundColor: params.node.rowIndex % 2 === 0 ? 
        theme.palette.background.default : 
        'transparent'
    }
  }, [theme])

  const handleDownloadPdf = async (list: InventoryList) => {
    try {
      setDownloadMessage('Generating PDF...')
      setIsDownloading(true)
      await downloadShareableInventoryPdf('master_inventory', list.inventoryIds)
    } finally {
      setIsDownloading(false)
    }
  }

  const handleDeleteList = async () => {
    if (!listToDelete) return
    
    try {
      setDeleteLoading(true)
      await deleteList(listToDelete.id)
      setListToDelete(null)
    } finally {
      setDeleteLoading(false)
    }
  }

  if (loading || !lists) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ p: 3 }}>
      <LoadingDialog 
        open={isDownloading} 
        message={downloadMessage} 
      />

      <DeleteListDialog
        open={!!listToDelete}
        list={listToDelete}
        onClose={() => setListToDelete(null)}
        onConfirm={handleDeleteList}
        loading={deleteLoading}
      />

      <Grid container spacing={3}>
        {lists.map(list => (
          <Grid item xs={12} key={list.id}>
            <Card 
              elevation={1}
              sx={{
                '&:hover': {
                  boxShadow: 3,
                  transition: 'box-shadow 0.3s ease-in-out'
                }
              }}
            >
              <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {list.name}
                      {list.isArchived && (
                        <Chip 
                          label="Archived" 
                          size="small" 
                          color="default" 
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography color="text.secondary" variant="body2">
                        Created: {new Date(list.dateCreated).toLocaleDateString()}
                      </Typography>
                      <Typography color="text.secondary" variant="body2">
                        •
                      </Typography>
                      <Typography color="text.secondary" variant="body2">
                        Vehicles: {list.inventoryIds.length}
                      </Typography>
                    </Stack>
                    {list.description && (
                      <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
                        {list.description}
                      </Typography>
                    )}
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <Button
                      startIcon={<PictureAsPdfIcon />}
                      variant="outlined"
                      size="small"
                      onClick={() => handleDownloadPdf(list)}
                      disabled={isDownloading}
                      sx={{ 
                        borderRadius: 2,
                        textTransform: 'none',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                      }}
                    >
                      {isDownloading ? 'Generating...' : 'Download PDF'}
                    </Button>
                    <Button
                      startIcon={<PersonAddIcon />}
                      variant="outlined"
                      size="small"
                      onClick={() => setSelectedListForSharing(list)}
                      sx={{ 
                        borderRadius: 2,
                        textTransform: 'none',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                      }}
                    >
                      Share
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => setListToDelete(list)}
                      sx={{ 
                        borderRadius: 2,
                        textTransform: 'none',
                        '&:hover': { backgroundColor: 'rgba(211, 47, 47, 0.04)' }
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EmailIcon fontSize="small" color="action" />
                    <Typography variant="body2" color="text.secondary">
                      Shared with:
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {list.associatedUserIds?.length ? (
                      list.associatedUserIds.map(userId => {
                        const user = users?.find(u => u.id === userId)
                        return user ? (
                          <Chip
                            key={userId}
                            label={user.email}
                            size="small"
                            variant="outlined"
                            sx={{ 
                              maxWidth: 200,
                              '.MuiChip-label': {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                              }
                            }}
                          />
                        ) : null
                      })
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No users
                      </Typography>
                    )}
                  </Box>
                </Stack>

                {selectedListForSharing && (
                  <ShareDialog
                    open={!!selectedListForSharing}
                    onClose={() => setSelectedListForSharing(null)}
                    list={selectedListForSharing}
                    users={users || []}
                    onShare={async (userIds: string[]) => {
                      if (!selectedListForSharing) return
                      await updateListAssociations({
                        listId: selectedListForSharing.id,
                        userIds
                      })
                      setSelectedListForSharing(null)
                    }}
                  />
                )}

                <Accordion
                  expanded={expandedList === list.id}
                  onChange={() => handleAccordionChange(list.id)}
                  elevation={0}
                  sx={{
                    '&:before': { display: 'none' },
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                  }}
                >
                  <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      borderRadius: 1,
                    }}
                  >
                    <Typography>View Vehicles</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <div style={{ height: 'auto', width: '100%', maxHeight: 480 }}>
                      <AgGridReact
                        rowData={getListVehicles(list)}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowClickSelection={true}
                        suppressCellFocus={true}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        enableRangeSelection={false}
                        enableCellTextSelection={false}
                        suppressPaginationPanel={false}
                        rowStyle={{ 
                          cursor: 'default',
                        }}
                        headerHeight={48}
                        rowHeight={44}
                        suppressLoadingOverlay={true}
                        suppressNoRowsOverlay={true}
                        overlayNoRowsTemplate="No vehicles in this list"
                        className={theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {lists.length === 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography align="center" color="textSecondary">
                  No lists have been created yet.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default AdminListBuilder 