import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, GridApi } from 'ag-grid-community'
import { useTheme } from '@mui/material'
import { useInventory } from 'hooks/useInventory'
import { useAuth } from 'contexts/AuthContext'
import { VehiclePhotosRenderer } from './VehiclePhotosRenderer'
import { getInventoryColumns } from './getInventoryColumns'
import { LoadingInventory } from '../LoadingInventory'
import { Box } from '@mui/material'
import { Button, Divider, Typography, Stack, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import ShareIcon from '@mui/icons-material/Share'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

import { ActionsPanel } from './ActionsPanel'

type GridTheme = {
  name: string
  rowHeight: number
  fontSize: string
}

const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  flex: 1,
  minWidth: 100,
  editable: true,
  singleClickEdit: true,
}

const masterDetailOptions = {
  masterDetail: true,
  detailRowHeight: 600,
  detailRowAutoHeight: true,
  suppressMasterDetailRefit: false,
  isRowMaster: () => true,
}

interface AgGridInventoryProps {
  gridRef: React.RefObject<AgGridReact>
  gridTheme: { name: string; rowHeight: number; fontSize: string }
  rows: Vehicle[]
  isLoading?: boolean
}

// Add these constants at the top of the file, before the component
const ZOOM_LIMITS = {
  FONT: {
    MIN: 8, // Smaller minimum font size
    MAX: 20,
    DEFAULT: 14
  },
  ROW: {
    MIN: 24, // Smaller minimum row height
    MAX: 100,
    DEFAULT: 24
  }
} as const

const AgGridInventory: React.FC<AgGridInventoryProps> = ({ gridRef, gridTheme, rows, isLoading = false }) => {
  const { userInfo } = useAuth()
  const theme = useTheme()
  const gridApiRef = useRef<GridApi | null>(null)

  const { handleCellValueChange } = useInventory('master_inventory')

  const [selectedRows, setSelectedRows] = useState<Vehicle[]>([])

  const [activeToolPanel, setActiveToolPanel] = useState<string | undefined>(undefined)

  const [expandedRows, setExpandedRows] = useState<string[]>([])

  // Update the initial state to use the default values
  const [fontSize, setFontSize] = useState(`${ZOOM_LIMITS.FONT.DEFAULT}px`)
  const [rowHeight, setRowHeight] = useState<number>(ZOOM_LIMITS.ROW.DEFAULT)

  // Move clearAllFilters up here, before it's used in sideBar
  const clearAllFilters = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null)
    }
  }, [])

  const onSelectionChanged = useCallback(() => {
    const selected = gridApiRef.current?.getSelectedRows() || []
    setSelectedRows(selected)

    if (gridApiRef.current) {
      if (selected.length > 0 && activeToolPanel !== 'actions') {
        gridApiRef.current.openToolPanel('actions')
        setActiveToolPanel('actions')
      } else if (selected.length === 0 && activeToolPanel === 'actions') {
        gridApiRef.current.closeToolPanel()
        setActiveToolPanel(undefined)
      }
    }
  }, [activeToolPanel])

  // Now sideBar can use clearAllFilters
  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'actions',
          labelDefault: 'Actions',
          labelKey: 'actions',
          iconKey: 'menu',
          toolPanel: () => <ActionsPanel selectedRows={selectedRows} clearAllFilters={clearAllFilters} />,
        },
      ],
    }),
    [selectedRows, clearAllFilters],
  )

  const onCellClicked = useCallback((params: any) => {
    if (params.column.getColId() === 'stock_number') return

    if (params.column?.getColDef().editable !== false) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex!,
        colKey: params.column.getColId(),
      })

      // if (!params.node.isSelected()) {
      //   params.node.setSelected(true)
      // }
    }
  }, [])

  const handleCellValueChanged = useCallback(
    (params: any) => {
      const { data, colDef, newValue } = params
      handleCellValueChange(data.id, colDef.field!, newValue)

      // Only auto-size columns when data changes
      if (gridApiRef.current) {
        const allColumnIds: string[] = []
        gridApiRef.current.getColumns()?.forEach(column => {
          allColumnIds.push(column.getId())
        })
        gridApiRef.current.autoSizeColumns(allColumnIds)
      }
    },
    [handleCellValueChange],
  )

  const isAdmin = userInfo?.user_type === 'admin'
  const columnDefs = useMemo(() => {
    const checkboxColumn: ColDef = {
      headerName: '',
      field: 'checkbox',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      pinned: 'left',
      lockPosition: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: false,
      filter: false,
      floatingFilter: false,
      suppressFiltersToolPanel: true,
    }

    const inventoryColumns = getInventoryColumns(isAdmin)
    return [checkboxColumn, ...inventoryColumns]
  }, [isAdmin])
  const memoizedVehiclePhotosRenderer = useMemo(() => VehiclePhotosRenderer, [])

  // Add a loading row renderer
  const loadingRenderer = () => {
    return (
      <Box sx={{ height: gridTheme.rowHeight, position: 'relative' }}>
        <LoadingInventory />
      </Box>
    )
  }

  // Update the zoom effect to use the new limits
  useEffect(() => {
    const handleZoom = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
        
        const delta = e.deltaY > 0 ? -1 : 1
        const zoomFactor = 1 + (delta * 0.05)

        setFontSize(prev => {
          const currentSize = parseFloat(prev)
          const newSize = Math.max(
            ZOOM_LIMITS.FONT.MIN,
            Math.min(ZOOM_LIMITS.FONT.MAX, currentSize * zoomFactor)
          )
          return `${newSize}px`
        })

        setRowHeight(prev => {
          return Math.max(
            ZOOM_LIMITS.ROW.MIN,
            Math.min(ZOOM_LIMITS.ROW.MAX, prev * zoomFactor)
          )
        })

        if (gridApiRef.current) {
          gridApiRef.current.resetRowHeights()
        }
      }
    }

    const preventKeyboardZoom = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '=')) {
        e.preventDefault()
        
        const delta = (e.key === '-') ? -1 : 1
        const zoomFactor = 1 + (delta * 0.1)

        setFontSize(prev => {
          const currentSize = parseFloat(prev)
          const newSize = Math.max(
            ZOOM_LIMITS.FONT.MIN,
            Math.min(ZOOM_LIMITS.FONT.MAX, currentSize * zoomFactor)
          )
          return `${newSize}px`
        })

        setRowHeight(prev => {
          return Math.max(
            ZOOM_LIMITS.ROW.MIN,
            Math.min(ZOOM_LIMITS.ROW.MAX, prev * zoomFactor)
          )
        })

        if (gridApiRef.current) {
          gridApiRef.current.resetRowHeights()
        }
      }
    }

    document.addEventListener('wheel', handleZoom, { passive: false })
    document.addEventListener('keydown', preventKeyboardZoom)

    return () => {
      document.removeEventListener('wheel', handleZoom)
      document.removeEventListener('keydown', preventKeyboardZoom)
    }
  }, [])

  // Add this callback function
  const handleFirstDataRendered = useCallback(() => {
    if (gridApiRef.current) {
      const allColumnIds: string[] = []
      gridApiRef.current.getColumns()?.forEach(column => {
        allColumnIds.push(column.getId())
      })
      gridApiRef.current.autoSizeColumns(allColumnIds)
    }
  }, [])

  useEffect(() => {
    const handleClearFilters = () => clearAllFilters()
    document.addEventListener('clearAllFilters', handleClearFilters)
    
    return () => {
      document.removeEventListener('clearAllFilters', handleClearFilters)
    }
  }, [clearAllFilters])

  // Now define gridConfig after clearAllFilters is declared
  const gridConfig = useMemo(
    () => ({
      columnDefs,
      rowData: isLoading ? [] : rows,
      defaultColDef,
      ...masterDetailOptions,
      detailCellRenderer: memoizedVehiclePhotosRenderer,
      rowSelection: 'multiple' as const,
      sideBar: {
        ...sideBar,
        defaultToolPanel: activeToolPanel,
      },
      onCellClicked,
      onCellValueChanged: handleCellValueChanged,
      suppressRowClickSelection: true,
      suppressColumnVirtualisation: true,
      colResizeDefault: 'shift' as const,
      domLayout: 'normal' as const,
      rowHeight: rowHeight,
      rowStyle: { fontSize: fontSize },
      groupDisplayType: 'groupRows' as const,
      animateRows: true,
      rowGroupPanelShow: 'always' as const,
      suppressAggFuncInHeader: true,

      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' },
        ],
      },
      onGridReady: (params: GridReadyEvent) => {
        gridApiRef.current = params.api
        params.api.sizeColumnsToFit()
        
        if (gridApiRef.current) {
          const allColumnIds: string[] = []
          gridApiRef.current.getColumns()?.forEach(column => {
            allColumnIds.push(column.getId())
          })
          gridApiRef.current.autoSizeColumns(allColumnIds)
        }
      },
      // suppressRowClickSelection: true,
      onSelectionChanged,
      onToolPanelVisibleChanged: (event: any) => {
        if (event.source === 'toolPanelUi') {
          setActiveToolPanel(event.toolPanel.id)
        }
      },
      onRowExpanded: (event: any) => {
        setExpandedRows(prev => [...prev, event.data.id])
      },
      onRowCollapsed: (event: any) => {
        setExpandedRows(prev => prev.filter(id => id !== event.data.id))
      },
      isRowExpanded: (row: any) => {
        return expandedRows.includes(row.data.id)
      },
      onFirstDataRendered: handleFirstDataRendered,
    }),
    [
      columnDefs,
      rows,
      isLoading,
      sideBar,
      onCellClicked,
      handleCellValueChanged,
      memoizedVehiclePhotosRenderer,
      fontSize,
      rowHeight,
      loadingRenderer,
      onSelectionChanged,
      activeToolPanel,
      expandedRows,
      handleFirstDataRendered,
      clearAllFilters,
    ],
  )

  return (
    <div
      className={theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
      style={{ width: '100%', height: '80vh' }}
    >
      <AgGridReact ref={gridRef} {...gridConfig} />
    </div>
  )
}

export default React.memo(AgGridInventory)
