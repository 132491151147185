import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material'

interface AddLinkDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (link: string) => void
  currentLink?: string
}

const AddLinkDialog: React.FC<AddLinkDialogProps> = ({ open, onClose, onSubmit, currentLink }) => {
  const [link, setLink] = useState(currentLink || '')
  const [error, setError] = useState('')

  const handleSubmit = () => {
    // Basic URL validation
    try {
      new URL(link)
      setError('')
      onSubmit(link)
      onClose()
    } catch {
      setError('Please enter a valid URL (include http:// or https://)')
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Vehicle Link</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            autoFocus
            fullWidth
            label="Vehicle Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            error={!!error}
            helperText={error}
            placeholder="https://example.com/vehicle"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddLinkDialog 