import { db } from '../../config/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'

interface VehicleInfo {
  id: string
  vin: string
  make?: string
  model?: string
  year?: string
}

interface SetAccessCodeParams {
  accessCode: string
  userId?: string
  vehicles: VehicleInfo | VehicleInfo[]
  preDelivery?: boolean
  postDelivery?: boolean
}

export const setAccessCode = async ({ 
  accessCode, 
  userId,
  vehicles,
  preDelivery = false,
  postDelivery = false
}: SetAccessCodeParams): Promise<string> => {
  const uuid = uuidv4()
  const accessCodeDoc = doc(db, 'accessCodes', uuid)
  
  // Convert single vehicle to array if necessary
  const vehicleArray = Array.isArray(vehicles) ? vehicles : [vehicles]
  
  await setDoc(accessCodeDoc, {
    code: accessCode,
    userId,
    vehicles: vehicleArray.map(v => ({
      id: v.id,
      vin: v.vin || '',
      ...(v.make && { make: v.make }),
      ...(v.model && { model: v.model }),
      ...(v.year && { year: v.year })
    })),
    preDelivery,
    postDelivery,
    createdAt: new Date().toISOString(),
    expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // 7 days from now
  })
  return uuid
}