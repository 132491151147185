import { API_URL } from 'core/config';
import { getHeaders } from 'core/api/getHeaders';
import { doc, getDoc, getFirestore } from '@firebase/firestore';

const db = getFirestore();

export const updateUserBank = async (firebaseUserId: string, bankId: string) => {
  const userRef = doc(db, `users/${firebaseUserId}`);
  const docSnap = await getDoc(userRef)
  const snapshot = docSnap.data();
  console.log("Snapshot", snapshot)
  const userId = snapshot?.id;

    if (!userId) {
        console.error("Could not find user with id: ", firebaseUserId);
        return undefined;
    }
    
  const data = {
    user_id: userId,
    bank_id: bankId,
  };

  try {
    const response = await fetch(`${API_URL}/admin/change-user-bank`, {
      method: 'POST',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response) {
      throw new Error(`HTTP error!`);
    }

    const res = await response.json();
    if (res.detail !== "User bank updated successfully") {
      throw new Error(`HTTP error!`);
    }
    return res;
  } catch (error) {
    console.error('There was an error!', error);
    return null;
  }
};
