import { db } from 'core/config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

interface InventoryItem {
  vin: string;
  stock_number: string;
  make: string;
  model: string;
  size: string;
  fuel: string;
  miles: number;
  year: number;
  condition: string;
  location: string;
  who: string;
  type: string;
  status: string;
  optic_list_price?: string;
  seller_asking_price?: string;
  url?: string;
  show_on_website?: boolean;
  sold_date?: string;
  profit?: string;
}

const convertToCSV = (items: InventoryItem[], uType: string): string => {
  const headers = [
    'VIN', 'Stock Number', 'Make', 'Model', 'Size', 'Fuel', 'Mileage', 'Year',
    'Condition', 'Location', 'Who', 'Type', 'Status', 'Sale Price',
    'URL', 'Show On Website', 'Sold Date'
  ];

  // Add "Buy Price" and "Profit" headers only for non-babyAdmins
  if (uType !== "babyAdmin") {
    headers.splice(13, 0, 'Buy Price');
    headers.splice(15, 0, 'Profit');
  }

  const escapeCommas = (value: string | number | undefined) => {
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value}"`;
    }
    return value;
  };

  const rows = items.map(item => {
    const row = [
      escapeCommas(item.vin), escapeCommas(item.stock_number), escapeCommas(item.make), escapeCommas(item.model),
      escapeCommas(item.size), escapeCommas(item.fuel), escapeCommas(item.miles), escapeCommas(item.year),
      escapeCommas(item.condition), escapeCommas(item.location), escapeCommas(item.who), escapeCommas(item.type),
      escapeCommas(item.status), escapeCommas(item.optic_list_price),
      escapeCommas(item.url), item.show_on_website ? 'Yes' : 'No', escapeCommas(item.sold_date)
    ];

    // Add "Buy Price" and "Profit" values only for non-babyAdmins
    if (uType !== "babyAdmin") {
      row.splice(13, 0, escapeCommas(item.seller_asking_price));
      row.splice(15, 0, escapeCommas(item.profit));
    }

    return row;
  });

  const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');
  return csvContent;
};

const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadShareableInventoryCsv = async (collectionName: string, ids: string[], uType: string) => {
  const inventoryCollection = collection(db, collectionName);
  const batchSize = 30;
  const inventoryItems: InventoryItem[] = [];

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const inventoryQuery = query(inventoryCollection, where('__name__', 'in', batchIds));
    const querySnapshot = await getDocs(inventoryQuery);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const item: InventoryItem = {
        vin: data.vin || '',
        stock_number: data.stock_number || '',
        make: data.make || '',
        model: data.model || '',
        size: data.size || '',
        fuel: data.fuel || '',
        miles: data.miles || '',
        year: data.year || '',
        condition: data.condition || '',
        location: data.location || '',
        who: data.who || '',
        type: data.type || '',
        status: data.status || '',
        seller_asking_price: data.seller_asking_price || '',
        optic_list_price: data.optic_list_price || '',
        profit: data.profit || '',
        url: data.url || '',
        sold_date: data.sold_date || '',
        show_on_website: data.show_on_website || '',
      };
      inventoryItems.push(item);
    });
  }

  const csvContent = convertToCSV(inventoryItems, uType);
  downloadCSV(csvContent, 'inventory_data.csv');

};
