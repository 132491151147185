import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

interface NoteDialogProps {
  open: boolean;
  note: string;
  onClose: () => void;
  onSave: (newNote: string) => void;
}

const NoteDialog: React.FC<NoteDialogProps> = ({ open, note, onClose, onSave }) => {
  const [currentNote, setCurrentNote] = React.useState(note);

  useEffect(() => {
    setCurrentNote(note);
  }, [note]);

  const handleSave = () => {
    onSave(currentNote);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Notes</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Notes"
          type="text"
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          value={currentNote}
          onChange={(e) => setCurrentNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoteDialog; 