import React, { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { Box, Typography } from '@mui/material'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

interface FirestoreDocViewerProps {
  collectionName: string
  docId: string
}

const FirestoreDocViewer: React.FC<FirestoreDocViewerProps> = ({ collectionName, docId }) => {
  const [documentData, setDocumentData] = useState<unknown>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true)
      setError(null)
      try {
        const docRef = doc(db, collectionName, docId)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setDocumentData(docSnap.data())
        } else {
          setError('Document does not exist.')
        }
      } catch (err) {
        console.error('Error fetching document:', err)
        setError('Failed to fetch document.')
      } finally {
        setLoading(false)
      }
    }

    fetchDocument()
  }, [collectionName, docId])

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  if (error) {
    return <Typography color='error'>{error}</Typography>
  }

  if (!documentData) {
    return <Typography>No data available.</Typography>
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: '#2d2d2d', borderRadius: 1 }}>
      <SyntaxHighlighter language='json' style={materialDark}>
        {JSON.stringify(documentData, null, 2)}
      </SyntaxHighlighter>
    </Box>
  )
}

export default FirestoreDocViewer
