import React from 'react'
import { DashboardLayout } from '@toolpad/core'
import { Box } from '@mui/material'
import Footer from 'components/Footer'

export const CoreLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const { isOpen, toggleSidebar } = useSidebarOpen()

  return (
    <DashboardLayout
      // defaultSidebarOpen={false}
      // defaultSidebarCollapsed={true}
      // hideNavigation={true}
      defaultSidebarCollapsed
      slotProps={{
        toolbarAccount: {
          slotProps: {
            signInButton: {
              sx: { display: 'none' }, // Hide the sign-in button
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '70vh',
          width: '100%',
          overflow: 'hidden', // Prevent horizontal scrolling at layout level
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',
            padding: 2,
            boxSizing: 'border-box',
            overflow: 'hidden', // Prevent horizontal dscrolling at content level
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </DashboardLayout>
  )
}

export default CoreLayout
