import React, { useState } from 'react'

import { Box, Container, Avatar, Typography } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { useAuth } from 'contexts/AuthContext'

import ProfileInfoDialog from './components/ProfileInfoDialog'

const Profile = (): JSX.Element => {
  const [profileDialogVisible, setProfileDialog] = useState(false)

  const { userInfo } = useAuth()
  const userData = {
    name: userInfo?.first_name + ' ' + userInfo?.last_name,
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    email: userInfo?.email,
    company: userInfo?.company_name,
  }

  const onDialogClose = () => {
    setProfileDialog(false)
  }

  // const openDialog = () => {
  //     setProfileDialog(true);
  // }

  return (
    <>
      <Container component='main' maxWidth='md'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfileInfoDialog isOpen={profileDialogVisible} onClose={onDialogClose} companyInfo={null} />

          <Avatar sx={{ m: 1, bgcolor: deepPurple[500], width: 56, height: 56 }}>
            {userData.first_name?.charAt(0)}
            {userData.last_name?.charAt(0)}
          </Avatar>
          <Typography component='h1' variant='h5'>
            {userData.name}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {userData.email}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {userData.company}
          </Typography>
          {/* COMMENTED OUT, NOT FINISHED WITH PROFILE UPDATE */}
          {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Typography variant="subtitle1">Company Name: <Typography variant="body2" component="span">{userData.company}</Typography></Typography>
                        <Typography variant="subtitle1">Location: <Typography variant="body2" component="span">{"Virginia"}</Typography></Typography>
                        <Typography variant="subtitle1">Years in Business: <Typography variant="body2" component="span">{7}</Typography></Typography>
                        <Typography variant="subtitle1">Phone Number: <Typography variant="body2" component="span">{"111-222-3333"}</Typography></Typography>
                        <Typography variant="subtitle1">Email: <Typography variant="body2" component="span">{userData.email}</Typography></Typography>
                    </Box>
                    <Button onClick={openDialog}>
                        Edit Profile Info
                    </Button> */}
        </Box>

        {/* <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item>
                        <Button variant="contained" color="primary">
                            Edit Profile
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined">
                            Change Password
                        </Button>
                    </Grid>
                </Grid> */}
      </Container>
    </>
  )
}

export default Profile
