import React, { createContext, useContext, useEffect } from 'react';
import { useSyncExternalStore } from 'react';
import { useAuth } from './AuthContext';
import { adminFunctions } from 'core/api/application/admin';
import { AdminApplication, Application } from 'core/types/applications';

type AdminProviderProps = {
  children: React.ReactNode;
};

type AdminContextType = {
  applications: AdminApplication[];
  refetchApplications: () => Promise<void>;
  banks: Bank[];
  refetchBanks: () => Promise<void>;
  statusCounts: {
    ready: number;
    correctionsNeeded: number;
    approved: number;
    denied: number;
    viewed: number;
    pending: number;
  };
};

const createStore = <T,>(initialState: T) => {
  let state = initialState;
  const subscribers = new Set<() => void>();

  const getSnapshot = () => state;
  const subscribe = (callback: () => void) => {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  };
  const setState = (newState: T) => {
    state = newState;
    subscribers.forEach(callback => callback());
  };

  return { getSnapshot, subscribe, setState };
};


const applicationsStore = createStore<AdminApplication[]>([])
const banksStore = createStore<Bank[]>([])

export const AdminContext = createContext<AdminContextType | null>(null);

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
  const { userInfo } = useAuth();
  const applications = useSyncExternalStore(applicationsStore.subscribe, applicationsStore.getSnapshot);
  const banks = useSyncExternalStore(banksStore.subscribe, banksStore.getSnapshot);


  const refetchApplications = async () => {
    try {
      const newApplications = await adminFunctions.getApplications();
      applicationsStore.setState(newApplications);
    } catch (error) {
      console.error('Error fetching applications:', error);
    }
  };

  const refetchBanks = async () => {
    try {
      const newBanks = await adminFunctions.getBanks();
      banksStore.setState(newBanks);
    } catch (error) {
      console.error('Error fetching banks:', error);
    }
  };

  const calculateStatusCounts = (applications: Application[]) => {
    const statusCounts = {
      ready: 0,
      correctionsNeeded: 0,
      approved: 0,
      denied: 0,
      viewed: 0,
      pending: 0,
    };
    applications.forEach((application) => {
      switch (application.status) {
        case 'Ready':
          statusCounts.ready += 1;
          break;
        case 'Corrections Needed':
          statusCounts.correctionsNeeded += 1;
          break;
        case 'Approved':
          statusCounts.approved += 1;
          break;
        case 'Denied':
          statusCounts.denied += 1;
          break;
        case 'Viewed':
          statusCounts.viewed += 1;
          break;
        case 'Pending':
          statusCounts.pending += 1;
          break;
        default:
          break;
      }
    });

    return statusCounts;
  };

  const statusCounts = calculateStatusCounts(applications);

  useEffect(() => {
    if (userInfo && userInfo.user_type === 'admin' || userInfo?.user_type === 'babyAdmin') {
      refetchApplications();
      refetchBanks();
    }
  }, [userInfo]);

  const contextValue = {
    applications,
    refetchApplications,
    banks,
    refetchBanks,
    statusCounts,
  };

  return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }
  return context;
};
