import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { readInventoryFromXLSX } from 'core/api/inventory/handleInventoryCsvUpload'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import TruckIcon from '@mui/icons-material/LocalShipping'
import SpeedIcon from '@mui/icons-material/Speed'
import { useAuth } from 'contexts/AuthContext'
import { generateOpticStockNumber, generateShieldStockNumber } from 'core/api/inventory/generateStockNumber'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { addVinData } from 'views/SuperAdmin/Tools/vehicleUtils'

interface FleetOwner {
  name: string
  email: string
  phone: string
}

interface FleetListDialogProps {
  open: boolean
  onClose: () => void
  vehicles?: Vehicle[]
  file?: string
  fleetOwner: FleetOwner
  onSubmit: () => void
}
const FleetListDialog: React.FC<FleetListDialogProps> = ({ open, onClose, vehicles: initialVehicles, file, fleetOwner, onSubmit }) => {

  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([])
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [openCancelWindow, setOpenCancelWindow] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()

  const { data: submissionVehicles, isLoading } = useQuery(
    ['submission-vehicles', file],
    async () => {
      if (!file) return null
      const vehicles = await readInventoryFromXLSX(file)
      const validVehicles = vehicles.filter(vehicle => vehicle.vin.length === 17)
      const updatedVehicles = await Promise.all(validVehicles.map(addVinData))
      return updatedVehicles as Vehicle[]
    },
    {
      staleTime: Infinity,
      enabled: open && !!file,
    },
  )

  useEffect(() => {
    if (initialVehicles) {
      setVehicles(initialVehicles)
    } else if (submissionVehicles) {
      setVehicles(submissionVehicles)
    }
  }, [initialVehicles, submissionVehicles])

  const { data: opticVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'master_inventory',
  })

  const { data: shieldVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'shield_inventory',
  })

  const { mutate: addFirebaseDocumentToOpticInventory } = useAddFirestoreDocument({
    collectionName: 'master_inventory',
  })
  const { mutate: addFirebaseDocumentToShieldInventory } = useAddFirestoreDocument({
    collectionName: 'shield_inventory',
  })

  const addVehiclesToInventory = (newVehicles: Vehicle[], kind: 'optic' | 'shield') => {
    const existingOpticStockNumbers = (opticVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []
    const existingShieldStockNumbers = (shieldVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []
    let newStockNumber: string;
    newVehicles.forEach(vehicle => {
      if (kind === 'optic') {
        if ((opticVehicles as Vehicle[]).map(v => v.vin).includes(vehicle.vin)) return
        newStockNumber = generateOpticStockNumber(
          existingOpticStockNumbers,
          userInfo?.first_name || '',
          userInfo?.last_name || '',
        )
        const newVehicle = {
          ...vehicle,
          who: fleetOwner.name,
          phone_number: fleetOwner.phone,
          email: fleetOwner.email,
          stock_number: newStockNumber,
        }
        existingOpticStockNumbers.push(newStockNumber)
        addFirebaseDocumentToOpticInventory(newVehicle)
      } else {
        if ((shieldVehicles as Vehicle[]).map(v => v.vin).includes(vehicle.vin)) return
        newStockNumber = generateShieldStockNumber(
          existingShieldStockNumbers,
        )
        const newVehicle = {
          ...vehicle,
          who: fleetOwner.name,
          phone_number: fleetOwner.phone,
          email: fleetOwner.email,
          stock_number: newStockNumber,
        }
        existingShieldStockNumbers.push(newStockNumber)
        addFirebaseDocumentToShieldInventory(newVehicle)
      }
    })
    showSnackbar(`Vehicles (${newVehicles.length}) added to ${kind} inventory`, 'success')
    setVehicles(prevVehicles => prevVehicles.filter(prevVehicle => !newVehicles.includes(prevVehicle)))
    setSelectedVehicles([])
    if (newVehicles.length === vehicles.length) {
      onSubmit()
    }
  }

  const handleVehicleSelect = (vehicle: Vehicle) => {
    const index = selectedVehicles.findIndex(v => v.vin === vehicle.vin)
    if (index > -1) {
      setSelectedVehicles(selectedVehicles.filter(v => v.vin !== vehicle.vin))
    } else {
      setSelectedVehicles([...selectedVehicles, vehicle])
    }
  }

  return (
    <>
      <Dialog 
        fullScreen={true} 
        open={open} 
        onClose={onClose} 
        style={{ zIndex: 1000 }}
        PaperProps={{
          sx: {
            marginLeft: '300px', // Adjust this value to match your sidebar width
            width: 'calc(100% - 300px)', // Adjust the width to account for sidebar
          }
        }}
      >
        <DialogTitle sx={{ fontSize: '1.5rem' }}>
          Submit Vehicles to inventory
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant='determinate' value={(selectedVehicles.length / vehicles.length) * 100} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant='body2'
                color='text.secondary'
              >{`${selectedVehicles.length}/${vehicles.length}`}</Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {file && isLoading ? (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight={200}>
              <CircularProgress />
            </Box>
          ) : vehicles.length ? (
            <>
              <Toolbar>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedVehicles.length === vehicles.length}
                      onChange={event => {
                        if (event.target.checked) {
                          setSelectedVehicles(vehicles)
                        } else {
                          setSelectedVehicles([])
                        }
                      }}
                    />
                  }
                  label='Select All'
                />
              </Toolbar>

              <List>
                {vehicles
                  .sort((a, b) => parseInt(a.miles) - parseInt(b.miles))
                  .map(vehicle => (
                    <ListItem
                      key={vehicle.vin}
                      onClick={() => handleVehicleSelect(vehicle)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: 'action.hover' },
                        padding: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                      }}
                    >
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                          <Checkbox checked={selectedVehicles.some(v => v.vin === vehicle.vin)} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant='h6' gutterBottom>
                            <TruckIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body2' color='text.secondary'>
                                VIN: {vehicle.vin}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body2' color='text.secondary'>
                                <SpeedIcon sx={{ verticalAlign: 'middle', marginRight: 1, fontSize: '0.9rem' }} />
                                Mileage: {vehicle.miles.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} mi
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={selectedVehicles.some(v => v.vin === vehicle.vin) ? 'Selected' : 'Select'}
                            color={selectedVehicles.some(v => v.vin === vehicle.vin) ? 'primary' : 'default'}
                            size='small'
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            </>
          ) : (
            <Typography variant='body1' align='center' fontSize='1rem'>
              No vehicles submitted
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={() => {
              if (vehicles.length > 0) {
                setOpenCancelWindow(true)
              } else {
                onClose()
              }
            }}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button onClick={() => addVehiclesToInventory(selectedVehicles, 'shield')} sx={{ textTransform: 'none' }}>
            Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Shield Inventory
          </Button>
          <Button onClick={() => addVehiclesToInventory(selectedVehicles, 'optic')} sx={{ textTransform: 'none' }}>
            Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Optic Inventory
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelWindow}
        keepMounted
        onClose={() => setOpenCancelWindow(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ zIndex: 3101 }}
        PaperProps={{
          sx: {
            marginLeft: '120px', // Center the cancel dialog in the remaining space
          }
        }}
      >
        <DialogTitle id='alert-dialog-title'>{'Cancel submission to inventory'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {vehicles.length === 0 ? (
              'No vehicles to submit. Closing...'
            ) : (
              <>
                You still have to submit {vehicles.length} trucks to inventory.
                <br />
                Are you sure you want to cancel?
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button onClick={() => setOpenCancelWindow(false)} color='primary' autoFocus>
            No
          </Button>
          <Button
            onClick={() => {
              setOpenCancelWindow(false)
              onClose()
            }}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FleetListDialog
