import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { NotFound, IsLoading, AccountDeactivated, NeedVerification } from 'components'
import { useAuth } from 'contexts/AuthContext'
import { Login, Signup, ForgotPassword, ResetPassword } from 'views/Auth'
import { Profile, SupportRequest } from 'views/Shared'
import { getSuperUserPages, clientPages, dealerPages, bankPages } from 'router/pages'
import { LayoutProvider } from 'router/layouts'
import {
  AuthRoute,
  SuperUserRoute,
  DealerRoute,
  ClientRoute,
  BankRoute,
  ProtectedRoute,
} from './routes'
import { mapToolpadToReactRouter } from 'router/layouts/mapToolpadToReactRouter'
import { AuthLayout } from './layouts'
import FleetListPage from 'views/SuperAdmin/Management/SubmissionManagement/components/FleetListPage'

export const AppRoutes = () => {
  const { isLoading, userInfo, isVerified } = useAuth()

  // if (isLoading) {
  //   return <IsLoading />
  // }


  const superuserPages = getSuperUserPages(userInfo?.user_type || '')

  if (userInfo?.is_active === false) {
    return <AccountDeactivated />
  }

  if (!isVerified() && userInfo) {
    return <NeedVerification />
  }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path='/login'
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path='/signup'
          element={
            <AuthRoute>
              <Signup />
            </AuthRoute>
          }
        />
        <Route
          path='/forgot-password'
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />
        <Route
          path='/__/auth/action'
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />
      </>
    )
  }

  const RedirectRoutes = () => {
    return (
      <>
        {/* Not Found - other pages redirect here */}
        <Route path='/not-found' element={<NotFound />} />

        {/* Redirect logic to /login or /user_type occurs in AuthLayout */}
        <Route path='/' />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route path='*' element={<Navigate to='/not-found' replace />} />
      </>
    )
  }

  const SharedRoutes = () => {
    return (
      <>
        <Route
          path='/profile'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path='/support-request'
          element={
            <ProtectedRoute>
              <SupportRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path='/not-found'
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />
        <Route
          path='/account-deactivated'
          element={
            <ProtectedRoute>
              <AccountDeactivated />
            </ProtectedRoute>
          }
        />
      </>
    )
  }

  const SuperAdminRoutes = () => {
    return (
      <>
        <Route
          path="/fleet-list"
          element={
            <SuperUserRoute>
              <FleetListPage />
            </SuperUserRoute>
          }
        />
      </>
    )
  }

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {AuthRoutes()} {RedirectRoutes()}
        </Route>

        <Route element={<LayoutProvider />}>
          {SharedRoutes()}
          {SuperAdminRoutes()}
          {mapToolpadToReactRouter(superuserPages, SuperUserRoute)}
          {mapToolpadToReactRouter(clientPages, ClientRoute)}
          {mapToolpadToReactRouter(dealerPages, DealerRoute)}
          {mapToolpadToReactRouter(bankPages, BankRoute)}
        </Route>
      </Routes>
    </Router>
  )
}
