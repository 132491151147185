import { AdminProvider } from 'contexts/AdminContext'
import { SnackbarProvider } from 'contexts/snackBarContext'
import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { QueryClient as TanstackQueryClient, QueryClientProvider as TanstackQueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from './contexts/AuthContext'
import { AppRoutes } from './router/AppRoutes'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

const tanstackQueryClient = new TanstackQueryClient()

export default function App() {
  return (
    <TanstackQueryClientProvider client={tanstackQueryClient}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AdminProvider>
            <SnackbarProvider>
              <AppRoutes />
            </SnackbarProvider>
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
          </AdminProvider>
        </AuthProvider>
      </QueryClientProvider>
    </TanstackQueryClientProvider>
  )
}
