import { useCallback, useState } from 'react'
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  getDoc,
} from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'contexts/snackBarContext'
import { useAuth } from 'contexts/AuthContext'
import { inventoryListConverter, userInventoryListConverter } from 'converters/inventoryListConverter'

interface UseAdminListBuilderOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

interface CreateListParams {
  name: string
  description: string
  initialInventoryIds: string[]
  associatedUserIds?: string[]
}

export const useAdminListBuilder = (options?: UseAdminListBuilderOptions) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()
  const [loading, setLoading] = useState(false)

  // Fetch all lists (admin view)
  const {
    data: lists,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['adminInventoryLists'],
    queryFn: async () => {
      if (userInfo?.user_type !== 'admin') {
        throw new Error('Unauthorized access')
      }

      const listsQuery = query(
        collection(db, 'inventoryLists').withConverter(inventoryListConverter)
      )
      const snapshot = await getDocs(listsQuery)
      
      // Get all lists
      const listDocs = snapshot.docs.map(doc => doc.data())
      
      // Get user associations for all lists
      const userAssociationsQuery = query(
        collection(db, 'userInventoryLists').withConverter(userInventoryListConverter)
      )
      const userAssociations = await getDocs(userAssociationsQuery)
      const associationsMap = new Map<string, string[]>()
      
      userAssociations.docs.forEach(doc => {
        const data = doc.data()
        if (!associationsMap.has(data.inventoryListId)) {
          associationsMap.set(data.inventoryListId, [])
        }
        associationsMap.get(data.inventoryListId)?.push(data.userId)
      })
      
      // Combine lists with their user associations
      return listDocs.map(list => ({
        ...list,
        associatedUserIds: associationsMap.get(list.id) || []
      }))
    },
    enabled: userInfo?.user_type === 'admin',
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  // Create a new list (with optional user association)
  const createList = useMutation({
    mutationFn: async ({
      name,
      description,
      initialInventoryIds,
      associatedUserIds = []
    }: CreateListParams) => {
      if (userInfo?.user_type !== 'admin') throw new Error('Unauthorized access')

      const listDoc = await addDoc(collection(db, 'inventoryLists'), {
        dateCreated: new Date().toISOString(),
        createdBy: userInfo.id,
        inventoryIds: initialInventoryIds,
        name,
        description,
        isArchived: false,
        associatedUserIds: associatedUserIds,
      })

      // Create user associations for each user
      await Promise.all(
        associatedUserIds.map(userId =>
          addDoc(collection(db, 'userInventoryLists'), {
            userId,
            inventoryListId: listDoc.id,
            dateAdded: new Date().toISOString(),
          })
        )
      )

      return listDoc.id
    },
    onSuccess: () => {
      showSnackbar('List created successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['adminInventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error creating list', 'error')
      console.error('Error creating list:', error)
      options?.onError?.(error)
    },
  })

  // Add/Update user associations
  const updateListAssociations = useMutation({
    mutationFn: async ({ 
      listId, 
      userIds 
    }: { 
      listId: string
      userIds: string[] 
    }) => {
      // Get current associations
      const currentAssociationsQuery = query(
        collection(db, 'userInventoryLists'),
        where('inventoryListId', '==', listId)
      )
      const currentAssociations = await getDocs(currentAssociationsQuery)
      
      // Remove old associations
      await Promise.all(
        currentAssociations.docs.map(doc => deleteDoc(doc.ref))
      )
      
      // Add new associations
      await Promise.all(
        userIds.map(userId => 
          addDoc(collection(db, 'userInventoryLists'), {
            userId,
            inventoryListId: listId,
            dateAdded: new Date().toISOString(),
          })
        )
      )
    },
    onSuccess: () => {
      showSnackbar('List associations updated successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['adminInventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error updating list associations', 'error')
      console.error('Error updating list associations:', error)
      options?.onError?.(error)
    },
  })

  // Add this mutation to useAdminListBuilder
  const addVehiclesToList = useMutation({
    mutationFn: async ({ listId, vehicleIds }: { listId: string; vehicleIds: string[] }) => {
      const listRef = doc(collection(db, 'inventoryLists'), listId)
      const listDoc = await getDoc(listRef)
      const currentList = listDoc.data() as InventoryList
      
      const updatedIds = [...new Set([...currentList.inventoryIds, ...vehicleIds])]
      await updateDoc(listRef, { inventoryIds: updatedIds })
    },
    onSuccess: () => {
      showSnackbar('Vehicles added to list successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['adminInventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error adding vehicles to list', 'error')
      console.error('Error adding vehicles to list:', error)
      options?.onError?.(error)
    },
  })

  // Add this mutation
  const deleteList = useMutation({
    mutationFn: async (listId: string) => {
      // Delete the list document
      await deleteDoc(doc(collection(db, 'inventoryLists'), listId))
      
      // Delete user associations
      const userAssociationsQuery = query(
        collection(db, 'userInventoryLists'),
        where('inventoryListId', '==', listId)
      )
      const userAssociations = await getDocs(userAssociationsQuery)
      await Promise.all(
        userAssociations.docs.map(doc => deleteDoc(doc.ref))
      )
    },
    onSuccess: () => {
      showSnackbar('List deleted successfully', 'success')
      queryClient.invalidateQueries({ queryKey: ['adminInventoryLists'] })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      showSnackbar('Error deleting list', 'error')
      console.error('Error deleting list:', error)
      options?.onError?.(error)
    },
  })

  // Rest of the mutations (addVehiclesToList, removeVehiclesFromList, etc.) remain similar
  // but with admin-specific error handling and permissions

  return {
    lists,
    loading: isLoading || loading,
    isError,
    error,
    createList: createList.mutate,
    updateListAssociations: updateListAssociations.mutate,
    addVehiclesToList: addVehiclesToList.mutate,
    deleteList: deleteList.mutate,
    refetchLists: refetch,
  }
} 