import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material'
import { getApplicationDocuments } from 'core/api/application/bank'
import { BankApplication } from 'core/types/applications'


interface ViewDocumentsDialogProps {
  application: BankApplication | null
  isOpen: boolean
  onClose: () => void
}

const ViewDocumentsDialog: React.FC<ViewDocumentsDialogProps> = ({ application, isOpen, onClose }) => {
  const [documents, setDocuments] = useState<Record<string, string>>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (application) {
      setLoading(true)
      getApplicationDocuments(application.document_uuid).then(res => {
        setDocuments(res)
        setLoading(false)
      })
    }
  }, [application])

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Available Documents</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : Object.keys(documents).length > 0 ? (
          Object.entries(documents).map(([key, url]) =>
            url ? (
              <Button key={key} component={Link} href={url} target='_blank' style={{ margin: 5 }}>
                {key.replace(/_/g, ' ')} {/* Replacing underscores with spaces for better readability */}
              </Button>
            ) : null,
          )
        ) : (
          <Typography>No documents available for this application.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewDocumentsDialog
