import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material'
import React, { useState } from 'react'

// Icon greedy import
import { useSnackbar } from 'contexts/snackBarContext'
import { deleteStorageFile } from 'core/api/firebaseStorage/deleteStorageFile'
import { uploadFileToInventory } from 'core/api/inventory/addFileToInventory'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import { getPhotoSubmissionObject } from 'core/api/inventory/getPhotoSubmission'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ReviewSubmittedPhotosTab from '../Tabs/ReviewSubmittedPhotosTab'
import UploadPhotosTab from '../Tabs/UploadPhotosTab'
import ViewPhotosTab from '../Tabs/ViewPhotosTab'
import { useIsMobile } from 'hooks/screen/useIsMobile'

interface ManagePhotosDialogProps {
  collectionName: string,
  open: boolean
  onClose: () => void
  vehicle: Vehicle
}

type StagedPhoto = {
  file: File
  previewBlobUrl: string
}

const ManagePhotosDialog: React.FC<ManagePhotosDialogProps> = ({ collectionName, open, onClose, vehicle: row }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { showSnackbar } = useSnackbar()
  const isMobile = useIsMobile();


  const {
    data: photos,
    isLoading: isLoadingPhotos
  } = usePhotosForInventoryObject(collectionName, row.id)

  const { data: photoSubmissions, isLoading: isLoadingPhotoSubmissions } = useQuery(
    ['photoSubmissions', row.id],
    async () => {
      if (!row.uploadedPhotoIds || row.uploadedPhotoIds.length === 0) return null

      const promisses = row.uploadedPhotoIds.map((photoUploadFKey: PhotoSubmissionForeignKey) =>
        getPhotoSubmissionObject(photoUploadFKey),
      )
      return Promise.all(promisses)
    },
    {
      enabled: !!row.uploadedPhotoIds && row.uploadedPhotoIds.length > 0,
    },
  )

  const queryClient = useQueryClient()

  const { mutate: deletePhotos } = useMutation(
    async (photos: VehiclePhoto[]) => {
      for (const photo of photos) {
        await deleteStorageFile(photo.url)
      }
    },
    {
      onSuccess: (_, selectedPhotos) => {
        queryClient.setQueryData<VehiclePhoto[]>(['photos', row!.id], (oldPhotos = []) => {
          return oldPhotos.filter(photo => !selectedPhotos.includes(photo))
        })
      },
      onError: error => {
        console.error('Error deleting selected photos:', error)
      },
    },
  )

  const { mutate: uploadPhotos } = useMutation(
    async (stagedPhotos: StagedPhoto[]) => {
      return stagedPhotos.map(({ file }) => uploadFileToInventory(collectionName, row.id, file, true))
    },
    {
      onMutate: async newPhotos => {
        await queryClient.cancelQueries(['photos', row.id])
        const previousPhotos = queryClient.getQueryData(['photos', row.id])
        queryClient.setQueryData(['photos', row.id], (old: StagedPhoto[] | undefined) => [...(old || []), ...newPhotos])

        return { previousPhotos }
      },
      onError: (err, newPhotos, context) => {
        queryClient.setQueryData(['photos', row.id], context?.previousPhotos)
        showSnackbar('Error uploading photos', 'error')
      },
      onSettled: () => {
        queryClient.invalidateQueries(['photos', row.id])
      },
      onSuccess: () => {
        showSnackbar('Photos uploaded successfully!', 'success')
      },
    },
  )

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      sx={{ justifyContent: 'center' }}
    >
      <DialogTitle id='form-dialog-title'>
        {row.make} {row.model}: VIN#{row.vin}
      </DialogTitle>
      {isLoadingPhotos || isLoadingPhotoSubmissions ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 500,
            height: 450,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label={ isMobile ? 'View' : 'View Photos'} />
              <Tab label={ isMobile ? 'Upload' : 'Upload Photos'} />
              <Tab label='Review Submissions' />
            </Tabs>
          </Box>
          <DialogContent>
              {tabIndex === 0 && (
                <ViewPhotosTab collectionName={collectionName} vehicleId={row.id} photos={photos} onDeleteSelectedPhotos={deletePhotos} />
              )}
              {tabIndex === 1 && <UploadPhotosTab onSubmitAddedPhotos={uploadPhotos} />}
              {tabIndex === 2 && <ReviewSubmittedPhotosTab row={row} photoSubmissions={photoSubmissions} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ManagePhotosDialog
