import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Radio,
  FormControl,
  Box,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  CircularProgress,
} from '@mui/material'
import { Search, PersonAdd } from '@mui/icons-material'
import { collection, getDocs, query, where, limit } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { useQuery, useQueryClient } from 'react-query'
import { assignUserToVehicle } from 'core/api/inventory/assignUserToVehicle'
import CreateUserDialog from './CreateUserDialog'

interface InitiatePhotoUploadDialogProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

type DialogStep = 'user' | 'vehicles'

interface Vehicle {
  docId: string
  id: string
  year: string
  make: string
  model: string
  vin: string
  hasUploadId?: boolean
  assigned_user?: {
    id: string
    name: string
  }
}

const InitiatePhotoUploadDialog: React.FC<InitiatePhotoUploadDialogProps> = ({ open, onClose, onSuccess }) => {
  const [step, setStep] = useState<DialogStep>('user')
  const [accessType, setAccessType] = useState<'anonymous' | 'user'>('user')
  const [userSearch, setUserSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [vehicleSearch, setVehicleSearch] = useState('')
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false)
  const selectedUserRef = useRef<HTMLLIElement>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (selectedUser) {
      const timer = setTimeout(() => {
        const element = document.querySelector(`[data-user-id="${selectedUser.docId}"]`)
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'center' 
          })
        }
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [selectedUser])

  const { data: users = [], isLoading: isLoadingUsers } = useQuery(
    ['users', userSearch],
    async () => {
      const usersRef = collection(db, 'users')
      let q;
      
      if (userSearch) {
        q = query(
          usersRef,
          where('email', '>=', userSearch.toLowerCase()),
          where('email', '<=', userSearch.toLowerCase() + '\uf8ff')
        )
      } else {
        q = query(usersRef, limit(20))
      }
      
      const snapshot = await getDocs(q)
      return snapshot.docs.map(doc => ({
        docId: doc.id,
        email: doc.data().email,
        phone: doc.data().phone,
        ...doc.data()
      }))
    },
    {
      enabled: accessType === 'user',
      staleTime: 30000
    }
  )

  const { data: vehicles = [], isLoading: isLoadingVehicles } = useQuery(
    ['vehicles', vehicleSearch],
    async () => {
      const vehiclesRef = collection(db, 'master_inventory')
      let q;
      
      if (vehicleSearch) {
        q = query(
          vehiclesRef,
          where('vin', '>=', vehicleSearch.toUpperCase()),
          where('vin', '<=', vehicleSearch.toUpperCase() + '\uf8ff'),
          limit(50)
        )
      } else {
        q = query(vehiclesRef, limit(50))
      }
      
      const snapshot = await getDocs(q)
      
      const userIds = new Set(
        snapshot.docs
          .map(doc => doc.data().assigned_user?.id)
          .filter(Boolean)
      )
      
      const vehicleUploadMap = new Map()
      
      for (const userId of userIds) {
        const userVehiclesRef = collection(db, 'users', userId, 'vehicles')
        const userVehiclesSnapshot = await getDocs(userVehiclesRef)
        
        userVehiclesSnapshot.docs.forEach(doc => {
          if (doc.data().uploadId) {
            vehicleUploadMap.set(doc.id, true)
          }
        })
      }
      
      return snapshot.docs.map(doc => {
        const data = doc.data()
        const hasUploadId = vehicleUploadMap.has(doc.id)
        
        return {
          docId: doc.id,
          ...data,
          hasUploadId,
          assigned_user: data.assigned_user ? {
            ...data.assigned_user,
            name: data.assigned_user.name || 'Unknown user'
          } : undefined
        }
      }) as (Vehicle & { docId: string })[]
    },
    {
      enabled: step === 'vehicles',
      staleTime: 30000
    }
  )

  const handleNext = () => {
    if (step === 'user') {
      setStep('vehicles')
    } else {
      handleSubmit()
    }
  }

  const handleBack = () => {
    if (step === 'vehicles') {
      setStep('user')
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      if (accessType === 'user' && selectedUser) {
        for (const vehicleId of selectedVehicles) {
          await assignUserToVehicle(vehicleId, selectedUser.docId, 'master_inventory')
        }
      }

      onSuccess?.()
      onClose()
    } catch (error) {
      console.error('Error creating photo upload:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleVehicleToggle = (docId: string) => {
    setSelectedVehicles(prev => 
      prev.includes(docId)
        ? prev.filter(id => id !== docId)
        : [...prev, docId]
    )
  }

  const renderUserStep = () => (
    <>
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={() => setIsCreateUserDialogOpen(true)}
            startIcon={<PersonAdd />}
          >
            New User
          </Button>
        </Box>
      </FormControl>

      {accessType === 'user' && (
        <>
          <TextField
            fullWidth
            label="Search Users by Email"
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />

          <Box sx={{ minHeight: 300 }}>
            {isLoadingUsers ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <List sx={{ height: 300, overflow: 'auto' }}>
                {users.length > 0 ? (
                  users.map((user) => (
                    <ListItem
                      data-user-id={user.docId}
                      key={user.docId}
                      sx={{
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          backgroundColor: selectedUser?.docId === user.docId 
                            ? 'primary.main'
                            : 'action.hover',
                        },
                        ...(selectedUser?.docId === user.docId && {
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        }),
                      }}
                      onClick={() => setSelectedUser(user)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: selectedUser?.docId === user.docId 
                                ? 'inherit'
                                : 'text.primary',
                              fontWeight: selectedUser?.docId === user.docId ? 600 : 400
                            }}
                          >
                            {user.email}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{
                              color: selectedUser?.docId === user.docId 
                                ? 'inherit'
                                : 'text.secondary',
                              opacity: 0.8
                            }}
                          >
                            {user.phone || 'No phone number'}
                          </Typography>
                        }
                      />
                      {selectedUser?.docId === user.docId && (
                        <Radio
                          checked={true}
                          sx={{ 
                            ml: 2,
                            color: 'inherit',
                            '&.Mui-checked': {
                              color: 'inherit'
                            }
                          }}
                        />
                      )}
                    </ListItem>
                  ))
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                    <Typography color="text.secondary">
                      {userSearch ? 'No users found' : 'Start typing to search users'}
                    </Typography>
                  </Box>
                )}
              </List>
            )}
          </Box>
        </>
      )}
    </>
  )

  const renderVehiclesStep = () => (
    <>
      <TextField
        fullWidth
        label="Search Vehicles by VIN"
        value={vehicleSearch}
        onChange={(e) => setVehicleSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />

      <Box sx={{ minHeight: 400 }}>
        {isLoadingVehicles ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={400}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <List sx={{ height: 400, overflow: 'auto' }}>
            {vehicles.length > 0 ? (
              vehicles.map((vehicle) => (
                <ListItem
                  key={vehicle.docId}
                  sx={{
                    cursor: vehicle.hasUploadId ? 'not-allowed' : 'pointer',
                    '&:hover': {
                      backgroundColor: vehicle.hasUploadId ? undefined : 'action.hover',
                    },
                    opacity: vehicle.hasUploadId ? 0.5 : 1,
                  }}
                  onClick={() => !vehicle.hasUploadId && handleVehicleToggle(vehicle.docId)}
                >
                  <Checkbox
                    checked={selectedVehicles.includes(vehicle.docId)}
                    edge="start"
                    disabled={vehicle.hasUploadId}
                  />
                  <ListItemText
                    primary={
                      <Typography>
                        {vehicle.year} {vehicle.make} {vehicle.model}
                        {vehicle.assigned_user && (
                          <Typography 
                            component="span" 
                            color="text.secondary" 
                            sx={{ ml: 1, fontSize: '0.875rem' }}
                          >
                            (Assigned to: {vehicle.assigned_user.name})
                          </Typography>
                        )}
                        {vehicle.hasUploadId && (
                          <Typography
                            component="span"
                            color="error"
                            sx={{ ml: 1, fontSize: '0.875rem' }}
                          >
                            • Photos Already Uploaded
                          </Typography>
                        )}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        VIN: {vehicle.vin}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                <Typography color="text.secondary">
                  {vehicleSearch ? 'No vehicles found' : 'Start typing to search vehicles'}
                </Typography>
              </Box>
            )}
          </List>
        )}
      </Box>

      {selectedVehicles.length > 0 && (
        <Box sx={{ mt: 2, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
          <Typography variant="subtitle2">
            Selected Vehicles: {selectedVehicles.length}
          </Typography>
        </Box>
      )}
    </>
  )

  const handleClose = () => {
    setStep('user')
    setAccessType('user')
    setUserSearch('')
    setSelectedUser(null)
    setVehicleSearch('')
    setSelectedVehicles([])
    setIsSubmitting(false)
    
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {step === 'user' ? 'New Photo Upload - Select User' : 'Select Vehicles'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {step === 'user' ? renderUserStep() : renderVehiclesStep()}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {step === 'vehicles' && <Button onClick={handleBack}>Back</Button>}
        <Button 
          onClick={handleNext}
          variant="contained"
          disabled={
            isSubmitting || 
            (step === 'user' && accessType === 'user' && !selectedUser) ||
            (step === 'vehicles' && selectedVehicles.length === 0)
          }
        >
          {isSubmitting ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} color="inherit" />
              {`Assigning ${selectedVehicles.length} vehicle${selectedVehicles.length > 1 ? 's' : ''}...`}
            </Box>
          ) : (
            step === 'user' ? 'Next' : 'Create Upload'
          )}
        </Button>
      </DialogActions>
      <CreateUserDialog
        open={isCreateUserDialogOpen}
        onClose={() => setIsCreateUserDialogOpen(false)}
        onSuccess={async (userData) => {
          setSelectedUser({
            docId: userData.id,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName
          })
          await queryClient.invalidateQueries(['users'])
          setIsCreateUserDialogOpen(false)
        }}
      />
    </Dialog>
  )
}

export default InitiatePhotoUploadDialog 