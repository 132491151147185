import { AttachMoney, ExpandLess, ExpandMore, FilterList, LocalShipping, Phone, Speed } from '@mui/icons-material'
import {
  Box,
  Button,
  Drawer,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  Collapse,
} from '@mui/material'
import { formatDistance, formatPrice } from 'core/utils/inventoryUtils'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useState } from 'react'
import { getVehicleSizeOptions, vehicleFuelOptions, vehicleTypeOptions } from 'core/constants/inventory'

interface VehicleSidebarFilterProps {
  onFilterChange: (filters: FilterState) => void
  onFilterClear: () => void
  isAdmin?: boolean
}

export interface FilterState {
  vin: string
  stockNumber: string
  type: string
  size: string
  fuel: string
  miles: [number, number]
  listPrice: [number, number]
  askingPrice: [number, number]
  potentialProfit: [number, number]
  location: string
  contact: string
  addedDate: Date | null
  isAvailable: boolean
}

const initialFilterState: FilterState = {
  vin: '',
  stockNumber: '',
  type: '',
  size: '',
  fuel: '',
  miles: [0, 500000],
  listPrice: [0, 100000],
  askingPrice: [0, 100000],
  potentialProfit: [0, 50000],
  location: '',
  contact: '',
  addedDate: null,
  isAvailable: false,
}
const VehicleSidebarFilter: React.FC<VehicleSidebarFilterProps> = ({ onFilterChange, onFilterClear, isAdmin }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const isMobile = useIsMobile()

  return (
    <>
      {!isMobile && (
        <Box height='fit-content' top={24} boxShadow={1} minWidth={300} position='sticky'>
          <VehicleFilterList onFilterChange={onFilterChange} onFilterClear={onFilterClear} isAdmin={isAdmin} />
        </Box>
      )}
      {isMobile && (
        <Box>
          <Fab
            variant='extended'
            size='medium'
            color='primary'
            aria-label='filter'
            onClick={() => setIsFilterOpen(true)}
            sx={{
              display: { xs: 'block', sm: 'none' },
              position: 'fixed',
              bottom: 16,
              left: 16,
              zIndex: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterList sx={{ marginRight: 1 }} />
              <Typography variant='button'>Filter</Typography>
            </Box>
          </Fab>
          <Drawer
            open={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
            anchor='left'
            sx={{ width: { xs: '100%', sm: 'auto' }, display: { sm: 'none' } }}
          >
            <VehicleFilterList onFilterChange={onFilterChange} onFilterClear={onFilterClear} isAdmin={isAdmin} />
          </Drawer>
        </Box>
      )}
    </>
  )
}

const VehicleFilterList: React.FC<VehicleSidebarFilterProps> = ({ onFilterChange, onFilterClear, isAdmin }) => {
  const [filters, setFilters] = useState<FilterState>(initialFilterState)
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({
    general: true,
    performance: false,
    pricing: false,
    contact: false,
  })

  const toggleSection = (section: string) => {
    setExpandedSections(prevSections => ({ ...prevSections, [section]: !prevSections[section] }))
  }

  const handleFilterChange = (field: keyof FilterState, value: string | number | number[] | boolean) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters, [field]: value }
      onFilterChange(newFilters)
      return newFilters
    })
  }

  const clearFilters = () => {
    setFilters(initialFilterState)
    onFilterClear()
  }

  return (
    <List>
      {/* General Information */}
      <ListItemButton onClick={() => toggleSection('general')}>
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary='General Information' />
        {expandedSections.general ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expandedSections.general} timeout='auto' unmountOnExit>
        <ListItem>
          <TextField
            fullWidth
            label='VIN'
            value={filters.vin}
            onChange={e => handleFilterChange('vin', e.target.value)}
          />
        </ListItem>
        <ListItem>
          <Select
            fullWidth
            value={filters.type}
            onChange={e => {
              handleFilterChange('type', e.target.value)
              handleFilterChange('size', '')
            }}
            displayEmpty
          >
            <MenuItem value=''>Select Type</MenuItem>
            {vehicleTypeOptions.map(vehicleType => (
              <MenuItem key={vehicleType} value={vehicleType}>
                {vehicleType}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
        {filters.type && (
          <>
            <ListItem>
              <TextField
                fullWidth
                label='Stock number'
                value={filters.stockNumber}
                onChange={e => handleFilterChange('stockNumber', e.target.value)}
              />
            </ListItem>
            <ListItem>
              <Select
                fullWidth
                value={filters.size}
                onChange={e => handleFilterChange('size', e.target.value)}
                displayEmpty
              >
                <MenuItem value=''>Select Size</MenuItem>
                {getVehicleSizeOptions(filters.type).map(vehicleSize => (
                  <MenuItem key={vehicleSize} value={vehicleSize}>
                    {vehicleSize}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
          </>
        )}
      </Collapse>

      {/* Performance */}
      <ListItemButton onClick={() => toggleSection('performance')}>
        <ListItemIcon>
          <Speed />
        </ListItemIcon>
        <ListItemText primary='Performance' />
        {expandedSections.performance ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expandedSections.performance} timeout='auto' unmountOnExit>
        <ListItem>
          <Select
            fullWidth
            value={filters.fuel}
            onChange={e => handleFilterChange('fuel', e.target.value)}
            displayEmpty
          >
            <MenuItem value=''>Select Fuel Type</MenuItem>
            {vehicleFuelOptions.map(fuelType => (
              <MenuItem key={fuelType} value={fuelType}>
                {fuelType}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
        <ListItem>
          <Box sx={{ width: '80%', mx: 'auto', py: 2 }}>
            <Typography gutterBottom>
              <strong>Miles</strong>: {formatDistance(String(filters.miles[0]))} -{' '}
              {formatDistance(String(filters.miles[1]))}
            </Typography>
            <Slider
              value={filters.miles}
              onChange={(_, newValue) => handleFilterChange('miles', newValue)}
              valueLabelDisplay='auto'
              min={0}
              max={500000}
              marks={[
                { value: 0, label: '0' },
                { value: 500000, label: '500k' },
              ]}
            />
          </Box>
        </ListItem>
      </Collapse>

      {/* Pricing */}
      <ListItemButton onClick={() => toggleSection('pricing')}>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary='Pricing' />
        {expandedSections.pricing ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expandedSections.pricing} timeout='auto' unmountOnExit>
        {[
          { key: 'askingPrice', label: 'Sell Price', max: 100000 },
          ...(isAdmin
            ? [
                { key: 'listPrice', label: 'Buy Price', max: 100000 },
                { key: 'potentialProfit', label: 'Potential Profit', max: 50000 },
              ]
            : []),
        ].map(({ key, label, max }) => (
          <ListItem key={key}>
            <Box sx={{ width: '80%', mx: 'auto', py: 2 }}>
              <Typography gutterBottom>
                <strong>{label}</strong>:{' '}
                {formatPrice(String(filters[key as keyof FilterState] as [number, number])[0])} -{' '}
                {formatPrice(String((filters[key as keyof FilterState] as [number, number])[1]))}
              </Typography>
              <Slider
                value={filters[key as keyof FilterState] as [number, number]}
                onChange={(_, newValue) => handleFilterChange(key as keyof FilterState, newValue)}
                valueLabelDisplay='auto'
                min={0}
                max={max}
                marks={[
                  { value: 0, label: '0' },
                  { value: max, label: `${max / 1000}k` },
                ]}
              />
            </Box>
          </ListItem>
        ))}
      </Collapse>

      {/* Contact */}
      <ListItemButton onClick={() => toggleSection('contact')}>
        <ListItemIcon>
          <Phone />
        </ListItemIcon>
        <ListItemText primary='Contact' />
        {expandedSections.contact ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expandedSections.contact} timeout='auto' unmountOnExit>
        <ListItem>
          <TextField
            fullWidth
            label='Contact'
            value={filters.contact}
            onChange={e => handleFilterChange('contact', e.target.value)}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label='Location'
            value={filters.location}
            onChange={e => handleFilterChange('location', e.target.value)}
          />
        </ListItem>
      </Collapse>

      {/* Status */}
      <ListItem>
        <ListItemText primary='Available' />
        <Switch checked={filters.isAvailable} onChange={e => handleFilterChange('isAvailable', e.target.checked)} />
      </ListItem>
      {/* Action Buttons */}
      <ListItem>
        <Button variant='outlined' color='secondary' onClick={clearFilters} fullWidth>
          Clear Filters
        </Button>
      </ListItem>
    </List>
  )
}
export default VehicleSidebarFilter
