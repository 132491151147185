import React, { useEffect, useState, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Divider,
  Tabs,
  Tab,
} from '@mui/material'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import { db, storage } from 'core/config/firebase'
import PhotoPreviewDialog from './PhotoPreviewDialog'
import { useSnackbar } from 'contexts/snackBarContext'
import { PhotoUploadVehicle } from 'core/types/photoUpload'
import { sendSMS } from 'core/api/sms/sendSMS'
import { doc, updateDoc, arrayUnion } from '@firebase/firestore'

interface TransporterPhotoPreviewDialogProps {
  open: boolean
  onClose: () => void
  vehicles: PhotoUploadVehicle[]
  userId: string
  phoneNumber: string
}

interface PhotoItem {
  id: string
  url: string
  label: string
  vehicleId: string
}

const TransporterPhotoPreviewDialog = ({ open, onClose, vehicles, userId, phoneNumber }: TransporterPhotoPreviewDialogProps) => {
  const [preDeliveryPhotos, setPreDeliveryPhotos] = useState<PhotoItem[]>([])
  const [postDeliveryPhotos, setPostDeliveryPhotos] = useState<PhotoItem[]>([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [previewPhoto, setPreviewPhoto] = useState<{ url: string; label: string } | null>(null)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (open) {
      fetchAllPhotos('preDelivery').then(setPreDeliveryPhotos)
      fetchAllPhotos('postDelivery').then(setPostDeliveryPhotos)
    }
  }, [open, vehicles, userId])

  const fetchAllPhotos = async (deliveryType: 'preDelivery' | 'postDelivery') => {
    try {
      setLoading(true)
      setError(null)
      const allPhotos: PhotoItem[] = []

      for (const vehicle of vehicles) {
        const storagePath = `users/${userId}/photos/${vehicle.id}/${deliveryType}`
        console.log('Fetching photos for:', storagePath)
        const storageRef = ref(storage, storagePath)
        const result = await listAll(storageRef)
        console.log('List result:', result)

        const photos = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item)
            console.log('Photo URL:', url)
            return { id: item.name, url, label: `${vehicle.make} ${vehicle.model} - ${item.name}`, vehicleId: vehicle.id }
          })
        )
        allPhotos.push(...photos)
      }

      return allPhotos
    } catch (err) {
      console.error('Error fetching photos:', err)
      setError('Failed to load photos')
      return []
    } finally {
      setLoading(false)
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handlePhotoClick = (url: string, label: string) => {
    setPreviewPhoto({ url, label })
  }

  const groupPhotosByVehicle = (photos: PhotoItem[]) => {
    return photos.reduce((acc, photo) => {
      if (!acc[photo.vehicleId]) {
        acc[photo.vehicleId] = {
          vehicleInfo: `${vehicles.find(v => v.id === photo.vehicleId)?.make} ${vehicles.find(v => v.id === photo.vehicleId)?.model}`,
          photos: []
        }
      }
      acc[photo.vehicleId].photos.push(photo)
      return acc
    }, {} as Record<string, { vehicleInfo: string, photos: PhotoItem[] }>)
  }

  const groupedPreDeliveryPhotos = useMemo(() => groupPhotosByVehicle(preDeliveryPhotos), [preDeliveryPhotos])
  const groupedPostDeliveryPhotos = useMemo(() => groupPhotosByVehicle(postDeliveryPhotos), [postDeliveryPhotos])

  const handleGoodToGoClick = async () => {
    if (!phoneNumber) {
      showSnackbar('No phone number found. Please update it in user management.', 'error')
      return
    }

    try {
      await sendSMS(phoneNumber, 'Your photos have been approved, you are authorized to begin shipment!')
      showSnackbar('SMS sent successfully!', 'success')



      for (const vehicle of vehicles) {
        const vehicleRef = doc(db, 'users', userId, 'vehicles', vehicle.id)
        await updateDoc(vehicleRef, {
          'accepted': true
        })
      }
    } catch (error) {
      console.error('Error sending SMS or updating Firestore:', error)
      showSnackbar('Failed to send SMS or update Firestore.', 'error')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          position: 'relative',
        }
      }}
    >
      <DialogTitle>Transporter Photo Preview</DialogTitle>
      <Divider />
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Pre-Delivery" />
        <Tab label="Post-Delivery" />
      </Tabs>

      <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Box>
            {Object.entries(selectedTab === 0 ? groupedPreDeliveryPhotos : groupedPostDeliveryPhotos).map(([vehicleId, data]) => (
              <Box key={vehicleId} sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                  {data.vehicleInfo}
                </Typography>
                <Grid container spacing={2}>
                  {data.photos.map((photo) => (
                    <Grid item xs={12} sm={6} md={4} key={photo.id}>
                      <Box
                        sx={{
                          height: 250,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                          overflow: 'hidden',
                          position: 'relative',
                          bgcolor: 'background.paper',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            p: 1,
                            bgcolor: 'background.default',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          {photo.label}
                        </Typography>
                        <Box
                          onClick={() => handlePhotoClick(photo.url, photo.label)}
                          sx={{
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 0.9,
                            }
                          }}
                        >
                          <img
                            src={photo.url}
                            alt={photo.label}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                              backgroundColor: '#f5f5f5',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {selectedTab === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoodToGoClick}
                    sx={{ mt: 2 }}
                  >
                    Good to Go!
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>

      <PhotoPreviewDialog
        open={!!previewPhoto}
        onClose={() => setPreviewPhoto(null)}
        photoUrl={previewPhoto?.url || ''}
        photoLabel={previewPhoto?.label || ''}
      />
    </Dialog>
  )
}

export default TransporterPhotoPreviewDialog