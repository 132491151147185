import React, { useState } from 'react'
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'
// import { forgotPassword } from 'core/api/auth';
import { useAuth } from 'contexts/AuthContext'

import { authTextFieldStyle } from 'components/authTextFieldStyle'

// https://api.optictruckworks.com/api/action?

const ForgotPassword = (): JSX.Element => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState({ title: '', body: '', button: '' })
  const [isLoading, setIsLoading] = useState(false)

  const { handleSendPasswordResetEmail } = useAuth()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    const formData = new FormData(event.currentTarget)
    const email = formData.get('email') as string
    const response = await handleSendPasswordResetEmail(email)

    if (response) {
      setIsLoading(false)
      setDialogContent({
        title: 'Password Reset Email Sent',
        body: `If an account exists for ${email}, you should see password reset instructions in your inbox.`,
        button: 'Ok',
      })
      setOpenDialog(true)
    } else {
      setIsLoading(false)
      setDialogContent({
        title: 'Error',
        body: 'There was an error sending the password reset email. Please try again.',
        button: 'Try Again',
      })
      setOpenDialog(true)
      console.error('Error logging in')
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const LoadingDialog = () => (
    <Dialog open={isLoading}>
      <DialogTitle>Sending reset email...</DialogTitle>
      <Box sx={{ margin: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    </Dialog>
  )

  const ForgotPasswordDialog = (): JSX.Element => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              {dialogContent.button}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <>
      {/* Dialogs */}
      <ForgotPasswordDialog />
      <LoadingDialog />
      {/* Title */}
      <Box sx={{ marginTop: '20px' }}>
        <Typography>Enter your email address for a password reset link.</Typography>
      </Box>
      {/* Forgot Password Form */}
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          required
          fullWidth
          autoFocus
          margin='normal'
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          sx={authTextFieldStyle}
        />
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
          Send Password Reset Email
        </Button>
      </Box>

      {/* Links */}
      <Grid container>
        <Grid item xs>
          <Link href='/login' variant='body2'>
            Remember your password?
          </Link>
        </Grid>
        <Grid item>
          <Link href='/signup' variant='body2'>
            Don&apos;'t have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPassword
