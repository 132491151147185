import React, { useState, useEffect } from 'react'
import { CustomNavigation } from 'router/types/customNavigation'
import {
  Assignment,
  Business,
  Description,
  Group,
  Home,
  People,
  Calculate,
  Search,
  LocalShipping,
  Inventory,
  PhotoCamera,
  WebAsset,
  Build,
  Handshake,
  Construction,
  Dashboard,
  ListAlt,
  DirectionsCar,
  Shield,
  Settings,
  Public,
  CarCrash,
  CarRental,
  AddAPhoto,
  MessageOutlined,
  LocalSee,
  AccountBalance,
  Badge,
  History,
  FormatListBulleted,
} from '@mui/icons-material'
import {
  SuperAdminHome,
  HubspotDash,
  ApplicationManagement,
  UserManagement,
  UserManagementV2,
  DepreciationCalculator,
  AffiliateManagement,
  TruckDebtOverhaulManagement,
  VINDecoder,
  InventoryManagement,
} from 'views/SuperAdmin'
import SubmissionManagement from 'views/SuperAdmin/Management/SubmissionManagement'
import PhotoUpload from 'views/SuperAdmin/Management/PhotoUpload'
import InventoryV3 from 'views/SuperAdmin/InventoryV3'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AuditLog from 'views/SuperAdmin/Tools/AuditLog/AuditLog'
import TransporterPhotoUpload from 'views/SuperAdmin/Management/PhotoUpload/TransporterPhotoUpload'
import { fetchPhotoUploads } from 'core/api/photoUploadUtils'
import { calculateTotalSubmissions } from 'core/api/submissionUtils'
import { PhotoUpload as PhotoUploadModel } from 'core/types/photoUpload'
import TransporterBadge from 'components/TransporterBadge'
import UserListBuilder from 'views/Shared/UserListBuilder/UserListBuilder'
import AdminListBuilder from 'views/SuperAdmin/AdminListBuilder'
import MessageLog from 'views/SuperAdmin/Tools/Messages/MessageLog'

// const theme = useTheme()

export const getSuperUserPages = (userType: string): CustomNavigation => {
  const adminOnlyPages = []

  return [
    { kind: 'header', title: 'General' },
    {
      segment: 'admin',
      title: 'Dashboard',
      icon: <Dashboard />,
      component: <SuperAdminHome />,
    },
    {
      segment: 'admin/application-management',
      title: 'Financing Applications',
      icon: <AccountBalance />,
      component: <ApplicationManagement />,
    },
    {
      segment: 'admin/management/photo-upload',
      title: 'Photo Uploader',
      icon: (
        <Box position='relative' sx={{ display: 'flex' }}>
          <LocalSee />
        </Box>
      ),
      component: <PhotoUpload />,
    },
    {
      segment: 'admin/management/transporter-photo-upload',
      title: 'Transporter Photos',
      icon: <TransporterBadge />,
      component: <TransporterPhotoUpload />,
    },
    {
      segment: 'admin/user-management-v2',
      title: 'User Management',
      icon: <Group />,
      component: <UserManagementV2 />,
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Inventory' },
    {
      segment: 'admin/inventory',
      title: 'Manage Inventory',
      icon: <ListAlt />,
      children: [
        {
          segment: 'optic-inventory-v2',
          title: 'Optic Inventory 2.0',
          icon: (
            <Box position='relative'>
              <DirectionsCar />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: -4,
                  right: -4,
                  fontSize: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                2.0
              </Typography>
            </Box>
          ),
          component: <InventoryV3 inventoryCollection='master_inventory' />,
        },
        ...(userType !== 'admin'
          ? []
          : [
              {
                segment: 'shield-inventory-v2',
                title: 'Shield Inventory 2.0',
                icon: (
                  <Box position='relative'>
                    <Shield />
                    <Typography
                      sx={{
                        position: 'absolute',
                        bottom: -4,
                        right: -4,
                        fontSize: '0.5rem',
                        fontWeight: 'bold',
                      }}
                    >
                      2.0
                    </Typography>
                  </Box>
                ),
                component: <InventoryV3 inventoryCollection='shield_inventory' />,
              },
            ]),
        {
          segment: 'admin/optic-inventory-management',
          title: 'Optic Inventory (Deprecated)',
          icon: <DirectionsCar />,
          component: <InventoryManagement inventoryCollectionName='master_inventory' />,
        },
        ...(userType !== 'admin'
          ? []
          : [
              {
                segment: 'admin/shield-inventory-management',
                title: 'Shield Inventory (Deprecated)',
                icon: <Shield />,
                component: <InventoryManagement inventoryCollectionName='shield_inventory' />,
              },
            ]),
      ],
    },
    {
      segment: 'admin/management/submission-management',
      title: 'Website Submissions',
      component: <SubmissionManagement />,
      icon: <Public />,
    },
    {
      segment: 'admin/list-builder',
      title: 'List Builder',
      icon: <FormatListBulleted />,
      component: <AdminListBuilder />,
    },
    {
      segment: 'admin/my-inventory-lists',
      title: 'My Inventory Lists',
      icon: <FormatListBulleted />,
      component: <UserListBuilder />,
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Tools' },
    {
      segment: 'admin/tools',
      title: 'Tools',
      icon: <Build />,
      children: [
        {
          segment: 'depreciation-calculator',
          title: 'Depreciation Calculator',
          icon: <CarCrash />,
          component: <DepreciationCalculator />,
        },
        {
          segment: 'vin-decoder',
          title: 'VIN Decoder',
          icon: <CarRental />,
          component: <VINDecoder />,
        },
        {
          segment: 'audit-log',
          title: 'Audit Log',
          icon: <History />,
          component: <AuditLog />,
        },
        {
          segment: 'messages',
          title: 'Messages',
          icon: <MessageOutlined />,
          component: <MessageLog />,
        },
      ],
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Miscellaneous' },
    {
      segment: 'admin/other',
      title: 'Miscellaneous',
      icon: <Settings />,
      children: [
        {
          segment: 'admin/affiliate-management',
          title: 'Affiliate Program',
          icon: <Handshake />,
          component: <AffiliateManagement />,
        },
        {
          segment: 'admin/truck-debt-overhaul-management',
          title: 'Truck Debt Overhaul',
          icon: <LocalShipping />,
          component: <TruckDebtOverhaulManagement />,
        },
        {
          segment: 'hubspot-integration',
          title: 'Hubspot Integration',
          icon: <Business />,
          component: <HubspotDash />,
        },
      ],
    },
  ]
}
