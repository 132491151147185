import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import { createFirebaseUser } from 'core/api/users'
import { adminFunctions } from 'core/api/application/admin'
import { useSnackbar } from 'contexts/snackBarContext'

export const CreateUser = () => {
  const [userData, setUserData] = useState<CreateUserType>({
    email: '',
    phone: '',
    password: '',
    first_name: '',
    last_name: '',
    user_type: '',
    company_name: '',
    entity_structure: '',
    email_verified: false,
    is_active: false,
    bank_id: '',
    dealership_name: '',
    notes: '',
  })
  const [open, setOpen] = useState(false)
  const [useTempPassword, setUseTempPassword] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [errorCreatingUser, setErrorCreatingUser] = useState<string | null>(null)
  // const [isUserTypeSelected, setIsUserTypeSelected] = useState(true);
  const { showSnackbar, updateSnackbar } = useSnackbar()

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setIsEmailValid(true)
    setIsPasswordValid(true)
    setErrorCreatingUser(null)
    setOpen(false)
  }

  const [banks, setBanks] = useState<Bank[]>([])

  useEffect(() => {
    const fetchBanks = async () => {
      const res = await adminFunctions.getBanks()
      setBanks(res)
    }
    fetchBanks()
  }, [])

  const generateTempPassword = () => {
    const length = 12
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$'

    const getRandomChar = (charSet: string) => charSet.charAt(Math.floor(Math.random() * charSet.length))

    const requiredChars = [getRandomChar('ABCDEFGHIJKLMNOPQRSTUVWXYZ'), getRandomChar('0123456789')]

    const remainingChars = length - requiredChars.length
    const allChars = charset.replace(/[A-Z0-9]/g, '')

    const randomChars = Array.from({ length: remainingChars }, () => getRandomChar(allChars))
    const tempPassword = [...requiredChars, ...randomChars].sort(() => Math.random() - 0.5).join('')

    return tempPassword
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'useTempPassword') {
      setUseTempPassword(e.target.checked)
      if (e.target.checked) {
        setUserData({ ...userData, password: generateTempPassword() })
      } else {
        setUserData({ ...userData, password: '' })
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      })
    }
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setUserData({ ...userData, [event.target.name]: event.target.value })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    showSnackbar('Creating user...', 'info', 'medium', undefined, true, 'Creating user...');
  
    createFirebaseUser(userData)
      .then((response) => {
        console.log('response', response);
        if (response === 'Created') {
          updateSnackbar({
            loading: false,
            message: 'User created successfully!',
            severity: 'success',
          });
          console.log('User created successfully:', response);
        } else {
          updateSnackbar({
            loading: false,
            message: response,
            severity: 'error',
          });
          console.error('Error creating user:', response);
        }
  
      })
      .catch((error) => {
        console.error('Error creating user', error);
        setErrorCreatingUser('Error creating user');
        updateSnackbar({
          loading: false,
          message: 'Error creating user',
          severity: 'error',
        });
      });
      handleClose();
  };
  
  

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Button variant='outlined' onClick={handleOpen}>
          Create User
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  value={userData.email}
                  onChange={handleChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? 'Invalid email address' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='phone'
                  label='Phone Number'
                  name='phone'
                  autoComplete='phone'
                  value={userData.phone}
                  onChange={handleChange}
                  // #TODO: Add phone number validation
                  // error={!isEmailValid}
                  // helperText={!isEmailValid ? 'Invalid email address' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='first_name'
                  label='First Name'
                  name='first_name'
                  autoComplete='fname'
                  value={userData.first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='last_name'
                  label='Last Name'
                  name='last_name'
                  autoComplete='lname'
                  value={userData.last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={!useTempPassword}
                  fullWidth
                  id='password'
                  label='Password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  value={userData.password}
                  onChange={handleChange}
                  disabled={useTempPassword}
                  error={!isPasswordValid}
                  helperText={
                    !isPasswordValid
                      ? 'Password does not meet requirements. Must be 8 characters and contain a number.'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>User Type</InputLabel>

                  <Select
                    label='User Type'
                    name='user_type'
                    value={userData.user_type}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value='admin'>Admin</MenuItem>
                    <MenuItem value='client'>Client</MenuItem>
                    <MenuItem value='bankAgent'>Bank Agent</MenuItem>
                    <MenuItem value='babyAdmin'>Baby Admin</MenuItem>
                    <MenuItem value='dealer'>Dealer</MenuItem>
                  </Select>

                  {/* TODO: @chaseleto I removed this when i was adding eslint bcuz the lint gods picked up it wasnt doing anything */}

                  {/* {!isUserTypeSelected && (
                    <Typography color="error" variant="caption">
                      Please select a user type.
                    </Typography>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='notes'
                  label='Notes'
                  name='notes'
                  type='text'
                  value={userData.notes}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
              {(userData.user_type === 'client' || userData.user_type === 'bankAgent') && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Bank</InputLabel>

                    <Select label='Bank' name='bank_id' value={userData.bank_id} onChange={handleSelectChange}>
                      {banks.map(bank => (
                        <MenuItem key={bank.id} value={bank.id}>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {userData.user_type === 'client' && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='company_name'
                    label='Company Name'
                    name='company_name'
                    type='text'
                    value={userData.company_name}
                    onChange={handleChange}
                  />
                </Grid>
              )}

              {userData.user_type === 'client' && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='entity_structure'
                    label='Entity Structure'
                    name='entity_structure'
                    type='text'
                    value={userData.entity_structure}
                    onChange={handleChange}
                  />
                </Grid>
              )}

              {userData.user_type === 'dealer' && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='dealership_name'
                    label='Dealership Name'
                    name='dealership_name'
                    type='text'
                    value={userData.dealership_name}
                    onChange={handleChange}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={useTempPassword} onChange={handleChange} name='useTempPassword' />}
                  label='Send Temp Password'
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={useTempPassword}
                      checked={userData ? userData.email_verified : false}
                      onChange={handleChange}
                      name='email_verified'
                    />
                  }
                  label='Email Verified'
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={userData.is_active} onChange={handleChange} name='is_active' />}
                  label='Active'
                />
              </Grid>
              <Typography sx={{ color: 'red' }}>{errorCreatingUser && `${errorCreatingUser}`}</Typography>
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Create User
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
