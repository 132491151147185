import { db } from 'core/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteStorageFile } from 'core/api/firebaseStorage/deleteStorageFile'
import { uploadFileToInventory } from 'core/api/inventory/addFileToInventory'
import { updatePhotoPosition } from 'core/api/inventory/updatePhotoPosition'

const storage = getStorage()

const getAdditionalPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
  definedInventoryPhotos: VehiclePhoto[],
): Promise<VehiclePhoto[]> => {
  const storageRef = ref(storage, `${collectionName}/${inventoryObjectId}`)

  const photos: VehiclePhoto[] = []

  const storageObjects = await listAll(storageRef)

  for (const obj of storageObjects.items) {
    const url = await getDownloadURL(obj)

    if (definedInventoryPhotos.some(photo => photo.url === url)) {
      continue
    } else {
      photos.push({
        id: inventoryObjectId,
        name: obj.name,
        fullPath: obj.fullPath,
        url,
      })
    }
  }
  return photos
}

const fetchPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
): Promise<VehiclePhoto[]> => {
  const inventoryDocRef = doc(db, collectionName, inventoryObjectId)

  const docSnap = await getDoc(inventoryDocRef)
  const definedPhotos: VehiclePhoto[] = []

  if (!docSnap.exists()) {
    console.error('No such document!')
    return []
  }

  const data = docSnap.data()

  if (!data) {
    console.error('No data found for document!')
    return []
  }

  const errorPhotos: unknown[] = []

  if (data.photos) {
    const photos: Record<string, string> = data.photos
    for (const [key, value] of Object.entries(photos)) {
      try {
        const storageRef = ref(storage, `${collectionName}/${inventoryObjectId}/${value}`)

        if (!storageRef) {
          continue
        }

        const url = await getDownloadURL(storageRef)

        definedPhotos.push({
          id: inventoryObjectId,
          name: value,
          url: url,
          position: key,
          fullPath: '',
        })
      } catch (error) {
        console.error('Error getting download URL for photo', value, error)
        errorPhotos.push(value)
      }
    }
  }

  const additionalPhotos = await getAdditionalPhotosForInventoryObject(
    collectionName,
    inventoryObjectId,
    definedPhotos,
  )

  return [...definedPhotos, ...additionalPhotos]
}

export const usePhotosForInventoryObject = (collectionName: string, inventoryObjectId: string) => {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['photos', collectionName, inventoryObjectId],
    queryFn: () => fetchPhotosForInventoryObject(collectionName, inventoryObjectId),
    enabled: !!collectionName && !!inventoryObjectId,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  const deletePhotosMutation = useMutation({
    mutationFn: async (photosToDelete: VehiclePhoto[]) => {
      for (const photo of photosToDelete) {
        await deleteStorageFile(photo.url)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['photos', collectionName, inventoryObjectId] })
    }
  })

  const uploadPhotosMutation = useMutation({
    mutationFn: async (photosToUpload: { file: File; previewBlobUrl: string }[]) => {
      return Promise.all(
        photosToUpload.map(({ file }) => 
          uploadFileToInventory(collectionName, inventoryObjectId, file, true)
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['photos', collectionName, inventoryObjectId] })
    }
  })

  const updatePositionMutation = useMutation({
    mutationFn: async (params: { name: string; position: PhotoPosition }) => {
      await updatePhotoPosition(collectionName, inventoryObjectId, params.name, params.position)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['photos', collectionName, inventoryObjectId] })
    }
  })

  return {
    ...query,
    deletePhotos: deletePhotosMutation.mutate,
    uploadPhotos: uploadPhotosMutation.mutate,
    updatePosition: updatePositionMutation.mutate,
    isDeleting: deletePhotosMutation.isPending,
    isUploading: uploadPhotosMutation.isPending,
    isUpdating: updatePositionMutation.isPending,
  }
}
