/* eslint-disable */

// TODO: @adamsiwiec1 i will type this

import { API_URL } from 'core/config/index'
import { ClientApplication } from 'core/types/applications'

const RouteName = 'client'

const getJwtToken = (): string | null => {
  return localStorage.getItem('token')
}

const getHeaders = (): HeadersInit => {
  const jwtToken = getJwtToken()
  return {
    'Content-Type': 'application/json',
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  }
}

interface GeneratedApplicationResponse {
  url: string;
  application_id: number;
}

export const generateApplicationUrl = async (applicationId: number): Promise<GeneratedApplicationResponse> => {
  const response = await fetch(`${API_URL}/${RouteName}/generate-application?application_id=${applicationId}`, {
    method: 'GET',
    headers: getHeaders(),
  },)

  const data = await response.json()
  return data
}


export const generateNewApplicationUrl = async (): Promise<GeneratedApplicationResponse> => {
  const response = await fetch(`${API_URL}/${RouteName}/generate-new-application`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

interface FinishDocumentRequestParams {
  application_id?: number
  document_uuid: string
  template_uuid: string
  date_completed: string
}

export const postFinishedApplication = async (params: FinishDocumentRequestParams): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/submit-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(params),
  })
  return response
}

export const getClientApplications = async (): Promise<ClientApplication[]> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-applications`, {
    method: 'GET',
    headers: getHeaders(),
  })
  const data = await response.json()
  return data
}

export const getViewableDocument = async (document_id: string): Promise<string> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-viewable-document`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_id }),
  })

  if (!response.ok) {
    throw new Error('Document fetch failed')
  }

  const blob = await response.blob()
  return URL.createObjectURL(blob) // Creates a URL for the blob
}

export const registerAffiliate = async (affiliate: any): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/register-affiliate`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(affiliate),
  })

  return response
}

export const getLeads = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-leads`, {
    method: 'GET',
    headers: getHeaders(),
  })

  return response
}

export const createLead = async (lead: any): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/submit-lead`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(lead),
  })

  return response
}

export const getDocumentForCorrections = async (application_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/make-application-corrections`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id }),
  })

  const data = await response.json()
  return data
}

export const submitApplicationCorrections = async (application_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/submit-corrections`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id }),
  })

  const data = await response.json()
  return data
}

export const generateW9Url = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/generate-w9-form`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

export const submitW9 = async (document_uuid: any): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/submit-w9-form`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_uuid }),
  })

  const data = await response.json()
  return data
}

export const submitTruckDebtOverhaulSubmission = async (truckDebtOverhaulSubmission: any): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/submit-truck-debt-overhaul`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(truckDebtOverhaulSubmission),
  })

  const data = await response.json()
  return data
}

export const associateTrucksToSubmission = async (
  submission_id: string,
  trucks: DebtOverhaulTruck[],
): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/associate-trucks-to-debt-overhaul-submission`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ submission_id, trucks }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

export const getTruckDebtOverhaulSubmissions = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-truck-debt-overhaul-submissions`, {
    method: 'GET',
    headers: getHeaders(),
  })

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }

  const data = await response.json()

  return data
}

export const getDebtOverhaulTrucks = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-debt-overhaul-trucks`, {
    method: 'GET',
    headers: getHeaders(),
  })

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }

  const data = await response.json()

  return data
}

export const getTruckDebtOverhaulSubmissionInfo = async (submissionId: number): Promise<any> => {
  const response = await fetch(
    `${API_URL}/${RouteName}/get-truck-debt-overhaul-submission-info?submission_id=${submissionId}`,
    {
      method: 'GET',
      headers: getHeaders(),
    },
  )

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }

  const data = await response.json()

  return data
}

export const editTruckDebtOverhaulSubmission = async (editedTruckDebtOverhaulSubmission: any): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/edit-truck-debt-overhaul-submission`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(editedTruckDebtOverhaulSubmission),
  })

  const data = await response.json()
  return data
}

interface ClientFunctions {
  generateApplicationUrl: (applicationId: number) => Promise<any>
  generateNewApplicationUrl: () => Promise<any>
  postFinishedApplication: (document: any) => Promise<any>
  getClientApplications: () => Promise<any>
  getViewableDocument: (document_id: string) => Promise<string>
  registerAffiliate: (affiliate: any) => Promise<any>
  getLeads: () => Promise<any>
  createLead: (lead: any) => Promise<any>
  getDocumentForCorrections: (application_id: number) => Promise<any>
  submitApplicationCorrections: (application_id: any) => Promise<any>
  generateW9Url: () => Promise<any>
  submitW9: (document_uuid: any) => Promise<any>
  submitTruckDebtOverhaulSubmission: (truckDebtOverhaulSubmission: any) => Promise<any>
  associateTrucksToSubmission: (submission_id: string, trucks: DebtOverhaulTruck[]) => Promise<any>
  getTruckDebtOverhaulSubmissions: () => Promise<any>
  getDebtOverhaulTrucks: () => Promise<any>
  getTruckDebtOverhaulSubmissionInfo: (submissionId: number) => Promise<any>
  editTruckDebtOverhaulSubmission: (editedTruckDebtOverhaulSubmission: any) => Promise<any>
}

export const clientFunctions: ClientFunctions = {
  generateNewApplicationUrl: generateNewApplicationUrl,
  generateApplicationUrl: generateApplicationUrl,
  postFinishedApplication: postFinishedApplication,
  getClientApplications: getClientApplications,
  getViewableDocument: getViewableDocument,
  registerAffiliate: registerAffiliate,
  getLeads: getLeads,
  createLead: createLead,
  getDocumentForCorrections: getDocumentForCorrections,
  submitApplicationCorrections: submitApplicationCorrections,
  generateW9Url: generateW9Url,
  submitW9: submitW9,
  submitTruckDebtOverhaulSubmission: submitTruckDebtOverhaulSubmission,
  associateTrucksToSubmission: associateTrucksToSubmission,
  getTruckDebtOverhaulSubmissions: getTruckDebtOverhaulSubmissions,
  getDebtOverhaulTrucks: getDebtOverhaulTrucks,
  getTruckDebtOverhaulSubmissionInfo: getTruckDebtOverhaulSubmissionInfo,
  editTruckDebtOverhaulSubmission: editTruckDebtOverhaulSubmission,
}
