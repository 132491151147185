import React from "react";
import { CustomNavigation } from "router/types/customNavigation";
import { Home } from "@mui/icons-material";
import { BankHome } from "views/Bank";

export const bankPages: CustomNavigation = [
    { kind: "header", title: "Bank" },
    {
      segment: "bankAgent",
      title: "Home",
      icon: <Home />,
      component: <BankHome />,
    },
  ];