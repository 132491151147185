import { db } from 'core/config/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'


export const updatePhotoPosition = async (collectionName: string, inventoryId: string, photoName: string, position: PhotoPosition) => {
  const inventoryDocRef = doc(db, collectionName, inventoryId)

  const docSnap = await getDoc(inventoryDocRef)

  if (!docSnap.exists()) {
    console.error('No such document!')
    return
  }

  const inventoryDocData = docSnap.data()

  const photos = {
    ...inventoryDocData.photos,
    [position]: photoName,
  }

  await updateDoc(inventoryDocRef, {
    photos: photos,
  })
}
