import { API_URL } from 'core/config/index'
/* eslint-disable */

// TODO: @adamsiwiec1 i will type this

const RouteName = 'bank'

const getJwtToken = (): string | null => {
  return localStorage.getItem('token')
}

const getHeaders = (): HeadersInit => {
  const jwtToken = getJwtToken()
  return {
    'Content-Type': 'application/json',
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  }
}

export const getBankApplications = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-applications`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

export const getViewableDocument = async (document_id: string): Promise<string> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-viewable-document`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_id }),
  })

  if (!response.ok) {
    return ''
  }

  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

export const approveApplication = async (application_id: number, bank_decision_notes: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/approve-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, bank_decision_notes }),
  })

  const data = await response.json()
  return data
}

export const denyApplication = async (application_id: number, bank_decision_notes: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/deny-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, bank_decision_notes }),
  })

  const data = await response.json()
  return data
}

export const viewedApplication = async (application_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/application-viewed`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id }),
  })

  const data = await response.json()
  return data
}

export const getApplicationDocuments = async (document_id: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-application-documents`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_id }),
  })

  const data = await response.json()
  return data
}

interface BankFunctions {
  getBankApplications: () => Promise<any>
  getViewableDocument: (document_id: string) => Promise<string>
  approveApplication: (application_id: number, bank_decision_notes: string) => Promise<any>
  denyApplication: (application_id: number, bank_decision_notes: string) => Promise<any>
  viewedApplication: (application_id: number) => Promise<any>
  getApplicationDocuments: (document_id: string) => Promise<any>
}

export const bankFunctions: BankFunctions = {
  getBankApplications: getBankApplications,
  getViewableDocument: getViewableDocument,
  approveApplication: approveApplication,
  denyApplication: denyApplication,
  viewedApplication: viewedApplication,
  getApplicationDocuments: getApplicationDocuments,
}
