import { getClientApplications } from 'core/api/application/client'
import { useQuery } from 'react-query'

const useApplications = () => {
  return useQuery(['applications'], () => getClientApplications(), {
    onError: error => {
      console.error('Error fetching applications', error)
    },
  })
}

export default useApplications
