import React, { useEffect, useState } from 'react'

import { Typography, Box, Divider, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import ViewAffiliateDetailsDialog from './components/ViewAffiliateDetailsDialog'
import ViewLeadDetailsDialog from './components/ViewLeadDetailsDialog'
import ViewW9Form from './components/ViewW9Form'
import { PageTitle } from 'components/PageTitle'
import { adminFunctions } from 'core/api/application/admin'

const AffiliateManagement = () => {
  const [affiliates, setAffiliates] = useState<FlatLead[]>([])

  const [leads, setLeads] = useState<FlatLead[]>([])

  const fetchAffiliateShit = async () => {
    const data = await adminFunctions.getAffiliatesLeads()
    if (data && data.status === 'ok') {
      const affiliates = data.affiliates.map((affiliate: Affiliate) => ({
        id: affiliate.affiliate_info.id,
        name: affiliate.affiliate_info.first_name + ' ' + affiliate.affiliate_info.last_name,
        email: affiliate.affiliate_info.email,
        company_name: affiliate.affiliate_info.company_name,
        entity_structure: affiliate.affiliate_info.entity_structure,
        phone: affiliate.affiliate_info.phone,
        city_state: affiliate.affiliate_info.city + ', ' + affiliate.affiliate_info.state,
        mailing_address: affiliate.affiliate_info.mailing_address,
        tshirt_size: affiliate.affiliate_info.tshirt_size,
        years_in_business: affiliate.affiliate_info.years_in_business,
        status: affiliate.affiliate_info.status,
        w9_document_uuid: affiliate.affiliate_info.w9_document_uuid,
      }))

      setAffiliates(affiliates)

      const flattenedLeads = data.affiliates.flatMap((affiliate: Affiliate) =>
        affiliate.affiliate_leads.map(
          (lead: Lead): FlatLead => ({
            ...lead,
            affiliateId: affiliate.affiliate_info.id,
            affiliateCompanyName: affiliate.affiliate_info.company_name,
            affiliateName: affiliate.affiliate_info.first_name + ' ' + affiliate.affiliate_info.last_name,
            affiliateEmail: affiliate.affiliate_info.email,
          }),
        ),
      )
      setLeads(flattenedLeads)
    }
  }

  useEffect(() => {
    fetchAffiliateShit()
  }, [])

  const affiliateColumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'company_name', headerName: 'Company Name', width: 200 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 500,
      renderCell: (params: { row: AffiliateInfo }) => (
        <strong>
          <Button
            onClick={() => openViewAffiliateDialog(params.row)}
            variant='contained'
            color='primary'
            size='small'
          >
            View Affiliate Details
          </Button>

          {params.row.w9_document_uuid && (
            <Button
              onClick={() => openViewW9FormDialog(params.row)}
              variant='contained'
              color='primary'
              size='small'
              sx={{ marginLeft: 2 }}
            >
              View W9 Form
            </Button>
          )}

          {params.row.status === 'Pending' && (
            <>
              <Button
                onClick={() => handleApproveAffiliate(params.row)}
                variant='contained'
                color='success'
                size='small'
                sx={{ marginLeft: 2 }}
              >
                Approve
              </Button>

              <Button
                onClick={() => handleDenyAffiliate(params.row)}
                variant='contained'
                color='error'
                size='small'
                sx={{ marginLeft: 2 }}
              >
                Deny
              </Button>
            </>
          )}

          {params.row.status === 'Denied' && (
            <Button
              onClick={() => handleApproveAffiliate(params.row)}
              variant='contained'
              color='success'
              size='small'
              sx={{ marginLeft: 2 }}
            >
              Approve
            </Button>
          )}

          {params.row.status === 'Approved' && (
            <Button
              onClick={() => handleSuspendAffiliate(params.row)}
              variant='contained'
              color='error'
              size='small'
              sx={{ marginLeft: 2 }}
            >
              Suspend
            </Button>
          )}

          {params.row.status === 'Suspend' && (
            <Button
              onClick={() => handleRemoveSuspendAffiliate(params.row)}
              variant='contained'
              color='primary'
              size='small'
              sx={{ marginLeft: 2 }}
            >
              Remove Suspension
            </Button>
          )}
        </strong>
      ),
    },
  ]

  const affiliateLeadColumns = [
    { field: 'name', headerName: 'Lead Name', width: 150 },
    { field: 'email', headerName: 'Lead Email', width: 200 },
    { field: 'phone', headerName: 'Lead Phone', width: 150 },
    { field: 'city', headerName: 'City', width: 130 },
    { field: 'state', headerName: 'State', width: 130 },
    { field: 'status', headerName: 'Status', width: 130 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params: { row: Lead }) => (
        <strong>
          <Button onClick={() => openViewLeadDialog(params.row)} variant='contained' color='primary' size='small'>
            View Lead Details
          </Button>
          {params.row.status === 'Submitted' && (
            <>
              <Button
                onClick={() => handleApproveLead(params.row)}
                variant='contained'
                color='success'
                size='small'
                sx={{ marginLeft: 2 }}
              >
                Approve
              </Button>

              <Button
                onClick={() => handleDenyLead(params.row)}
                variant='contained'
                color='error'
                size='small'
                sx={{ marginLeft: 2 }}
              >
                Deny
              </Button>
            </>
          )}

          {params.row.status === 'Approved' && (
            <Button
              onClick={() => handleMarkConvertedLead(params.row)}
              variant='contained'
              color='primary'
              size='small'
              sx={{ marginLeft: 2 }}
            >
              Mark Converted
            </Button>
          )}
        </strong>
      ),
    },
  ]

  const handleApproveAffiliate = async (affiliate: AffiliateInfo) => {
    await adminFunctions.handleAffiliateStatusChange(affiliate.id, 'Approved')
    await fetchAffiliateShit()
  }

  const handleDenyAffiliate = async (affiliate: AffiliateInfo) => {
    await adminFunctions.handleAffiliateStatusChange(affiliate.id, 'Denied')
    await fetchAffiliateShit()
  }

  const handleSuspendAffiliate = async (affiliate: AffiliateInfo) => {
    await adminFunctions.handleAffiliateStatusChange(affiliate.id, 'Suspend')
    await fetchAffiliateShit()
  }

  const handleRemoveSuspendAffiliate = async (affiliate: AffiliateInfo) => {
    await adminFunctions.handleAffiliateStatusChange(affiliate.id, 'Approved')
    await fetchAffiliateShit()
  }

  const handleApproveLead = async (lead: Lead) => {
    if (!lead.id) {
      console.error('Lead ID not found', lead)
      return
    }
    await adminFunctions.handleLeadStatusChange(lead.id, 'Approved')
    await fetchAffiliateShit()
  }

  const handleDenyLead = async (lead: Lead) => {
    if (!lead.id) {
      console.error('Lead ID not found', lead)
      return
    }

    await adminFunctions.handleLeadStatusChange(lead.id, 'Denied')
    await fetchAffiliateShit()
  }

  const handleMarkConvertedLead = async (lead: Lead) => {
    if (!lead.id) {
      console.error('Lead ID not found', lead)
      return
    }

    await adminFunctions.handleLeadStatusChange(lead.id, 'Converted')
    await fetchAffiliateShit()
  }

  const [isViewAffiliateDialogOpen, setIsViewAffiliateDialogOpen] = useState(false)
  const [currentAffiliate, setCurrentAffiliate] = useState<AffiliateInfo | null>(null)

  const openViewAffiliateDialog = (affiliate: AffiliateInfo) => {
    setCurrentAffiliate(affiliate)
    setIsViewAffiliateDialogOpen(true)
  }

  const closeViewAffiliateDialog = () => {
    setIsViewAffiliateDialogOpen(false)
  }

  const [isViewLeadDialogOpen, setIsViewLeadDialogOpen] = useState(false)
  const [currentLead, setCurrentLead] = useState<Lead | null>(null)

  const openViewLeadDialog = (lead: Lead) => {
    setCurrentLead(lead)
    setIsViewLeadDialogOpen(true)
  }

  const closeViewLeadDialog = () => {
    setIsViewLeadDialogOpen(false)
  }

  const [isViewW9FormDialogOpen, setIsViewW9FormDialogOpen] = useState(false)
  const [currentW9Form, setCurrentW9Form] = useState<string | null>(null)

  useEffect(() => {
    // Any action you want to perform when currentW9Form changes
  }, [currentW9Form])

  const openViewW9FormDialog = (row: { w9_document_uuid: string }) => {
    setCurrentW9Form(row.w9_document_uuid)
    setIsViewW9FormDialogOpen(true)
  }

  const closeViewW9FormDialog = () => {
    setIsViewW9FormDialogOpen(false)
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
      {currentW9Form && (
        <ViewW9Form
          isDialogOpen={isViewW9FormDialogOpen}
          handleCloseDialog={closeViewW9FormDialog}
          documentUUID={currentW9Form}
        />
      )}

      {currentAffiliate && (
        <ViewAffiliateDetailsDialog
          open={isViewAffiliateDialogOpen}
          onClose={closeViewAffiliateDialog}
          affiliate={currentAffiliate}
        />
      )}

      {currentLead && (
        <ViewLeadDetailsDialog open={isViewLeadDialogOpen} onClose={closeViewLeadDialog} lead={currentLead} />
      )}

      <PageTitle
        title='Affiliate Management'
        subtitle='The table below shows all the affiliates info and actions associates with affiliates.'
        bulletPoints={[
          'Affiliate Management allows you to approve affiliate applications or suspend current affiliate',
          'Affiliate Management also allows you to view current affiliate details or their W9 Form.',
          'Affiliate Leads allows you to view, approve or deny leads submitted by affiliates.',
        ]}
      />

      <Box sx={{ flex: 1, width: '100%', minHeight: 300 }}>
        <DataGrid
          // @ts-expect-error this is a hack to get rid of the error bad practice
          rows={affiliates}
          columns={affiliateColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          getRowId={row => row.id}
          rowHeight={50}
        />
      </Box>

      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

      <Typography variant='h4' gutterBottom>
        Affiliate Leads
      </Typography>
      <Typography component='p' gutterBottom>
        The table below shows all the affiliates info and actions associates with affiliates.
      </Typography>

      <Box sx={{ flex: 1, width: '100%', minHeight: 300 }}>
        <DataGrid
          rows={leads}
          columns={affiliateLeadColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          rowHeight={50}
          pageSizeOptions={[20]}
          // @ts-expect-error this is a hack to get rid of the error bad practice
          getRowId={row => row.id}
        />
      </Box>
    </Box>
  )
}

export default AffiliateManagement
