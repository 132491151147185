import React, { useEffect, useMemo, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Menu, MenuItem } from '@mui/material'
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridCellParams,
  GridSlotsComponent,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useFirestoreCollectionMutation, useFirestoreQuery } from '@react-query-firebase/firestore'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'core/config/firebase'
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  updateDoc,
  where,
  WhereFilterOp,
} from 'firebase/firestore'
import { useMutation } from 'react-query'
import DataGridToolBar, { SelectionButtonT } from './DataGridToolBar'
import { StripedDataGrid } from './StyledDataGrids'
import DeleteConfirmationDialog from '../DeleteConfirmationDialog'
import FirestoreDocViewer from '../FirestoreDocViewer'
import { getTextWidth } from 'core/utils/inventoryUtils'

const calculateMinWidth = (rows: GridRowModel[], field: string): number => {
  const headerWidth = getTextWidth(field)
  const contentWidth = Math.max(...rows.map(row => getTextWidth(String(row[field]))))
  return Math.min(Math.max(headerWidth, contentWidth) + 24, 300) // Add padding and set max width
}

export type ColumnFunctionsType = {
  [key: string]: (id: string, value: string) => void | Promise<void>
}

interface DataGridFirestoreCRUDProps {
  collectionName: string
  columns: GridColDef[]
  hiddenColumns?: string[]
  filters?: [string, WhereFilterOp, string | number][]
  orderByColumn?: string
  staticValues?: string[]
  rowSize?: number
  search?: boolean
  create?: boolean
  editable: boolean
  doubleClickEdit?: boolean
  deleteable?: boolean
  multiDeleteable?: boolean
  onDoubleClick?: (id: string) => void
  onDeleteClick?: (id: string) => void
  customToolbarSelectionButtons?: SelectionButtonT[]
  customColumnSaveFunctions?: ColumnFunctionsType
  viewOnlyMode?: boolean
  canShowDocViewer?: boolean
}

const DataGridFirestoreCRUD: React.FC<DataGridFirestoreCRUDProps> = ({
  collectionName,
  hiddenColumns,
  columns,
  filters,
  orderByColumn,
  search = true,
  create = true,
  editable = true,
  deleteable = true,
  multiDeleteable = false,
  onDoubleClick,
  onDeleteClick,
  customToolbarSelectionButtons,
  customColumnSaveFunctions,
  viewOnlyMode,
  canShowDocViewer = false,
}) => {
  const [openDocViewer, setOpenDocViewer] = useState<boolean>(false)
  const [docViewerId, setDocViewerId] = useState<GridRowId | null>(null)
  const collectionRef = collection(db, collectionName)
  const { showSnackbar } = useSnackbar()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    Object.fromEntries((hiddenColumns ?? []).map(columnName => [columnName, false])),
  )
  const [columnDefs, setColumnDefs] = useState<GridColDef[]>(columns)
  const apiRef = useGridApiRef()

  const generateNewRow = useMemo(() => {
    const newRow: DocumentData = {}
    columns.forEach(column => {
      newRow[column.field] = ''
    })
    return newRow
  }, [columns])

  const queryKey = [collectionName, filters, orderByColumn]
  const getFinalRef = useMemo(() => {
    const queryConstraints: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] = []
    filters && filters.forEach(filter => queryConstraints.push(where(...filter)))
    orderByColumn && queryConstraints.push(orderBy(orderByColumn))
    const finalRef = query(collectionRef, ...queryConstraints)
    return finalRef
  }, [collectionRef, filters, orderByColumn])

  const { data: rows, isLoading } = useFirestoreQuery(
    queryKey,
    getFinalRef,
    {
      subscribe: true,
    },
    {
      select: snapshot => {
        if (!snapshot || !snapshot.docs) return []
        return snapshot.docs.map(doc => {
          const data = doc.data()
          return { ...data, id: doc.id }
        })
      },
      onSuccess: () => {
        apiRef.current.autosizeColumns({
          columns: columns.map(column => column.field),
          includeHeaders: true,
          includeOutliers: true,
          expand: true,
        })
      },
      enabled: !!getFinalRef,
    },
  )

  // CREATE

  const handleCreateRow = () => {
    const newRow = generateNewRow
    addFirebaseDocument(newRow)
  }

  const { mutate: addFirebaseDocument } = useFirestoreCollectionMutation(collectionRef, {
    onError: error => {
      showSnackbar('Adding row failed', 'error')
      console.error(error)
    },
  })

  // UPDATE

  const getUpdatedFirebaseData = async ({ id, ...data }: GridRowModel) => {
    const docRef = doc(collectionRef, id.toString())
    const existingDoc = await getDoc(docRef)
    const existingData: DocumentData = existingDoc.exists() ? existingDoc.data() : {}
    Object.keys(data).forEach(columnName => {
      if (data[columnName] === undefined) {
        delete data[columnName]
      } else if (
        data[columnName] !== existingData[columnName] &&
        customColumnSaveFunctions &&
        customColumnSaveFunctions[columnName]
      ) {
        customColumnSaveFunctions[columnName](id.toString(), data[columnName])
      }
    })
    return { ...data }
  }

  const { mutate: handleUpdateRow } = useMutation(
    async (row: GridRowModel) => {
      const updateData = await getUpdatedFirebaseData(row)
      const docRef = doc(collectionRef, row.id.toString())
      await updateDoc(docRef, updateData)
      return { ...updateData, id: row.id }
    },
    {
      onError: error => {
        showSnackbar('Updating row failed', 'error')
        console.error('Error updating project in Firestore:', error)
      },
    },
  )

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // DELETE

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [deleteMessage, setConfirmDeleteMessage] = useState<string>('')

  // Checkbox selected rows
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])

  const { mutate: deleteFirebaseDocs } = useMutation(
    async () => {
      for (const id of selectedRows) {
        if (onDeleteClick) {
          onDeleteClick(id.toString())
        }
        await deleteDoc(doc(db, collectionName, id.toString()))
      }
    },
    {
      onError: error => {
        showSnackbar(`Deleting row${selectedRows.length === 1 ? '' : 's'} failed`, 'error')
        console.error(`Error deleting document${selectedRows.length === 1 ? '' : 's'}:`, error)
      },
      onSettled: () => {
        setOpenDeleteConfirmation(false)
      },
      onSuccess: () => {
        setSelectedRows([])
      },
    },
  )

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false)
  }

  const handleCellDoubleClick: GridEventListener<'cellDoubleClick'> = params => {
    onDoubleClick && onDoubleClick(String(params.id))
  }

  const handleDeleteRows = (selectedRowIds: GridRowId[]) => {
    setConfirmDeleteMessage(
      `Are you sure you want to delete ${selectedRowIds.length} row${selectedRowIds.length === 1 ? '' : 's'}?`,
    )
    setOpenDeleteConfirmation(true)
  }

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel)
  }

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
    rowId: GridRowId
  } | null>(null)

  useEffect(() => {
    const handleCellMouseUp = (params: GridCellParams, event: React.MouseEvent) => {
      if (event.button === 2) {
        if (canShowDocViewer) {
          event.preventDefault()
          setContextMenu(
            contextMenu === null
              ? {
                  mouseX: event.clientX - 2,
                  mouseY: event.clientY - 4,
                  rowId: params.id,
                }
              : null,
          )
        } else {
          setContextMenu(null)
        }
      }
    }

    const unsubscribe = apiRef.current.subscribeEvent('cellMouseUp', handleCellMouseUp)
    return () => {
      unsubscribe()
    }
  }, [apiRef, contextMenu, canShowDocViewer])

  useEffect(() => {
    if (rows) {
      const newColumnDefs = columns.map(column => {
        return {
          ...column,
          editable: editable && column.editable !== false,
          minWidth: column.minWidth || calculateMinWidth(rows, column.field),
        }
      })
      setColumnDefs(newColumnDefs)
    }
  }, [rows, columns])

  const handleGridContextMenu = (event: React.MouseEvent) => {
    if (canShowDocViewer) {
      event.preventDefault()
    }
  }

  const handleShowFirestoreDoc = (id: GridRowId) => {
    if (canShowDocViewer) {
      setDocViewerId(id)
      setOpenDocViewer(true)
    } else {
      showSnackbar('Document viewer is disabled for your role', 'error')
    }
  }

  const handleCloseDocViewer = () => {
    setOpenDocViewer(false)
    setDocViewerId(null)
  }

  const handleSave = () => {
    const rowsInEditMode = apiRef.current
      .getAllRowIds()
      .filter(id => apiRef.current.getRowMode(id) === GridRowModes.Edit)
    rowsInEditMode.forEach(selectedId => {
      apiRef.current.stopRowEditMode({ id: selectedId })
    })
  }

  const renderDataGrid = useMemo(() => {
    return (
      <Box sx={{ height: '70vh', width: '100%' }} onContextMenu={handleGridContextMenu}>
        <DeleteConfirmationDialog
          open={openDeleteConfirmation}
          title='Confirm Delete'
          content={deleteMessage}
          onConfirm={() => deleteFirebaseDocs()}
          onCancel={handleCancelDelete}
        />

        <StripedDataGrid
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          apiRef={apiRef}
          checkboxSelection={multiDeleteable}
          onCellDoubleClick={handleCellDoubleClick}
          rows={isLoading ? [] : rows}
          autoHeight
          columns={columnDefs}
          editMode='row'
          onRowEditStop={handleRowEditStop}
          isCellEditable={() => !viewOnlyMode}
          processRowUpdate={updatedRow => {
            handleUpdateRow(updatedRow)
            return updatedRow
          }}
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          onProcessRowUpdateError={error => console.error('Update Row error:', error)}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={newSelectionModel => {
            setSelectedRows([...newSelectionModel])
          }}
          hideFooterSelectedRowCount
          sx={{
            '& .row-to-delete': {
              backgroundColor: '#ffcccc',
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.8rem',
              padding: '12px',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '0.8rem',
              padding: '12px',
            },
            '& .MuiDataGrid-columnHeader': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          }}
          slots={{
            toolbar: DataGridToolBar as GridSlotsComponent['toolbar'],
          }}
          slotProps={{
            toolbar: {
              collectionName,
              onDeleteRows: handleDeleteRows,
              onCreateRow: handleCreateRow,
              onSaveRows: handleSave,
              search,
              create,
              showQuickFilter: true,
              deleteable: deleteable,
              multiDeleteable: multiDeleteable,
              selectedRows: selectedRows,
              customToolbarSelectionButtons,
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
        {canShowDocViewer && contextMenu !== null && (
          <Menu
            open={contextMenu !== null}
            onClose={() => setContextMenu(null)}
            anchorReference='anchorPosition'
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            onMouseLeave={() => setContextMenu(null)}
          >
            <MenuItem
              onClick={() => {
                handleShowFirestoreDoc(contextMenu.rowId)
                setContextMenu(null)
              }}
            >
              Show Firestore Doc
            </MenuItem>
          </Menu>
        )}
        {canShowDocViewer && (
          <Dialog open={openDocViewer} onClose={handleCloseDocViewer} maxWidth='md' fullWidth>
            <DialogTitle>Firestore Document - ID: {docViewerId}</DialogTitle>
            <DialogContent>
              {docViewerId && <FirestoreDocViewer collectionName={collectionName} docId={docViewerId.toString()} />}
            </DialogContent>
          </Dialog>
        )}
      </Box>
    )
  }, [
    rows,
    selectedRows,
    openDeleteConfirmation,
    deleteMessage,
    contextMenu,
    columnVisibilityModel,
    columnDefs,
    apiRef,
    handleCellDoubleClick,
    isLoading,
    multiDeleteable,
    viewOnlyMode,
    deleteFirebaseDocs,
    handleDeleteRows,
    handleCreateRow,
    handleSave,
    collectionName,
    customToolbarSelectionButtons,
    deleteable,
    create,
    search,
    openDocViewer,
    docViewerId,
    canShowDocViewer,
  ])

  return <>{renderDataGrid}</>
}

export default DataGridFirestoreCRUD
