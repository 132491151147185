import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormField } from 'core/types/forms'
import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react'

interface CustomFormProps {
  fields: FormField[]
  submitText: string
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  onCancel: () => void
  initialValues?: { [key: string]: string | number | boolean | unknown }
  subTitles?: { [beforeFieldNumber: number]: string }
}

const CustomForm: React.FC<CustomFormProps> = ({
  fields,
  onSubmit,
  subTitles,
  submitText,
  onCancel,
  initialValues,
}) => {
  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({})

  const getSelectValues = useCallback((selectField: FormField) => {
    if (typeof selectField.selectValues === 'function') {
      return selectField.selectValues(values)
    }
    return selectField.selectValues || []
  }, [values])

  useEffect(() => {
    const existingValues = Object.assign(
      {},
      ...fields.map(field => ({ [field.name]: initialValues?.[field.name] ?? '' })),
    )
    setValues(existingValues)
    setTouched({})
  }, [fields, initialValues])

  const fieldIsRequired = useCallback((field: FormField) => {
    if (field.type === 'select' && !getSelectValues(field)) {
      return false
    }
    return field?.required
  }, [getSelectValues])

  const validateFieldValue = useCallback((field: FormField, value: string | boolean | number) => {
    if (!value && value !== 0) return fieldIsRequired(field) ? 'Field is required' : null
    if (field?.validation?.validateValue && !field.validation.validateValue(value)) {
      return field.validation.errorText || 'Invalid value'
    }
    return null
  }, [fieldIsRequired])

  const handleChange = useCallback((field: FormField, value: string | boolean | number) => {
    setValues(prev => ({ ...prev, [field.name]: value }))
    setTouched(prev => ({ ...prev, [field.name]: true }))
  }, [])

  // Debounced validation
  useEffect(() => {
    const debouncedValidation = setTimeout(() => {
      const newErrors: { [key: string]: string } = {}
      Object.keys(touched).forEach(fieldName => {
        const field = fields.find(f => f.name === fieldName)
        if (field) {
          const errorText = validateFieldValue(field, values[fieldName])
          if (errorText) {
            newErrors[fieldName] = errorText
          }
        }
      })
      setErrors(newErrors)
    }, 200) // 200ms debounce

    return () => clearTimeout(debouncedValidation)
  }, [values, touched, fields, validateFieldValue])

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    // Mark all fields as touched
    const allTouched = Object.fromEntries(fields.map(field => [field.name, true]))
    setTouched(allTouched)

    const newErrors: { [key: string]: string } = {}
    fields.forEach(field => {
      const errorText = validateFieldValue(field, values[field.name])
      if (errorText) {
        newErrors[field.name] = errorText
      }
    })

    if (Object.keys(newErrors).length === 0) {
      onSubmit(values)
    } else {
      setErrors(newErrors)
    }
  }, [fields, values, validateFieldValue, onSubmit])

  const renderField = useCallback((field: FormField) => {
    if (field.viewOnly) {
      return (
        <Box>
          <Typography variant='h5' fontWeight='medium'>{field.label}</Typography>
          <Typography fontWeight='light'>{values[field.name]}</Typography>
        </Box>
      )
    }

    const commonProps = {
      required: field.required,
      fullWidth: true,
      id: field.name,
      name: field.name,
      label: field.showTitle ? null : field.label,
      value: values[field.name] ?? '',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => 
        handleChange(field, field.type === 'check' ? event.target.checked : event.target.value),
      error: !!errors[field.name],
      helperText: errors[field.name],
    }

    switch (field.type) {
      case 'text':
        return (
          <TextField
            {...commonProps}
            {...(field.textFieldProps || {})}
            InputProps={field.button ? {
              endAdornment: (
                <InputAdornment position='end'>
                  {field.button(values[field.name])}
                </InputAdornment>
              ),
            } : undefined}
          />
        )
      case 'select':
        return (
          <FormControl error={!!errors[field.name]} fullWidth>
            {!field.showTitle && <InputLabel id={field.name}>{field.label}</InputLabel>}
            <Select
              {...commonProps}
              onChange={event => handleChange(field, event.target.value)}
            >
              {getSelectValues(field)?.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
          </FormControl>
        )
      case 'check':
        return (
          <FormControl error={!!errors[field.name]}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(values[field.name])}
                  onChange={event => handleChange(field, event.target.checked)}
                  name={field.name}
                />
              }
              label={field.label}
            />
            {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
          </FormControl>
        )
      case 'action':
        return field.actionElement?.()
      default:
        return null
    }
  }, [values, errors, handleChange, getSelectValues])

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit}
      height={{ xs: '100%', md: 'auto' }}
      width={{ xs: '100%', md: 'auto' }}
      overflow='hidden'
    >
      <Box flex={1} height='calc(100% - 64px)' sx={{ overflowY: 'auto' }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            const isFullWidth = field.fullWidth || index === 1
            return (
              <Fragment key={`${subTitles?.[index] ? `subtitle-${index}-` : ''}${field.name}`}>
                {subTitles?.[index] && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      {subTitles[index]}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={isFullWidth ? 12 : 6}>
                  <Box>
                    {field.showTitle && (
                      <InputLabel
                        sx={{
                          color: 'black',
                          mb: 1,
                          fontWeight: 'medium',
                        }}
                      >
                        {field.label}
                      </InputLabel>
                    )}
                    {renderField(field)}
                  </Box>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        alignItems='center'
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '64px',
        }}
      >
        <Button type='button' color='secondary' onClick={onCancel}>
          Cancel
        </Button>
        <Button type='submit' variant='contained'>
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}

  export default React.memo(CustomForm)
