import React, { useState } from 'react'
import { Typography, Box, Collapse, List, ListItem, useTheme } from '@mui/material'
import { FiberManualRecordSharp } from '@mui/icons-material'

interface PageTitleProps {
  title: string
  subtitle?: string
  bulletPoints?: string[]
  action?: React.ReactNode
}

export const PageTitle = (props: PageTitleProps) => {
  const theme = useTheme()
  const [showBullets, setShowBullets] = useState(false)

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant='h4' component='h1'>
          {props.title}
        </Typography>
        {props.action}
      </Box>
      {props.subtitle && (
        <Typography variant='subtitle1' gutterBottom>
          {props.subtitle}
          {props.bulletPoints && (
            <>
              {' '}
              <Typography
                variant='subtitle1'
                component='span'
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: { sm: 12, xs: 10 },
                }}
                onClick={() => setShowBullets(!showBullets)}
              >
                {showBullets ? 'Show Less' : 'Show More'}
              </Typography>
              <Collapse in={showBullets}>
                <List>
                  {props.bulletPoints.map((point, index) => (
                    <ListItem key={index} sx={{ padding: 0.25, marginLeft: 2 }}>
                      <FiberManualRecordSharp
                        fontSize='inherit'
                        sx={{
                          color: theme.palette.primary.main,
                          marginRight: 1,
                        }}
                      />
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: { sm: 14, xs: 12 },
                        }}
                      >
                        {point}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </Typography>
      )}
    </Box>
  )
}

