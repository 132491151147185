import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, deleteUser as deleteAuthUser } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from "core/config/firebase";
import { useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useSnackbar } from 'contexts/snackBarContext';
import { query, where, orderBy } from 'firebase/firestore';

interface HistoryEntry {
  id: string;
  documentId: string;
  timestamp: any;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
}

export const useManageUsers = () => {
  const queryClient = useQueryClient();
  const auth = getAuth();
  const storage = getStorage();
  const { showSnackbar } = useSnackbar();

  // Fetch all users
  const { data: users, isLoading, isError } = useQuery<UserInfoType[], Error>({
    queryKey: ["users"],
    queryFn: async () => {
      const usersRef = collection(db, "users");
      const snapshot = await getDocs(usersRef);
      return snapshot.docs.map(doc => ({ firestoreDocId: doc.id, ...doc.data() } as UserInfoType));
    },
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Create user
  const createUserMutation = useMutation({
    mutationFn: async (userData: Omit<UserInfoType, 'id'>) => {
      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, 'temporaryPassword');
      const user = userCredential.user;
      await setDoc(doc(db, `users/${user.uid}`), userData);
      await sendEmailVerification(user);
      return user.uid;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: async ({ id, ...data }: { id: string; [key: string]: any }) => {
      if (!id) throw new Error("User ID is required");
      
      const { isRevert, ...updateData } = data;
      
      await updateDoc(doc(db, `users/${id}`), {
        ...updateData,
        modified_by: auth.currentUser?.email,
      });

      return { id, data: updateData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["allUserHistories"] });
      showSnackbar('User updated successfully', 'success');
    },
    onError: (error) => {
      console.error('Error updating user:', error);
      showSnackbar('Error updating user', 'error');
    },
  });

  // Delete user
  const deleteUserMutation = useMutation({
    mutationFn: async (userId: string) => {
      const user = auth.currentUser;
      if (user) {
        await deleteAuthUser(user);
      }
      await deleteDoc(doc(db, `users/${userId}`));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  // Upload profile picture
  const uploadProfilePictureMutation = useMutation({
    mutationFn: async ({ userId, file }: { userId: string; file: File }) => {
      const storageRef = ref(storage, `users/${userId}/profilePicture`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      await updateDoc(doc(db, `users/${userId}`), { profilePicture: downloadURL });
      return downloadURL;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  // Get verified status
  const getVerifiedStatus = async (userId: string): Promise<boolean> => {
    const functions = getFunctions();
    const getVerifiedStatus = httpsCallable(functions, 'getVerifiedStatus');
    const result = await getVerifiedStatus({ userId });
    return (result.data as { isVerified: boolean }).isVerified;
  };

  // Resend verification email
  const resendVerificationEmailMutation = useMutation({
    mutationFn: async (email: string) => {
      const functions = getFunctions();
      const resendVerificationEmail = httpsCallable(functions, 'resendVerificationEmail');
      await resendVerificationEmail({ email });
    },
  });

  // Handle cell value change
  const handleCellValueChange = async (userId: string, field: string, newValue: any) => {
    try {
      await updateDoc(doc(db, `users/${userId}`), {
        [field]: newValue,
        modified_by: auth.currentUser?.email,
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      showSnackbar('Changes saved successfully', 'success');
    } catch (error) {
      console.error('Failed to update user:', error);
      showSnackbar('Error updating user', 'error');
    }
  };

  // Fetch all user histories
  const fetchAllUserHistories = () => {
    return useQuery<HistoryEntry[]>({
      queryKey: ["allUserHistories"],
      queryFn: async () => {
        try {
          const historyRef = collection(db, 'document_history');
          const q = query(
            historyRef,
            where('collection', '==', 'users'),
            orderBy('timestamp', 'desc')
          );
          const snapshot = await getDocs(q);
          return snapshot.docs.map(doc => ({
            ...doc.data(),
            documentId: doc.data().docId,
            id: doc.id // Keep track of the history document ID
          } as HistoryEntry));
        } catch (error) {
          console.error('Error fetching user history:', error);
          throw error;
        }
      },
      staleTime: 5 * 60 * 1000,
    });
  };

  // Add this new mutation for restoring users
  const restoreUserMutation = useMutation({
    mutationFn: async (userData: any) => {
      const { id, ...data } = userData;
      await setDoc(doc(db, `users/${id}`), {
        ...data,
        modified_by: auth.currentUser?.email
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      showSnackbar('User restored successfully', 'success');
    },
    onError: (error) => {
      console.error('Error restoring user:', error);
      showSnackbar('Error restoring user', 'error');
    },
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" || change.type === "removed") {
          queryClient.invalidateQueries({ queryKey: ["allUserHistories"] });
        }
      });
    });

    return () => unsubscribe();
  }, []);

  return {
    users,
    isLoading,
    isError,
    createUser: createUserMutation.mutate,
    updateUser: updateUserMutation.mutate,
    deleteUser: deleteUserMutation.mutate,
    restoreUser: restoreUserMutation.mutate,
    uploadProfilePicture: uploadProfilePictureMutation.mutate,
    getVerifiedStatus,
    resendVerificationEmail: resendVerificationEmailMutation.mutate,
    handleCellValueChange,
    fetchAllUserHistories,
  };
};
