import { Box, Grid, List, ListItem } from '@mui/material'
import { ApplicationStatus, ClientApplication } from 'core/types/applications'
import useApplications from 'hooks/useApplications'
import React from 'react'
import ApplicationCard from './components/cards/ApplicationCard'
import StatusSummary from './components/cards/SummaryCard'
import ApplicationDialog from './components/components/ApplicationDialog'

interface ApplicationBoardProps {
  statusFilter: ApplicationStatus | 'All'
  view: 'list' | 'card'
}

const ApplicationBoard: React.FC<ApplicationBoardProps> = ({ statusFilter, view }) => {
  const [financeDialogOpen, setFinanceDialogOpen] = React.useState(false)
  const [selectedApplication, setSelectedApplication] = React.useState<ClientApplication | null>(null)

  const { data: applications} = useApplications()

  const handleDraftClick = (draftApplication: ClientApplication) => {
    setFinanceDialogOpen(true)
    setSelectedApplication(draftApplication)
  }

  return (
    <Box>
      {applications && <StatusSummary applications={applications} />}
      <Box>
        {view === 'card' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <Grid container spacing={2}>
              {applications &&
                applications
                  .filter(application => application.status === statusFilter || statusFilter === 'All')
                  .map(application => (
                    <Grid key={application.application_id} item xs={12} sm={6} md={4} lg={3}>
                      <ApplicationCard
                        application={application}
                        onClick={() => handleDraftClick(application)}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Box>
        )}
        {view === 'list' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <List>
              {applications &&
                applications
                  .filter(application => application.status === statusFilter || statusFilter === 'All')
                  .map(application => (<ListItem key={application.application_id}/>))}
            </List>
          </Box>
        )}
      </Box>
      {selectedApplication && (
        <ApplicationDialog
          application={selectedApplication}
          open={financeDialogOpen}
          onClose={() => setFinanceDialogOpen(false)}
        />
      )}
    </Box>
  )
}

export default ApplicationBoard
