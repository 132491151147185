import StarBorderIcon from '@mui/icons-material/PhotoTwoTone'
import { Box, Button, IconButton, ImageList, ImageListItem, ImageListItemBar, MenuItem, Select, Typography } from "@mui/material"
import { updatePhotoPosition } from 'core/api/inventory/updatePhotoPosition'
import React, { useState } from "react"


interface ViewPhotosTabProps {
    collectionName: string
    vehicleId: string
    photos: VehiclePhoto[] | undefined,
    onDeleteSelectedPhotos: (selectedPhotos: VehiclePhoto[]) => void
}

const vehiclePhotoPositons = ['main', 'right', 'left', 'back', 'interior', 'box']


const ViewPhotosTab: React.FC<ViewPhotosTabProps> = ({ collectionName, vehicleId: rowId, photos, onDeleteSelectedPhotos }) => {
  const [selectedPhotos, setSelectedPhotos] = useState<VehiclePhoto[]>([])

  const handleSelectPhoto = (photo: VehiclePhoto) => {
    if (selectedPhotos.includes(photo)) {
      setSelectedPhotos(prevState => prevState.filter(p => p.url !== photo.url))
      return
    }
    console.log('selected photos', selectedPhotos)
    setSelectedPhotos(prevState => [...prevState, photo])
  }

  const handleSetPhotoPosition = (newPosition: string, item: VehiclePhoto) => {
    updatePhotoPosition(collectionName, rowId, item.name, newPosition as PhotoPosition)
  }

  return (
    <>
      {selectedPhotos.length > 0 && (
        <>
          <Typography variant='h6' sx={{ mb: 1 }}>
            {selectedPhotos.length} Photos Selected
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <Button variant='outlined' sx={{ mr: 1 }}>
              Download Photos
            </Button>
            <Button variant='outlined' color='error' onClick={() => onDeleteSelectedPhotos(selectedPhotos)}>
              Delete Photos
            </Button>
          </Box>
        </>
      )}

      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {photos && photos.length > 0 ? (
          photos.map(photo => (
            <ImageListItem key={photo.url} onClick={() => handleSelectPhoto(photo)}>
              <img
                srcSet={`${photo.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${photo.url}?w=164&h=164&fit=crop&auto=format`}
                alt={photo.name}
                loading='lazy'
              />
              {selectedPhotos.includes(photo) && (
                <>
                  <ImageListItemBar
                    sx={{
                      background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    title={photo.position}
                    position='bottom'
                    actionIcon={
                      <IconButton sx={{ color: 'white' }} aria-label={`star ${photo.name}`}>
                        <StarBorderIcon />
                      </IconButton>
                    }
                    actionPosition='left'
                  />
                  <ImageListItemBar
                    title={photo.position}
                    position='top'
                    actionIcon={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                          value={photo.name}
                          onChange={e => handleSetPhotoPosition(e.target.value, photo)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Vehicle Position' }}
                          sx={{ color: 'white', '.MuiSelect-icon': { color: 'white' } }}
                        >
                          {vehiclePhotoPositons.map(position => (
                            <MenuItem key={position} value={position}>
                              {position}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    }
                  />
                </>
              )}
            </ImageListItem>
          ))
        ) : (
          <Typography>No photos for this vehicle</Typography>
        )}
      </ImageList>
    </>
  )
}

export default ViewPhotosTab;