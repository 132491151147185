import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from 'core/config/firebase'

interface CreateUserDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (userData: {
    id: string
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
  }) => void
}

const CreateUserDialog: React.FC<CreateUserDialogProps> = ({ open, onClose, onSuccess }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [userType, setUserType] = useState('transporter')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    userType: ''
  })

  const validateForm = () => {
    const newErrors = {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      userType: ''
    }

    if (!email) {
      newErrors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email format'
    }
    
    if (!firstName.trim()) newErrors.firstName = 'First name is required'
    if (!lastName.trim()) newErrors.lastName = 'Last name is required'
    
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required'
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be 10 digits'
    }
    
    if (!userType) newErrors.userType = 'User type is required'

    setErrors(newErrors)
    return !Object.values(newErrors).some(error => error)
  }

  const handleSubmit = async () => {
    if (!validateForm()) return

    try {
      setIsSubmitting(true)
      
      const newUserRef = doc(collection(db, 'users'))
      
      const userData = {
        id: newUserRef.id,
        email: email.toLowerCase(),
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        phone: phoneNumber.trim(),
        user_type: userType,
        active: false,
        email_verified: false,
        dateCreated: new Date().toISOString()
      }

      await setDoc(newUserRef, userData)

      onSuccess({
        id: newUserRef.id,
        email: userData.email,
        firstName: userData.first_name,
        lastName: userData.last_name,
        phoneNumber: userData.phone
      })
      
      handleClose()
    } catch (error) {
      console.error('Error creating user:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClose = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setUserType('Client')
    setErrors({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      userType: ''
    })
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          Create New User
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 2 }}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            required
          />
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
            fullWidth
            required
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
            fullWidth
            required
          />
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            fullWidth
            required
          />
          <FormControl fullWidth required error={!!errors.userType}>
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              onChange={(e) => setUserType(e.target.value as 'transporter' | 'client')}
              label="User Type"
            >
              <MenuItem value="transporter">Transporter</MenuItem>
              <MenuItem value="Client">Client</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Note: The created user will only be able to upload photos and will not have access to the portal. 
            If portal access is required, please create the user through the User Management page.
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} color="inherit" />
              Creating...
            </Box>
          ) : (
            'Create User'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUserDialog 