import React from 'react'

import { Typography } from '@mui/material'
import ApplicationTable from './components/ApplicationTable'

const BankHome = (): JSX.Element => {
  return (
    <>
      <Typography>Bank Home</Typography>
      <ApplicationTable />
    </>
  )
}

export default BankHome
