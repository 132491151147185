import { addDoc, collection, doc, setDoc } from '@firebase/firestore'
import ListIcon from '@mui/icons-material/List'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import { Box, Button, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { PageTitle } from 'components/PageTitle'
import { useAuth } from 'contexts/AuthContext'
import { getJwtToken } from 'core/api'
import { db } from 'core/config/firebase'
import { ApplicationStatus } from 'core/types/applications'
import { generateMD5Hash } from 'core/utils/generateMD5Hash'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import jwtDecode from 'jwt-decode'

import React, { useState } from 'react'
import ApplicationBoard from './ApplicationBoard'
import { useNavigate } from 'react-router-dom'

// make apply now button larger
// add bank names

const ClientHome = (): JSX.Element => {
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const [view, setView] = React.useState<'card' | 'list'>('card')
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus | 'All'>('All')
  const navigate = useNavigate()
  const statuses = ['All', 'Approved', 'Draft', 'Denied', 'Pending', 'Viewed', 'Ready', 'Corrections Needed']

  const jwtToken = getJwtToken()
  // @ts-expect-error - jwtDecode returns an object with a user_id property
  const uid = jwtDecode(jwtToken).user_id
  const startPhotoUpload = () => {
    console.log('start photo upload')

    const encodedToken = btoa(`${uid},${jwtToken}` as string)
    console.log(uid)
    const tokenMD5Hash = generateMD5Hash(encodedToken)

    const docRef = doc(collection(db, 'photolinks'), tokenMD5Hash)

    setDoc(docRef, {
      link: encodedToken,
    }).then(() => console.log('Document written with ID: ', docRef.id))

    addDoc(collection(db, 'messages'), {
      to: '5712911193',
      body: `http://192.168.50.226:3000/token/${tokenMD5Hash}`,
    }).then(() => console.log('Queued message for delivery!'))
  }


  return (
    <>
      <Typography variant='h3' gutterBottom>
        Hello, {userInfo?.first_name}! Welcome to the the Optic Truck Works Portal.
      </Typography>
      <Box display='flex' gap={2} p={1}>
        <Button variant='contained' color='secondary' size='large' onClick={() => navigate('/client/application')}>
          Apply now
        </Button>
        <Button onClick={startPhotoUpload}>Upload Photo</Button>
      </Box>
      <Box display='flex' flexDirection='column'>
        <PageTitle title='Here you can find all your financial applications' />
      </Box>
      <Box display='flex' alignItems='center' gap={2}>
        {!isMobile && (
          <ToggleButtonGroup value={view} exclusive onChange={() => setView('card')} aria-label='text alignment'>
            <ToggleButton value='list' aria-label='list view' disabled={true}>
              <ListIcon />
            </ToggleButton>
            <ToggleButton value='card' aria-label='card view'>
              <SpaceDashboardIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Select
          value={statusFilter || 'New'}
          onChange={e => setStatusFilter(e.target.value as ApplicationStatus)}
          fullWidth={isMobile}
          variant='outlined'
          size='small'
          style={{ backgroundColor: 'transparent', border: '1px solid #ccc' }}
        >
          {statuses.map(status => (
            <MenuItem key={status} value={status} style={{ fontSize: '0.875rem' }}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <ApplicationBoard statusFilter={statusFilter} view={view} />
      {/* <ApplicationTable /> */}
    </>
  )
}

export default ClientHome
