export const vehicleStatusOptions = ['Pending Available', 'Available', 'Pending Sale', 'Sold', 'Sold elsewhere']

export const vehicleTypeOptions = ['Step van', 'Cargo van', 'Cutaway', 'Box truck']

export const vehicleFuelOptions = ['Gas', 'Diesel']

export const getVehicleSizeOptions = (vehicleType: string) => {
  switch (vehicleType) {
    case 'Step van':
      return ['P500', 'P700', 'P900', 'P1000', 'P1100', 'P1200', 'Unknown']
    case 'Cargo van':
      return []
    case 'Cutaway':
      return ['10ft', '12ft', '14ft', '15ft', '16ft', '17ft', '18ft', 'Unknown']
    case 'Box truck':
      return ['12ft', '14ft', '16ft', '18ft', '20ft', '22ft', '24ft', '26ft', 'Unknown']
    default:
      return []
  }
}
