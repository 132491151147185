const appEnv = process.env.REACT_APP_ENV

let functionBase

if (!appEnv) {
  throw new Error('REACT_APP_ENV is not defined')
} else if (appEnv === 'development') {
  functionBase = 'https://us-central1-otw-stg.cloudfunctions.net'
} else if (appEnv === 'production') {
  functionBase = 'https://us-central1-otw-production-91ffb.cloudfunctions.net'
}

if (!functionBase) {
  throw new Error('functionBase is not defined')
}
const local = false
if (local) {
  functionBase = 'http://127.0.0.1:5001/otw-stg/us-central1'
}

export const functionUrls: Record<FunctionUrls, string> = {
  createFirebaseUser: `${functionBase}/users-createFirebaseUser`,
  deleteFirebaseUser: `${functionBase}/users-deleteFirebaseUser`,
  resendVerificationEmail: `${functionBase}/mail-resendVerificationEmail`,
  sendEmail: `${functionBase}/mail-sendEmail`,
  generateSignInLink: `${functionBase}/generateSignInLink`,
  createHubspotContact: `${functionBase}/hubspot-createHubspotContactHTTP`,
  deleteHubspotContact: `${functionBase}/hubspot-deleteHubspotContactHTTP`,
  getHubspotContact: `${functionBase}/hubspot-retrieveContactsHTTP`,
  pullHubspotContact: `${functionBase}/hubspot-retrieveContactsHTTP`,
  createHubspotTicket: `${functionBase}/hubspot-createHubspotTicketHTTP`,
  deleteHubspotTicket: `${functionBase}/hubspot-deleteHubspotTicketHTTP`,
  pullHubspotTickets: `${functionBase}/hubspot-retrieveAllTicketsHTTP`,
}

// createFirebaseUser: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/users-createFirebaseUser',
//   deleteFirebaseUser: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/users-deleteFirebaseUser',
//   resendVerificationEmail:
//     'https://us-central1-otw-production-91ffb.cloudfunctions.net/mail-resendVerificationEmail',
//   sendEmail: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/mail-sendEmail',
//   generateSignInLink: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/generateSignInLink',
//   createHubspotContact:
//     'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-createHubspotContactHTTP',
//   deleteHubspotContact:
//     'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-deleteHubspotContactHTTP',
//   getHubspotContact: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-retrieveContactsHTTP',
//   pullHubspotContact: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-retrieveContactsHTTP',
//   createHubspotTicket: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-createHubspotTicketHTTP',
//   deleteHubspotTicket: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-deleteHubspotTicketHTTP',
//   pullHubspotTickets: 'https://us-central1-otw-production-91ffb.cloudfunctions.net/hubspot-retrieveAllTicketsHTTP',
// }

type FunctionUrls =
  | 'createFirebaseUser'
  | 'deleteFirebaseUser'
  | 'resendVerificationEmail'
  | 'sendEmail'
  | 'generateSignInLink'
  | 'createHubspotContact'
  | 'deleteHubspotContact'
  | 'getHubspotContact'
  | 'pullHubspotContact'
  | 'createHubspotTicket'
  | 'deleteHubspotTicket'
  | 'pullHubspotTickets'
