import React from "react";
import {
  Container,
  Box,
} from "@mui/material";
import { authBackground, logoNoBackground } from "assets";
import { alpha, useTheme } from "@mui/material/styles";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

const AuthLayout: React.FC = () => {
  const theme = useTheme();
  const { isLoading, isAuthenticated, userInfo } = useAuth();

  const location = useLocation();

  if (location.pathname === "/" && !isAuthenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && userInfo?.user_type) {
    if (userInfo?.user_type === 'babyAdmin') {
      return <Navigate to={`/admin`} replace />;
    }
    return <Navigate to={`/${userInfo?.user_type}`} replace />;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundImage: `url(${authBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white', // Using theme's primary light color with 90% opacity
        position: 'absolute',
        // top: 100,
        // left: 0,
        // zIndex: -1
      }}
    >
      <Container
        sx={{
          // backgroundColor: alpha(theme.palette.primary.light, 0.9), // Using theme's primary light color with 90% opacity
          backgroundColor: alpha('#F4F1EB', 0.9), // Using theme's primary light color with 90% opacity
          borderRadius: theme.shape.borderRadius,
          paddingBottom: theme.spacing(2.5),
        }}
        component='main'
        maxWidth='xs'
      >
        <Box
          sx={{
            marginTop: theme.spacing(20),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='img'
            src={logoNoBackground}
            sx={{
              height: '100px',
              width: '100px',
              // backgroundColor: 'white',
              marginTop: theme.spacing(2),
              padding: theme.spacing(1),
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <>
            <Outlet />
          </>
        </Box>
      </Container>

      {/* <Footer /> */}
    </Container>
  );
};

export default AuthLayout;
