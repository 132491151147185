import React, { useEffect, useState } from 'react'
import {
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  CircularProgress,
} from '@mui/material'
import { getBankApplications, getViewableDocument, viewedApplication } from 'core/api/application/bank'
import ViewNotesDialog from './ViewNotesDialog'
import ApproveOrDenyDialog from './ApproveOrDenyDialog'
import ViewDocumentsDialog from './ViewDocumentsDialog'
import { formatDateToLocalTime } from '../../../../core/utils/formatDateToLocalTime'
import { BankApplication, ApplicationStatus } from 'core/types/applications'

const ApplicationTable = (): JSX.Element => {
  const [applications, setApplications] = useState<BankApplication[]>([])
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [filteredApps, setFilteredApps] = useState<BankApplication[]>([])
  const [currentTab, setCurrentTab] = useState(0)
  const [applicationStateChange, setApplicationStateChange] = useState(false)

  useEffect(() => {
    getBankApplications().then(res => {
      setApplications(res)
    })
  }, [currentTab])

  useEffect(() => {
    if (!applications) return
    const formattedApplications = applications.map(app => ({
      ...app,
      time_created: formatDateToLocalTime(app.time_created)
    }));
    const filtered = formattedApplications.filter(app => {
      switch (currentTab) {
        case 0: // Pending tab includes 'Ready', 'Pending', and 'Viewed' applications
          return app.status === 'Pending' || app.status === 'Viewed'
        case 1: // Approved tab
          return app.status === 'Approved'
        case 2: // Denied tab
          return app.status === 'Denied'
        default:
          return false
      }
    })
    setFilteredApps(filtered)
  }, [currentTab, applications, applicationStateChange])

  const updateApplicationInState = (id: number, newStatus: ApplicationStatus) => {
    setApplications(apps => apps.map(app => (app.application_id === id ? { ...app, status: newStatus } : app)))
    setApplicationStateChange(prevState => !prevState)
  }

  const handleViewDocument = async (application_id: number, document_uuid: string, status: string) => {
    setLoadingDialogOpen(true)

    try {
      await viewedApplication(application_id)
      await new Promise(resolve => setTimeout(resolve, 500)) // Wait for a brief moment
      const url = await getViewableDocument(document_uuid)

      if (url === '') {
        setErrorDialogOpen(true)
        setLoadingDialogOpen(false)
        return
      }
      if (status === 'Pending') {
        updateApplicationInState(application_id, 'Viewed')
      }

      setDocumentUrl(url)
      setIsDialogOpen(true)
    } catch (error) {
      console.error('Error fetching document:', error)
    } finally {
      setLoadingDialogOpen(false)
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setDocumentUrl(null) // Reset the URL
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const ErrorDialog = () => (
    <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error loading the document. Please try again or submit a support request.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href='/support-request'>Submit Support Request</Button>
        <Button onClick={handleCloseErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  const handleCloseLoadingDialog = () => {
    setErrorDialogOpen(false)
  }

  const LoadingDialog = () => (
    <Dialog open={loadingDialogOpen} onClose={handleCloseLoadingDialog}>
      <DialogTitle>Loading Document..</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )

  const [viewNotesDialogOpen, setViewNotesDialogOpen] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState<BankApplication | null>(null)

  const handleOpenViewNotesDialog = (application: BankApplication) => {
    setSelectedApplication(application)
    setViewNotesDialogOpen(true)
  }

  const handleCloseViewNotesDialog = () => {
    setViewNotesDialogOpen(false)
  }

  const [approveOrDenyDialogOpen, setApproveOrDenyDialogOpen] = useState(false)
  // const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);

  const handleOpenApproveOrDenyDialog = (application: BankApplication) => {
    setSelectedApplication(application)
    setApproveOrDenyDialogOpen(true)
  }

  const handleCloseApproveOrDenyDialog = () => {
    getBankApplications().then(res => {
      setApplications(res)
    })
    setApproveOrDenyDialogOpen(false)
  }

  const [viewDocumentsDialog, setViewDocumentsDialog] = useState(false)

  const handleOpenViewDocumentsDialog = (application: BankApplication) => {
    setSelectedApplication(application)

    setViewDocumentsDialog(true)
  }

  const handleCloseViewDocumentsDialog = () => {
    setViewDocumentsDialog(false)
  }

  return (
    <>
      <ErrorDialog />
      <LoadingDialog />

      <ViewNotesDialog
        application={selectedApplication}
        isOpen={viewNotesDialogOpen}
        onClose={handleCloseViewNotesDialog}
      />

      <ViewDocumentsDialog
        application={selectedApplication}
        isOpen={viewDocumentsDialog}
        onClose={handleCloseViewDocumentsDialog}
      />

      <ApproveOrDenyDialog
        application={selectedApplication}
        isOpen={approveOrDenyDialogOpen}
        onClose={handleCloseApproveOrDenyDialog}
      />

      <Tabs value={currentTab} onChange={handleTabChange} centered>
        <Tab label='Pending' />
        <Tab label='Approved' />
        <Tab label='Denied' />
      </Tabs>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Application ID</TableCell>
              <TableCell>Client Email</TableCell>
              <TableCell>Date Submitted</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApps.map((app, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{app.application_id}</TableCell>
                <TableCell>{app.user_email}</TableCell>
                <TableCell>{app.time_created}</TableCell>

                <TableCell>{app.status}</TableCell>

                <TableCell>
                  <Button
                    onClick={() => handleViewDocument(app.application_id, app.document_uuid, app.status)}
                    variant='contained'
                    color='primary'
                    sx={{ mr: 1 }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => handleOpenViewDocumentsDialog(app)}
                    variant='contained'
                    color='primary'
                    sx={{ mr: 1 }}
                  >
                    Download Documents
                  </Button>
                  <Button
                    onClick={() => handleOpenViewNotesDialog(app)}
                    variant='contained'
                    color='primary'
                    sx={{ mr: 1 }}
                  >
                    View Optic Notes
                  </Button>

                  {currentTab === 0 && (
                    <>
                      {/* <Button onClick={() => markApplicationApproved(app.application_id)} variant="contained" color="primary" sx={{ mr: 1 }}>
                                                Approve
                                            </Button>
                                            <Button onClick={() => markApplicationDenied(app.application_id)} variant="contained" color="primary" sx={{ mr: 1 }}>
                                                Deny
                                            </Button> */}
                      <Button
                        onClick={() => handleOpenApproveOrDenyDialog(app)}
                        variant='contained'
                        color='primary'
                        sx={{ mr: 1 }}
                      >
                        Approve or Deny
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            height: '100%',
            maxHeight: 'calc(100vh - 64px)', // Adjust as needed
            overflowY: 'hidden', // Prevents double scrolling
          },
        }}
        fullWidth
        maxWidth='lg'
      >
        {documentUrl && (
          <iframe
            src={documentUrl}
            style={{
              height: 'calc(100vh - 64px)', // Adjust as needed
              width: '100%',
              border: 'none',
            }}
            allowFullScreen
          />
        )}
      </Dialog>
    </>
  )
}

export default ApplicationTable
