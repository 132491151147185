import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Paper } from '@mui/material';
import InventoryLog from './inventoryLog';
import UserManagementLog from './usermanagementLog';
import { collection, getFirestore, query, where, orderBy, getDocs, onSnapshot } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const db = getFirestore();

interface HistoryEntry {
  id: string;
  documentId: string;
  timestamp: any;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
}

const AuditLog: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const queryClient = useQueryClient();

  // Single query for all history
  const { data: allHistory, isLoading } = useQuery({
    queryKey: ['documentHistory'],
    queryFn: async () => {
      const historyRef = collection(db, 'document_history');
      const q = query(
        historyRef,
        orderBy('timestamp', 'desc')
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        documentId: doc.data().docId,
        id: doc.id
      } as HistoryEntry));
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Set up real-time listener
  useEffect(() => {
    const historyRef = collection(db, 'document_history');
    const unsubscribe = onSnapshot(historyRef, () => {
      queryClient.invalidateQueries({ queryKey: ['documentHistory'] });
    });

    return () => unsubscribe();
  }, [queryClient]);

  // Split history by collection
  const inventoryHistory = allHistory?.filter(entry => entry.collection === 'master_inventory');
  const userHistory = allHistory?.filter(entry => entry.collection === 'users');

  const combinedHistory = allHistory?.sort((a, b) => {
    const timeA = a.timestamp?.toDate().getTime() || 0;
    const timeB = b.timestamp?.toDate().getTime() || 0;
    return timeB - timeA;
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Paper sx={{ mb: 2 }}>
        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab label="All Changes" />
          <Tab label="Inventory Changes" />
          <Tab label="User Changes" />
        </Tabs>
      </Paper>

      {selectedTab === 0 && (
        <Box>
          {isLoading ? (
            <div>Loading history...</div>
          ) : (
            <InventoryLog 
              inventoryCollection="master_inventory" 
              externalHistory={combinedHistory}
              showTypeIndicator
              title="All Changes History"
            />
          )}
        </Box>
      )}
      
      {selectedTab === 1 && (
        <InventoryLog 
          inventoryCollection="master_inventory"
          externalHistory={inventoryHistory}
        />
      )}
      
      {selectedTab === 2 && (
        <UserManagementLog 
          externalHistory={userHistory}
        />
      )}
    </Box>
  );
}

export default AuditLog;
