import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface PhotoPreviewDialogProps {
  open: boolean
  onClose: () => void
  photoUrl: string
  photoLabel: string
}

const PhotoPreviewDialog = ({ open, onClose, photoUrl, photoLabel }: PhotoPreviewDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          position: 'relative',
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.7)',
          },
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <DialogContent sx={{ p: 0, height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src={photoUrl}
          alt={photoLabel}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </DialogContent>
      
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PhotoPreviewDialog 