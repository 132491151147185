import { fetchVinData, VINData} from 'core/api/inventory/fetchVinData';
import { useQuery } from 'react-query';

const useVinDecoder = (vin: string) => {
  return useQuery<VINData[], Error>(['vinData', vin], () => fetchVinData(vin), {
    enabled: vin.length === 17,
    refetchOnWindowFocus: false,
  },
);
};

export default useVinDecoder;
