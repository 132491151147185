import React, { useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import AgGridFirestoreCRUD from './components/UserGridFirestoreCRUD'
import { useTheme } from '@mui/material'
import { useManageUsers } from 'hooks/useManageUsers'
import { PageTitle } from 'components/PageTitle'
import { CreateUser } from '../UserManagement/components/CreateUser'

const UserManagementGrid: React.FC = () => {
  const theme = useTheme()
  const gridRef = useRef<AgGridReact>(null)
  const { users } = useManageUsers()
  const gridTheme = {
    name: 'default',
    rowHeight: 50,
    fontSize: '14px',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <PageTitle
        title='User Management'
        subtitle='This page allows you to create, update, or delete portal users.'
        bulletPoints={[
          'Double-click the row to start editing a user, then click the save icon that appears on the right side.',
          "Use the 'Remind' icon allows you to resend a verification email to a user.",
          "Share user notes with the team by clicking the 'Add Notes' icon.",
        ]}
      />

      <CreateUser />

      <div style={{ flexGrow: 1, width: '100%' }}>
        <AgGridFirestoreCRUD
          gridRef={gridRef}
          gridTheme={gridTheme}
          rows={users ?? []}
        />
      </div>
    </div>
  )
}

export default UserManagementGrid
