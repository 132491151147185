import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { generateApplicationUrl, postFinishedApplication } from 'core/api/application/client'
import { ClientApplication, PandaDocument } from 'core/types/applications'
import React, { useEffect, useState } from 'react'
import PandadocFrame from 'views/Client/Application/PandadocFrame'

interface ApplicationDialogProps {
  open: boolean
  onClose: () => void
  application: ClientApplication
}

const getTextByStatus = (application: ClientApplication) => {
  switch (application.status) {
    case 'Draft':
      return { title: 'Please fill out the Application Form', content: null }
    case 'Ready':
      return { title: 'Application Form', content: null }
    case 'Corrections Needed':
      return {
        title: 'Application Corrections Required',
        content: (
          <>
            An admin has reviewed your application and requested some changes. Please review the following notes:
            <br />
            <strong>{application.correction_notes}</strong>
          </>
        ),
      }
    case 'Pending':
      return {
        title: 'Application Under Review',
        content: 'Your application is currently being reviewed by our team. Please check back later for updates.',
      }
    case 'Viewed':
      return {
        title: 'Application Viewed',
        content: 'Your application has been viewed by the bank. We will update you on any further developments.',
      }
    case 'Approved':
      return {
        title: 'Application Approved',
        content:
          'Congratulations! Your application has been approved. We will update you on any further developments.',
      }
    case 'Denied':
      return {
        title: 'Application Denied',
        content:
          'We regret to inform you that your application has been denied. Please contact our support team for more information.',
      }
    default:
      return { title: 'Application Form', content: null }
  }
}

const ApplicationDialog: React.FC<ApplicationDialogProps> = ({ open, onClose, application }) => {
  const [iframeUrl, setIframeUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const { showSnackbar } = useSnackbar()
  const applicationText = getTextByStatus(application)

  useEffect(() => {
    setLoading(true)
    generateApplicationUrl(application.application_id).then(generatedApplication => {
      setIframeUrl(generatedApplication.url)
      setLoading(false)
    })
  }, [application.application_id])

  const onFinishAppliction = (pandaPayload: PandaDocument) => {
    const pandadata = {
      document_uuid: pandaPayload.document.id,
      date_completed: pandaPayload.document.date_completed,
      template_uuid: pandaPayload.document.created_from_template.id,
    }

    postFinishedApplication({ application_id: application.application_id, ...pandadata }).then(res => {
      if (res.status === 200) {
        showSnackbar('Application Submitted Successfully!', 'success')
        onClose()
      } else {
        showSnackbar('Application Failed to submit!', 'error')
      }
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle variant='h5'>
        {applicationText.title}
        <IconButton aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 0}}>
        {applicationText.content && (
          <DialogContentText
            sx={{
              backgroundColor: theme => theme.palette.background.paper,
              borderRadius: 2,
              padding: 2,
              boxShadow: 3,
              border: theme => `1px solid ${theme.palette.divider}`,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: 'bold',
                color: theme => theme.palette.primary.main,
                marginBottom: 2,
              }}
            >
              {applicationText.content}
            </Typography>
          </DialogContentText>
        )}
        <PandadocFrame
          loading={loading}
          iframeUrl={iframeUrl}
          onFormCompleted={onFinishAppliction}
          onIframeLoad={() => setLoading(false)}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ApplicationDialog
