import React from 'react'
import { Box } from '@mui/material'
import { stepVan } from 'assets'

export const LoadingInventory = () => {
  const vehicles = [stepVan]

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {/* Road Background */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: '60px',
          bgcolor: '#3e3e3e',
          borderRadius: '5px',
          boxShadow: '0px -2px 10px rgba(0,0,0,0.3)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            height: '4px',
            bgcolor: '#ffffff',
            transform: 'translateY(-50%)',
            background: 'repeating-linear-gradient(to right, #fff, #fff 20px, #3e3e3e 20px, #3e3e3e 40px)',
          },
        }}
      />

      {vehicles.map((vehicle, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            animation: 'drive 4s ease infinite',
            '@keyframes drive': {
              '0%': { transform: 'translateX(-50%) translateY(0)' },
              '100%': { transform: 'translateX(200%) translateY(0)' },
            }
          }}
        >
          {/* Enhanced Exhaust Smoke */}
          <Box
            sx={{
              position: 'absolute',
              left: '-140px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '180px',
              height: '40px',
              bgcolor: 'rgba(158, 158, 158, 0.5)',
              borderRadius: '50px 20px 20px 50px',
              filter: 'blur(6px)',
              animation: 'float 1.2s ease-in-out infinite alternate',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '-20px',
                left: '20px',
                width: '80px',
                height: '60px',
                bgcolor: 'rgba(158, 158, 158, 0.5)',
                borderRadius: '60% 40% 40% 60%',
                filter: 'blur(8px)',
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '-10px',
                left: '60px',
                width: '60px',
                height: '50px',
                bgcolor: 'rgba(158, 158, 158, 0.4)',
                borderRadius: '50% 30% 30% 50%',
                filter: 'blur(8px)',
              },
              '@keyframes float': {
                '0%': { transform: 'translateY(-4px) scaleY(1.1)', opacity: 0.6 },
                '100%': { transform: 'translateY(4px) scaleY(0.9)', opacity: 0.3 },
              },
            }}
          />
          {/* Vehicle */}
          <Box
            component="img"
            src={vehicle}
            sx={{
              width: { xs: '200px', sm: '300px', md: '400px' },
              height: { xs: '200px', sm: '300px', md: '400px' },
              filter: 'brightness(0.9)',
            }}
          />
        </Box>
      ))}
    </Box>
  )
}
