import React, { useMemo } from 'react'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import { Application, ApplicationStatus } from 'core/types/applications'

interface StatusSummaryProps {
  applications: Application[]
}

const StatusSummary: React.FC<StatusSummaryProps> = ({ applications }) => {
  const statusCounts = useMemo(() => {
    const counts: Record<ApplicationStatus, number> = {
      Draft: 0,
      Ready: 0,
      'Corrections Needed': 0,
      Viewed: 0,
      Pending: 0,
      Approved: 0,
      Denied: 0,
    }
    applications.forEach((app) => {
      counts[app.status]++
    })
    return counts
  }, [applications])

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Application Status Summary
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(statusCounts).map(([status, count]) => (
            <Grid item xs={6} sm={3} key={status}>
              <Typography variant="subtitle1">{status}</Typography>
              <Typography variant="h4">{count}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default StatusSummary