import { doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'

export const removeUserFromVehicle = async (
  vehicleId: string,
  userId: string,
  collectionName: string
) => {
  try {
    // Remove from vehicle's assigned_users subcollection
    const vehicleUserRef = doc(db, collectionName, vehicleId, 'assigned_users', userId)
    await deleteDoc(vehicleUserRef)

    // Remove from user's vehicles subcollection
    const userVehicleRef = doc(db, 'users', userId, 'vehicles', vehicleId)
    await deleteDoc(userVehicleRef)

    // If this was the main assigned user, clear that reference
    const vehicleRef = doc(db, collectionName, vehicleId)
    const vehicleDoc = await getDoc(vehicleRef)
    const vehicleData = vehicleDoc.data()

    if (vehicleData?.assigned_user?.id === userId) {
      await updateDoc(vehicleRef, {
        assigned_user: null
      })
    }
  } catch (error) {
    console.error('Error removing user from vehicle:', error)
    throw error
  }
} 