import { FirestoreDataConverter, DocumentSnapshot, SnapshotOptions, WithFieldValue } from 'firebase/firestore'

export const inventoryListConverter: FirestoreDataConverter<InventoryList> = {
  toFirestore(inventoryList: WithFieldValue<InventoryList>) {
    const { id, ...data } = inventoryList
    return data
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): InventoryList {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      dateCreated: data?.dateCreated || new Date().toISOString(),
      createdBy: data?.createdBy || '',
      inventoryIds: data?.inventoryIds || [],
      name: data?.name || '',
      description: data?.description || '',
      isArchived: data?.isArchived || false,
    }
  },
}

export const userInventoryListConverter: FirestoreDataConverter<UserInventoryList> = {
  toFirestore(userList: WithFieldValue<UserInventoryList>) {
    const { id, ...data } = userList
    return data
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): UserInventoryList {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      userId: data?.userId || '',
      inventoryListId: data?.inventoryListId || '',
      dateAdded: data?.dateAdded || new Date().toISOString(),
    }
  },
} 