import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { SellSubmission } from 'core/types/form_submission'
import React from 'react'
import FleetListDialog from '../FleetListDialog'

interface SellSubmissionListItemProps {
  submission: SellSubmission
  actionButton: React.ReactNode
  onClick: () => void
  onSubmit: () => void
}

const SellSubmissionListItem: React.FC<SellSubmissionListItemProps> = ({
  submission,
  actionButton,
  //onClick,
  onSubmit,
}) => {
  const [openFleetDialog, setOpenFleetDialog] = React.useState(false)

  const getSubmissionText = () => {
    if (submission.vehicles?.length) {
      return `${submission.vehicles.length} vehicle${submission.vehicles.length !== 1 ? 's' : ''}`
    }
    return submission.fileBase64 ? '1 Excel file' : 'No vehicles'
  }

  return (
    <>
      <ListItem>
        <ListItemButton onClick={() => setOpenFleetDialog(true)}>{actionButton}</ListItemButton>
        <ListItemText
          primary={`${submission.firstName} ${submission.lastName}`}
          secondary={
            <>
              <Typography component='span' variant='body2' color='text.primary'>
                {getSubmissionText()}
              </Typography>
              {` - ${submission.email}`}
            </>
          }
        />
      </ListItem>
      <FleetListDialog
        open={openFleetDialog}
        fleetOwner={{
          name: `${submission.firstName} ${submission.lastName}`,
          phone: submission.phone,
          email: submission.email,
        }}
        onClose={() => setOpenFleetDialog(false)}
        vehicles={submission.vehicles}
        file={submission.fileBase64?.data}
        onSubmit={() => {
          onSubmit()
          setOpenFleetDialog(false)
        }}
      />
    </>
  )
}

export default SellSubmissionListItem
