import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import { Warning } from '@mui/icons-material';

interface RemoveAllConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  count: number;
}

export const RemoveAllConfirmationDialog: React.FC<RemoveAllConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  count
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <Warning color="error" />
          <Typography variant="h6" color="error">
            Remove All Access Codes
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to remove <strong>all {count} access codes</strong>?
        </Typography>
        <Typography color="error" sx={{ mt: 2, fontWeight: 'bold' }}>
          Warning: This is a destructive action!
        </Typography>
        <Typography color="error.main" sx={{ mt: 1 }}>
          This will immediately invalidate all photo upload links. This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button 
          onClick={onConfirm} 
          variant="contained" 
          color="error"
          sx={{ fontWeight: 'bold' }}
        >
          Yes, Remove All Access Codes
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 