import BuildIcon from '@mui/icons-material/Build'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Card, CardContent, Chip, Typography } from '@mui/material'
import { ApplicationStatus, ClientApplication } from 'core/types/applications'
import React from 'react'

interface ApplicationCardProps {
  application: ClientApplication
  onClick?: () => void
}

const getStatusStyles = (status: ApplicationStatus) => {
  switch (status) {
    case 'Draft':
      return { color: '#757575', icon: <EditIcon />, label: 'Draft' }
    case 'Ready':
      return { color: '#03A9F4', icon: <TaskAltIcon />, label: 'Ready' }
    case 'Corrections Needed':
      return { color: '#F44336', icon: <BuildIcon />, label: 'Corrections Needed' }
    case 'Pending':
      return { color: '#FFA000', icon: <HourglassEmptyIcon />, label: 'Pending' }
    case 'Viewed':
      return { color: '#9C27B0', icon: <VisibilityIcon />, label: 'Viewed' }
    case 'Approved':
      return { color: '#4caf50', icon: <CheckCircleIcon />, label: 'Approved' }
    case 'Denied':
      return { color: '#B71C1C', icon: <ClearIcon />, label: 'Denied' }
    default:
      return { color: '#000', icon: null, label: 'Unknown' }
  }
}

const ApplicationCard: React.FC<ApplicationCardProps> = ({ application, onClick }) => {
  const statusStyles = getStatusStyles(application.status)

  return (
    <Card
      sx={{ marginBottom: 2, boxShadow: 2, borderLeft: 4, borderColor: statusStyles.color, cursor: 'pointer' }}
      onClick={onClick}
    >
      <CardContent>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography variant='h5' gutterBottom>
                {application.bank_name}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <Chip
                {...(statusStyles.icon ? { icon: statusStyles.icon } : {})}
                label={statusStyles.label}
                color='default'
                sx={{ color: statusStyles.color, fontWeight: 'bold' }}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1'>
              <strong>Date:</strong> {new Date(application.time_created).toLocaleDateString()}
            </Typography>
          </Box>

          <Box>
            <Typography variant='h6' gutterBottom>
              Notes:
            </Typography>
            <Typography variant='body1'>{application.notes}</Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Typography variant='caption' sx={{ opacity: 0.7 }}>
            Ref #: [{application.application_id}]
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ApplicationCard
