import imageCompression from 'browser-image-compression'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'


export const uploadFileToInventory = async (collectionName: string, rowId: string, file: File, compressFile: boolean = false) => {
    const storage = getStorage()

    const unixTime = new Date().getTime()
    const fileName = `${unixTime}_${file.name.replace(/\s/g, '_')}`
    const storageRef = ref(storage, `${collectionName}/${rowId}/${fileName}`)

    if (compressFile) {
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 3,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
            })
        await uploadBytes(storageRef, compressedFile)
    } else {
        await uploadBytes(storageRef, file)
    }

    const url = await getDownloadURL(storageRef)
    return { name: file.name, url }; 
  }