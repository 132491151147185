import React from 'react'


const DealerHome = () => {
  // const googleSheetUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vT0EsJoTFDseVLrHpyIwYHFHUeV3fr7SP4bwJK2wj1hLrd15SkWVblEP2x4hIy6lJzmNruv-bwOE22M/pubhtml";

  const googleSheetUrl2 =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vT0EsJoTFDseVLrHpyIwYHFHUeV3fr7SP4bwJK2wj1hLrd15SkWVblEP2x4hIy6lJzmNruv-bwOE22M/pubhtml?widget=true&amp;headers=false'

  return (
    <>
      <h1>Dealer Home</h1>
      <iframe
        src={googleSheetUrl2}
        style={{ width: '100%', height: '600px', border: 'none' }}
        // frameborder="0"
        // scrolling="yes"
      ></iframe>
    </>
  )
}

export default DealerHome
