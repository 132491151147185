import { Box, Button, Dialog, DialogContent, DialogTitle, InputAdornment, Typography } from '@mui/material'
import CustomForm from 'components/CustomForm'
import { useAuth } from 'contexts/AuthContext'
import { FormField } from 'core/types/forms'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useState } from 'react'
import truckLogo from 'assets/Icon-OpticTruckWorks-02.png'
import {
  getVehicleSizeOptions,
  vehicleFuelOptions,
  vehicleStatusOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'

interface VehicleFormProps {
  open: boolean
  vehicle: Vehicle | null
  onClose: () => void
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  generateStockNumber: () => string
}

function isValidURL(url: string) {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

// TODO: add scanner vin in endendorment

const VehicleForm: React.FC<VehicleFormProps> = ({ open, onClose, onSubmit, vehicle, generateStockNumber }) => {
  const [vehicleValues, setVehicleValues] = useState({})
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isNew = vehicle === null
  const isAdmin = userInfo?.user_type === 'admin'
  const isBabyAdmin = userInfo?.user_type === 'babyAdmin'
  const fields = getInventoryFormFields(isAdmin, isBabyAdmin)

  const subTitles: { [beforFieldNumber: number]: string } = {
    2: 'Truck Information',
  }
  if (isAdmin) {
    subTitles[12] = 'Contact Information'
    subTitles[15] = 'Finance Information'
  } else if (isBabyAdmin) {
    subTitles[11] = 'Finance Information'
  }

  useEffect(() => {
    if (open && isNew && userInfo) {
      setVehicleValues({
        stock_number: generateStockNumber(),
      })
    }
  }, [open, isNew, userInfo])

  useEffect(() => {
    if (!isNew && vehicle) {
      setVehicleValues({
        ...vehicle,
      })
    }
  }, [vehicle])

  const handleSubmit = (data: { [key: string]: string | number | boolean }) => {
    setVehicleValues(data)
    onSubmit(data)
  }

  if (!vehicle && isNew && !userInfo) {
    return null
  }

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle p={0} justifyContent='center' alignContent='center' display='flex'>
        <Typography variant='h1'> {isNew ? 'Add' : 'Edit'} Truck</Typography>
        <Box component='img' src={truckLogo} height={48} width={48} alt='' />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '100%' }}>
        <CustomForm
          fields={fields}
          initialValues={vehicleValues}
          subTitles={subTitles}
          onSubmit={handleSubmit}
          submitText='Submit'
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  )
}

const getInventoryFormFields = (isAdmin: boolean, isBabyAdmin: boolean) => {
  const fields: FormField[] = [
    { name: 'stock_number', type: 'text', label: 'Stock Number', required: true, viewOnly: true },
    ...(isAdmin
      ? [
          {
            name: 'vin',
            type: 'text' as FormField['type'],
            label: 'VIN',
            validation: {
              validateValue: (value: string | number | boolean) => typeof value === 'string' && value.length === 17,
              errorText: 'VIN must be exactly 17 characters long',
            },
            textFieldProps: {
              InputProps: {
                endAdornment: <InputAdornment position='end'></InputAdornment>,
              },
            },
            fullWidth: true,
            showTitle: true,
            required: true,
          },
        ]
      : []),
  ]
  const commonFields: FormField[] = [
    { name: 'make', type: 'text', label: 'Make', required: true },
    { name: 'model', type: 'text', label: 'Model', required: true },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      selectValues: ['', ...vehicleTypeOptions, 'Unknown'],
      required: true,
    },
    {
      name: 'size',
      type: 'select',
      label: 'Size',
      selectValues: values => getVehicleSizeOptions(values.type as string),
      required: true,
    },
    { name: 'fuel', type: 'select', label: 'Fuel', selectValues: vehicleFuelOptions, required: true },
    {
      name: 'miles',
      type: 'text',
      label: 'Mileage',
      required: true,
      validation: {
        validateValue: (value: string | number | boolean) => {
          const sanitizedValue = typeof value === 'string' ? value.replace(/,/g, '') : String(value)
          const num = Number(sanitizedValue)
          return !isNaN(num) && num >= 0
        },
        errorText: 'Mileage must be a positive number',
      },
      textFieldProps: {
        type: 'number',
      },
    },
    {
      name: 'year',
      type: 'text',
      label: 'Year',
      required: true,
      validation: {
        validateValue: value => {
          const num = Number(value)
          if (isNaN(num)) return false

          return num >= 2000 && num <= new Date().getFullYear()
        },
        errorText: 'Year must be between 2000 and current year',
      },
    },
    { name: 'location', type: 'text', label: 'Location', required: true },
    { name: 'condition', type: 'select', label: 'Condition', selectValues: ['', 'Used', 'New'], required: true },
    {
      name: 'status',
      type: 'select',
      label: 'Status',
      required: true,
      selectValues: vehicleStatusOptions,
    },
  ]

  const contactFields: FormField[] = [
    {
      name: 'who',
      type: 'text',
      label: 'Who',
      required: false,
    },
    ...(isAdmin
      ? [
          {
            name: 'phone_number',
            type: 'text' as FormField['type'],
            label: 'Phone Number',
            required: false,
            validation: {
              validateValue: (value: string | number | boolean) => {
                const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                return typeof value === 'string' && phoneRegex.test(value)
              },
              errorText: 'Please enter a valid phone number',
            },
          },
        ]
      : []),
    {
      name: 'url',
      type: 'text',
      label: 'Web Link',
      validation: {
        validateValue: value => typeof value === 'string' && isValidURL(value),
        errorText: 'URL is not valid',
      },
      button: value => (value ? <Button onClick={() => window.open(String(value), '_blank')}>View</Button> : null),
      fullWidth: true,
    },
  ]

  const financeFields: FormField[] = [
    {
      name: 'optic_list_price',
      type: 'text',
      label: 'Optic List Price',
      validation: {
        validateValue: (value: string | number | boolean) => {
          const num = Number(value)
          return !isNaN(num) && num >= 0
        },
        errorText: 'Optic List Price must be a positive number',
      },
      textFieldProps: {
        type: 'number',
        InputProps: {
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        },
      },
    },
    ...(isAdmin
      ? [
          {
            name: 'seller_asking_price',
            type: 'text' as FormField['type'],
            label: 'Seller Asking Price',
            required: true,
            validation: {
              validateValue: (value: string | number | boolean) => {
                const num = Number(value)
                return !isNaN(num) && num >= 0
              },
              errorText: 'Seller Asking Price must be a positive number',
            },
            textFieldProps: {
              type: 'number',
              InputProps: {
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              },
            },
          },
        ]
      : []),
    {
      name: 'sold_date',
      type: 'text',
      label: 'Sold Date',
      required: false,
    },
  ]

  fields.push(...commonFields)
  if (!isBabyAdmin) {
    fields.push(...contactFields)
  }
  fields.push(...financeFields)
  fields.push({
    name: 'notes',
    type: 'text',
    label: 'Notes',
    showTitle: true,
    fullWidth: true,
    textFieldProps: {
      multiline: true,
      rows: 4,
    },
  })

  return fields
}

export default VehicleForm
