import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Chip,
  Divider,
  Paper
} from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from 'core/config/firebase';
import { DirectionsCar, Numbers, Inventory, CalendarMonth, Business } from '@mui/icons-material';

interface VehicleDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  vin: string;
}

interface VehicleDetails {
  stock_number: string;
  year: string;
  make: string;
  model: string;
  vin: string;
}

export const VehicleDetailsDialog: React.FC<VehicleDetailsDialogProps> = ({
  open,
  onClose,
  vin
}) => {
  const [vehicle, setVehicle] = useState<VehicleDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      if (!open || !vin) return;
      
      setLoading(true);
      setError(null);
      try {
        const vehicleQuery = query(
          collection(db, 'master_inventory'),
          where('vin', '==', vin)
        );
        
        const snapshot = await getDocs(vehicleQuery);
        
        if (snapshot.empty) {
          setError('Vehicle not found');
          setVehicle(null);
        } else {
          const vehicleData = snapshot.docs[0].data() as VehicleDetails;
          setVehicle(vehicleData);
        }
      } catch (err) {
        console.error('Error fetching vehicle details:', err);
        setError('Error fetching vehicle details');
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [open, vin]);

  const DetailSection = ({ label, value, icon }: { label: string; value: string; icon: React.ReactNode }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      {icon}
      <Box>
        <Typography variant="caption" color="textSecondary" display="block">
          {label}
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          {value}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <DirectionsCar />
          <Typography variant="h6">Vehicle Details</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : vehicle ? (
          <Box sx={{ mt: 2 }}>
            <Paper elevation={0} sx={{ bgcolor: 'grey.50', p: 2, mb: 3 }}>
              <DetailSection
                label="VIN"
                value={vehicle.vin}
                icon={<Numbers color="primary" />}
              />
              <DetailSection
                label="Stock Number"
                value={vehicle.stock_number}
                icon={<Inventory color="primary" />}
              />
            </Paper>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                  Vehicle Information
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Chip
                  label={vehicle.year}
                  icon={<CalendarMonth />}
                  sx={{ 
                    width: '100%',
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                    '& .MuiChip-icon': {
                      color: 'inherit'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Chip
                  label={vehicle.make}
                  icon={<Business />}
                  sx={{ 
                    width: '100%',
                    bgcolor: 'secondary.main',
                    color: 'secondary.contrastText',
                    fontWeight: 'bold',
                    '& .MuiChip-icon': {
                      color: 'inherit'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Chip
                  label={vehicle.model}
                  icon={<DirectionsCar />}
                  sx={{ 
                    width: '100%',
                    bgcolor: 'success.main',
                    color: 'success.contrastText',
                    fontWeight: 'bold',
                    '& .MuiChip-icon': {
                      color: 'inherit'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}; 